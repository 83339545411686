import React from 'react';
import Modal from '../../UiKit/Modal/Modal';
import NavigationButton, { NavigationButtonGroup } from '../../UiKit/Wizard/NavigationButton/NavigationButton';
import usePasswordReset from '../../Hooks/usePasswordReset';

function DidntGetPasscodeModal({ type, insideWizard, handleClose }) {
    const { closeCloseModal } = usePasswordReset();

    // Function to handle calling the phone number
    const handleCallClick = () => {
        // Open the phone app with the number
        window.open('tel:880-708-4590', '_blank');
    };

    return (
        <Modal type={type} insideWizard={insideWizard} isPasscodeModal={true}>
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Close onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <Modal.Title>Didn’t get your passcode?</Modal.Title>
                    <Modal.Content>
                        Call us to verify your identity and reset your password.
                    </Modal.Content>
                    <NavigationButtonGroup>
                        <NavigationButton
                            style={{ marginBottom: "8px" }}
                            type={"primary"}
                            onClick={handleCallClick}
                        >
                            880-708-4590
                        </NavigationButton>
                        <NavigationButton style={{ marginBottom: "8px" }} type={"primary"} onClick={handleClose}>
                            Close
                        </NavigationButton>
                    </NavigationButtonGroup>
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    );
}

export default DidntGetPasscodeModal;
