import React from 'react';
import classes from './Form.module.css';
import ChatbotRadio from '../ChatbotRadio/ChatbotRadio';

function Form({ children , style}) {
    return (
        <div className={`${classes['chatbot-form']}`} style={style}>
            {children}
        </div>
    )
}

Form.FormGroup = function FormGroup({ children, style }) {
    return (
        <div className={`${classes['chatbot-form__group']}`} style={style}>
            {children}
        </div>
    )
}

Form.Label = function Label({ children, style }) {
    return (
        <label className={`${classes['chatbot-form__label']}`} style={style}>
            {children}
        </label>
    )
}

Form.Control = function Control({ children, style }) {
    return (
        <div className={`${classes['chatbot-form__control']}`} style={style}>
            {children}
        </div>
    )
}

Form.ValidationMessage = function ValidationMessage({ children }) {
    return (
        <div className={`${classes['chatbot-form__validation-message']}`}>
            {children}
        </div>
    )
}

Form.RadioGroup = function RadioGroup(props) {
    return (
        <ChatbotRadio
            options={props.options}
            name={props.name}
            value={props.selectedOption}
            onChange={props.onChange}
            shouldShowValidation={props.shouldShowValidation}
            inputValidationMessage={props.inputValidationMessage}
            optionStyle={props.optionStyle} 
            containerStyle={props.containerStyle} 
            validationFailed={props.validationFailed} 
        />
    );
};

export default Form;
