import React from 'react';
import classes from './Header.module.css';

function Header({ children }) {
    return (
        <div className={`${classes['wizard-header']}`}>
            {children}
        </div>
    )
}

export default Header;