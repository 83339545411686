import React from 'react';
import SuggestedActions from "./SuggestedActions";

const ActivityWithSuggestedActions = (props) => {
    return (
        <div className="activity-with-suggested-action" data-intentname = {props.children.props.activity && props.children.props.activity.value && props.children.props.activity.value.intentName ? props.children.props.activity.value.intentName: ""}>
          <div className="activity">
            {props.children}
            <SuggestedActions activity={props.children.props.activity} {...props} />
          </div>
        </div>
      );
}

export default ActivityWithSuggestedActions;