import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useEnrollmentStatus from "../../Hooks/useEnrollmentStatus";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import Alert from "../../UiKit/Wizard/Alert/Alert";
import{ useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translation"

function EnrollmentStatusUnsupportedScenarios(props) {
    const { next, close } = useEnrollmentStatus();
    const { currentLanguage } = useLanguage();
    const EnrollmentStatusUnsupportedScenariosTranslations = translations[currentLanguage]["EnrollmentStatusUnsupportedScenarios"];
    const buttonTranslations = translations[currentLanguage]["button"];
    const { onNext } = props;

    const handleNext = () => {
        onNext();
    } 
    
    const handleExit = () => {
        close(currentLanguage);
               
        }

    return (
        <PageLayout>
            <PageLayout.Content>
                <Content EnrollmentStatusUnsupportedScenariosTranslations={EnrollmentStatusUnsupportedScenariosTranslations} />
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton onClick={handleExit}type="primary" style={{ paddingBottom:'16px'}}>
                    {buttonTranslations.Exit }
                </NavigationButton>
                <NavigationButton onClick={() => handleNext()} type="secondary" style={{ marginBottom: "20px" }}>
                    {buttonTranslations.ProvideFeedback}
                </NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

function Content({ EnrollmentStatusUnsupportedScenariosTranslations }) {
    return (
        <div
            style={{
                fontSize: '21px',
                lineHeight: '29.4px',
                fontWeight: 'normal',
                margin: '0',
            }}
        >
            <SubHeader
                style={{ display: 'flex', marginBottom: "-17px"}}
                subHeader={EnrollmentStatusUnsupportedScenariosTranslations.Title}
            />
            <Body
                bodyText={
                    <>
                        <div style={{ marginTop:'8px', marginBottom:"8px" }}>
                           {EnrollmentStatusUnsupportedScenariosTranslations.Body}
                        </div>
                        <div style={{paddingBottom: '32px'}}>
                            {EnrollmentStatusUnsupportedScenariosTranslations.Body2}
                        </div>
                        <div style={{ marginTop: '8px'}}>
                            {EnrollmentStatusUnsupportedScenariosTranslations.Body3}
                        </div>
                    </>
                }
            />
            
        </div>
    );
}

export default EnrollmentStatusUnsupportedScenarios;