import React, { useMemo } from "react";
import "./AdjustPlanDetails.css";
import Tooltip from "../Tooltip/Tooltip";
import { useLanguage } from "../langugeContext";
import { translations } from "../../../Components/M2CWizard/translations";

const AdjustedPlanDetails = ({ group, planName, monthlyCost, keepCount, totalCount }) => {
    const { currentLanguage } = useLanguage();
    const AdjustedPlanDetails = translations[currentLanguage]["ReviewAndConfirmPage"];

    const adjustedCost = useMemo(() => {
        return monthlyCost;
    }, [monthlyCost]);

    const formattedMonthlyCost = adjustedCost > 0 ? adjustedCost.toFixed(2) : "0";

    return (
        <div className="plan-details">
            <div className="plan-group">
                <span className="group">{group}</span>
                <strong className="plan-name">{planName}</strong>
            </div>
            <div className="plan-cost">
                <span className="what-you-pay">
                    {AdjustedPlanDetails.AdjustPlanDetails.WhatYouWillPay}
                    <Tooltip
                        width="189px"
                        height="auto"
                        message={AdjustedPlanDetails.AdjustPlanDetails.Tooltip}
                        forcePosition="left"
                        forcePositionSmall="left"
                        forcePositionLeft="left"
                        forcePositionRight="left"
                    />
                </span>
                <strong className="adjusted-cost">
                    ${formattedMonthlyCost}/<span className="monthly">{AdjustedPlanDetails.AdjustPlanDetails.Monthly}</span>
                </strong>
            </div>
        </div>
    );
};

export default AdjustedPlanDetails;
