import React, { useEffect, useState, useRef } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useM2C from "../../Hooks/useM2C";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import KeepImage from "../../Images/Keep.svg";
import Form from "../../UiKit/Form/Form";
import InputValidationMessage from "../../UiKit/InputValidationMessage/InputValidationMessage";
import Input from "../../UiKit/Wizard/Input/Input";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import styles from "../../UiKit/Wizard/WizardLayout/WizardLayout.module.css";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translations";

function AgreePlanPage(props) {
    const { next, previous, sb260Event } = useM2C();
    const [accepted, setAccepted] = useState(false);
    const [showError, setShowError] = useState(false);
    const [disableContinue, setDisableContinue] = useState(false);
    const { memberDecisions, keepEnrollmentYear, onConfirm, onBack, onNext, acceptedTerms, setAcceptedTerms, sendLoggingEventToBackend } = props;
    const { currentLanguage } = useLanguage();
    const AgreeTranslations = translations[currentLanguage]["AgreePlanPage"];
    const NavButton = translations[currentLanguage]["NavButton"];
    const [bottomValue, setBottomValue] = useState("73px");

    const bottomRef = useRef(null);
    const formRef = useRef(null);
    const errorRef = useRef(null);

    useEffect(() => { }, [keepEnrollmentYear]);

    useEffect(() => {
        const updateBottomValue = () => {
            const windowHeight = window.innerHeight;
            if (windowHeight > 600 && windowHeight < 950) {
                setBottomValue("-42px");
            } else if (windowHeight > 1000) {
                setBottomValue("-42px");
            } else {
                setBottomValue("-42px");
            }
        };

        updateBottomValue();
        window.addEventListener("resize", updateBottomValue);

        return () => {
            window.removeEventListener("resize", updateBottomValue);
        };
    }, []);

    const enrollments = Object.entries(sb260Event.data.getMemberDetails || {}).reduce((acc, [enrollmentId, group]) => {
        const membersWithEnrollmentId = group.members.map(member => ({
            ...member,
            enrollmentId
        }));
        return [...acc, ...membersWithEnrollmentId];
    }, []);

    const updatedEnrollments = enrollments.map((member, index) => ({
        ...member,
        type: memberDecisions[`${member.enrollmentId}-${member.apsId}-${index}`],
    }));

    useEffect(() => { }, [updatedEnrollments]);

    const memberDecisionsArray = Object.values(memberDecisions);

    const keepCount = memberDecisionsArray.filter(
        (decision) => decision === "keep"
    ).length;
    const cancelCount = memberDecisionsArray.filter(
        (decision) => decision === "cancel"
    ).length;

    const renderTermItem = (text) => {
        return (
            <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                <img src={KeepImage} alt="Keep" style={{ marginRight: "8px", marginTop: "7px", marginBottom: "auto" }} />
                <Body
                    bodyText={
                        <span>{text}</span>
                    }
                />
            </div>
        );
    };

    const termsEn = [
        `I will file an income tax return for ${keepEnrollmentYear}.`,
        `If I’m married, I will file a joint tax return for ${keepEnrollmentYear}.`,
        `I will claim deductions for all members of my family listed in this application on my ${keepEnrollmentYear} tax return.`,
        `No one else can claim me as a tax dependent for ${keepEnrollmentYear}.`,
        "If applicable, I will use binding arbitration to resolve disputes or claims with my health plan.",
        "I give consent for Covered California to check federal and state records to determine my eligibility.",
    ];

    const termsEs = [
        `Yo presentaré una declaración de impuestos para ${keepEnrollmentYear}.`,
        `Si estoy casado, yo presentaré una declaración de impuestos en conjunto para ${keepEnrollmentYear}.`,
        `Yo reclamaré deducciones en mi declaración de impuestos para ${keepEnrollmentYear}  para todos los miembros de mi familia anotados en esta aplicación.`,
        `Nadie más puede reclamarme como dependiente para ${keepEnrollmentYear}.`,
        "Si es aplicable, utilizaré arbitraje vinculante para resolver disputas o reclamos con mi plan de salud.",
        "Yo doy mi consentimiento para que Covered California revise los registros federales y estatales para determinar mi elegibilidad.",
    ];

    const terms = currentLanguage === "en" ? termsEn : termsEs;

    const handleNextClick = () => {
        if (!acceptedTerms) {
            setShowError(true);
            return;
        }
        setShowError(false);
        if (onConfirm && typeof onConfirm === 'function') {
            onConfirm(updatedEnrollments);
        }

        let intentName;
        let text;
        if (keepCount > 0 && cancelCount === 0) {
            intentName = text = currentLanguage === "en" ? "M2cAcceptTermsandConditions" : "M2cAcceptTermsandConditionsSpanish";
        }

        if (keepCount > 0 && cancelCount > 0) {
            intentName = text = currentLanguage === "en" ? "M2cMixedKeepandCancel" : "M2cMixedKeepandCancelSpanish";
        }
        props.sendLoggingEventToBackend({
            intentName: intentName,
            text: text
        });

        onNext();
    };

    useEffect(() => {
        if (showError && errorRef.current) {
            errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            errorRef.current.focus();
        }
    }, [showError]);

    const handleCheckboxChange = () => {
        setAcceptedTerms(!acceptedTerms);
        setShowError(false);
    };

    return (
        <>
            <WizardLayout.Content>
                <PageLayout>
                    <PageLayout.Header style={{ marginBottom: "-10px" }}>
                        <SubHeader style={{ marginBottom: "-" }} subHeader={AgreeTranslations.SubHeader} />
                    </PageLayout.Header>
                    <PageLayout.Content>
                        <Form ref={formRef}>
                            <Form.FormGroup>
                                <div style={{ marginTop: '10px' }}>
                                    {terms.map((term, index) => renderTermItem(term, index))}
                                </div>
                                <Body
                                    bodyText={
                                        <>
                                            <div style={{ marginTop: '32px', marginBottom: "17px" }}>
                                                {AgreeTranslations.Details}
                                            </div>
                                        </>
                                    }
                                />
                                <Form.Control style={{ marginBottom: "50px" }}>
                                    <Form.Label htmlFor="acceptTerms" className="d-flex align-items-center mt-4">
                                        <Input
                                            type="checkbox"
                                            id="acceptTerms"
                                            checked={acceptedTerms}
                                            onChange={handleCheckboxChange}
                                            disabled={disableContinue}
                                            className="agreeCheckbox"
                                            style={{ width: "18px", height: '18px' }}
                                        />
                                        <Body style={{ marginTop: "-5px", marginLeft: "10px" }}
                                            bodyText={
                                                <>
                                                    <div style={{ marginLeft: '11px' }}>
                                                        {AgreeTranslations.AcceptTerms}
                                                    </div>
                                                </>
                                            }
                                        />
                                    </Form.Label>
                                    {showError && (
                                        <InputValidationMessage tabIndex="-1" ref={errorRef}>
                                            {AgreeTranslations.Terms}
                                        </InputValidationMessage>
                                    )}
                                </Form.Control>
                            </Form.FormGroup>
                        </Form>
                    </PageLayout.Content>
                </PageLayout>
            </WizardLayout.Content>
            <div style={{ position: "relative" }}>
                <div ref={bottomRef} className={`${styles.overlaybottom}`} style={{ bottom: bottomValue, height:"78px" }}></div>
            </div>
            <WizardLayout.Navigation>
                <NavigationButton onClick={handleNextClick} type={"primary"} disabled={!acceptedTerms}>{NavButton.NextButton}</NavigationButton>
                <NavigationButton onClick={onBack} type={"secondary"}>{NavButton.BackButton}</NavigationButton>
            </WizardLayout.Navigation>
        </>
    );
}

export default AgreePlanPage;