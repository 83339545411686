import React, { createContext, useContext, useState } from "react";

export const WebChatContext = createContext();

function WebChatContextProvider({ children }) {
    const [directline, setDirectline] = useState();
    const [store, setStore] = useState();
    const [previousConversationHasBeenLoaded, setPreviousConversationHasBeenLoaded] = useState(false);
    const [error, setError] = useState();

    const value = {
        directline,
        setDirectline,
        store,
        setStore,
        previousConversationHasBeenLoaded,
        setPreviousConversationHasBeenLoaded,
        error,
        setError
    };

    return (
        <WebChatContext.Provider value={value}>
            {children}
        </WebChatContext.Provider>
    )
}

export const useWebChatContext = () => {
    const context = useContext(WebChatContext);
    if (!context) throw new Error("useWebChatContext must be used within WebChatContext.Provider");
    return context;
}

export default WebChatContextProvider;