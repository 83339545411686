import React, { createContext, useState } from "react";

export const M2CContext = createContext();

const M2CContextProvider = ({ children }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [isM2cWizardOpen, setIsM2cWizardOpen] = useState(true);
    const [isM2CMenuOpen, setIsM2CMenuOpen] = useState(false);
    const [dob, setDob] = useState('');
    const [ssnPart1, setSsnPart1] = useState('');
    const [ssnPart2, setSsnPart2] = useState('');
    const [ssnPart3, setSsnPart3] = useState('');
    const [zip, setZip] = useState('');
    const [closeModal, setCloseModal] = useState({isOpen: false, type: null});
  const [helpModal, setHelpModal] = useState({isOpen: false, type: null})
  const [offBusinessHoursHelpModal, setOffBusinessHoursHelpModal] = useState({isOpen: false, type: null})


    const value = {
        currentPage,
        setCurrentPage,
        isM2cWizardOpen,
        setIsM2cWizardOpen,
        dob,
        setDob,
        ssnPart1,
        setSsnPart1,
        ssnPart2,
        setSsnPart2,
        ssnPart3,
        setSsnPart3,
        zip,
        setZip,
        closeModal,
        setCloseModal,
        helpModal,
        setHelpModal,
        offBusinessHoursHelpModal,
        setOffBusinessHoursHelpModal,
        isM2CMenuOpen,
        setIsM2CMenuOpen
    };
    return (
        <M2CContext.Provider value={value}>
            {children}
        </M2CContext.Provider>
    );
};
export default M2CContextProvider;