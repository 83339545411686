import React, { useContext } from 'react';
import alertContext from './alertContext';
import styles from './Alert.module.css';

const Alert = ({ textColor, iconType, frameIcon, message, heading, highlightedMessage }) => {
    const theme = useContext(alertContext);
    const icon = theme.icons[iconType];
    const frameColor = theme.frameColors[frameIcon];

    return (
        <div className={styles.cici_Alert_outerFrame}>
            <div className={styles.cici_Alert_leftFrame} style={{ backgroundColor: frameColor }} />
                <div className={styles.cici_Alert_text_spacing}>
                    <div className={styles.cici_Alert_icon}>
                        <img src={icon} alt={`${iconType} Alert Icon`} />
                    </div>
                    <div className={styles.cici_Alert_text}>
                        {heading && <span style={{ color: textColor }}>{heading}</span>}
                        {highlightedMessage && <span className={styles.cici_Alert_highlightedMessage}>{highlightedMessage}</span>}
                        <span>{message}</span>
                    </div>
                </div>
        </div>
    );
};

export default Alert;
