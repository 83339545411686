import React, { useContext, useState } from 'react';
import useAffordabilityTool from '../../Hooks/useAffordabilityTool';
import ChatMenu from '../../UiKit/Chatbot/ChatMenu/ChatMenu';
import backButtonIcon from '../../Images/back-button-icon.svg';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Card from 'react-bootstrap/Card';
import ChatMenuAccordionClasses from "../../UiKit/Chatbot/ChatMenu/ChatMenuAccordion.module.css"
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import accordionItemCloseIcon from '../../Images/chat-menu-accordion-toggle-close.svg';
import accordionItemOpenIcon from '../../Images/chat-menu-accordion-toggle-open.svg';
import NavigationButton from '../../UiKit/Wizard/NavigationButton/NavigationButton';
import Pill from '../../UiKit/Pill/Pill';
import PillIconLiveChat from '../../Images/pill-icon-livechat.svg';
import PillIconInPersonHelp from '../../Images/pill-icon-in-person-help.svg';
import PillIconPhoneSupport from '../../Images/pill-icon-phone-support.svg';
import LanguageSelectorCheckmark from '../../Images/language-selector-check.svg'
import PillIconLiveChatActive from '../../Images/live-chat-icon-white.svg'
import PillIconPhoneSupportActive from '../../Images/pill-icon-phone-support--white.svg'
import PillIconInPersonActive from '../../Images/pill-icon-in-person-help--white.svg'
import { useLanguage } from '../../UiKit/Wizard/langugeContext';
import { translations } from '../AffordabilityToolWizard/translations';
import PhoneSupportModal from '../AffordabilityToolWizard/PhoneSupportModal';
import useServiceHour from '../../Hooks/useServiceHours';
import PhoneSupportModalOffHours from '../AffordabilityToolWizard/PhoneSupportModalOffHours';
import serviceHours from '../../Utilities/ServiceHours';
import useM2C from '../../Hooks/useM2C';

function M2CMenu() {
    const { toggleM2CMenu, isM2CMenuOpen, helpModal, openRelativeHelpModal, offBusinessHoursHelpModal, openRelativeOffHoursHelpModal } = useM2C();
    const [selectedLanguage, setSelectedLanguage] = useState('English');
    const [selectedPill, setSelectedPill] = useState(null);
    const { currentLanguage } = useLanguage();
    const affordabilityToolTranslations = translations[currentLanguage]["ToolMenu"];
    const { isServiceHour, serviceHourData } = useServiceHour();
    const handleLanguageSelect = (language) => {
        setSelectedLanguage(language);
    }
    const handlePillSelect = (pill) => {
        if(pill === "Phone Support"){
            openRelativeHelpModal();
        }
        if(pill === "In-person Help"){
            openRelativeOffHoursHelpModal();
        }
        setSelectedPill(pill);
    }

    const isWithinServiceHours = (serviceHourData, selectedLanguage) =>{
            return serviceHours(serviceHourData, selectedLanguage)
    }


    return (
        <ChatMenu isOpen={isM2CMenuOpen} style={{position: "relative", width:"100%", height:"100%"}}>
            <ChatMenu.Header>
                <ChatMenu.HeaderBackButtonContainer>
                    <ChatMenu.HeaderBackButton onClose={() => toggleM2CMenu()}>
                        <img src={backButtonIcon} alt="Back Button" />
                    </ChatMenu.HeaderBackButton>
                </ChatMenu.HeaderBackButtonContainer>
                <ChatMenu.HeaderText>
                    {affordabilityToolTranslations.ToolMenuTitle}
                </ChatMenu.HeaderText>
            </ChatMenu.Header>

            <ChatMenu.Body>
            <Accordion className={ChatMenuAccordionClasses['chat-menu-accordion']}>
                    <Card className={ChatMenuAccordionClasses['chat-menu-accordion__item']}>
                        <Card.Header className={ChatMenuAccordionClasses['chat-menu-accordion__header']}>
                            <CustomToggle eventKey="0">{affordabilityToolTranslations.liveHelp.liveHelp}</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                        <div style={{ position:"static", pospaddingTop: "16px", paddingBottom: "16px" }}>
                                <Pill
                                    variant={'outline-primary'}
                                    onClick={() => { console.log("clicked pill")
                                        handlePillSelect('Live Chat')}}
                                >
                                    <Pill.Icon initialIcon={PillIconLiveChat} activeIcon={PillIconLiveChatActive} />
                                    <Pill.Text>{affordabilityToolTranslations.liveHelp.liveChat}</Pill.Text>
                                </Pill>
                                <Pill
                                    variant={selectedPill === 'In-person Help' ? 'primary' : 'outline-primary'}
                                    onClick={() => handlePillSelect('In-person Help')}
                                >
                                    <Pill.Icon initialIcon={PillIconInPersonHelp} activeIcon={PillIconInPersonActive} />
                                    <Pill.Text>{affordabilityToolTranslations.liveHelp.inPersonHelp}</Pill.Text>
                                </Pill>
                                <Pill
                                    variant={selectedPill === 'Phone Support' ? 'primary' : 'outline-primary'}
                                    onClick={() => handlePillSelect('Phone Support')}
                                >
                                    <Pill.Icon initialIcon={PillIconPhoneSupport} activeIcon={PillIconPhoneSupportActive} />
                                    <Pill.Text>{affordabilityToolTranslations.liveHelp.phoneSupport}</Pill.Text>
                                </Pill>
                                {isWithinServiceHours ? (
                                    helpModal.isOpen && (
                                        <PhoneSupportModal type={helpModal.type} serviceHourData={serviceHourData} localeValue={selectedLanguage} />
                                    )
                                    ) : (
                                    offBusinessHoursHelpModal.isOpen && (
                                        <PhoneSupportModalOffHours type={offBusinessHoursHelpModal.type} localeValue={selectedLanguage} />
                                    )
                                    )}                            
                            </div>
                        </Accordion.Collapse>
                    </Card>

                    <Card className={ChatMenuAccordionClasses['chat-menu-accordion__item']} style={{border:"none", background:"white"}}>
                        <Card.Header className={ChatMenuAccordionClasses['chat-menu-accordion__header']} style={{border:"none", background:"white"}}>
                            <CustomToggle eventKey="1">{affordabilityToolTranslations.commonChatTopics.commonChatTopics}</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <div style={{ paddingTop: "16px", paddingBottom: "16px" }}>
                                <Pill variant="outline-primary">
                                    <Pill.Text>{affordabilityToolTranslations.commonChatTopics.iNeedCoverage}</Pill.Text>
                                </Pill>
                                <Pill variant="outline-primary">
                                    <Pill.Text>{affordabilityToolTranslations.commonChatTopics.accountHelp}p</Pill.Text>
                                </Pill>
                                <Pill variant="outline-primary">
                                    <Pill.Text>{affordabilityToolTranslations.commonChatTopics.myMedicalisEnding}</Pill.Text>
                                </Pill>
                            </div>
                        </Accordion.Collapse>
                    </Card>

                    <Card className={ChatMenuAccordionClasses['chat-menu-accordion__item']}  style={{border:"none", background:"white"}}>
                        <Card.Header className={ChatMenuAccordionClasses['chat-menu-accordion__header']} style={{border:"none", background:"white"}}>
                            <CustomToggle eventKey="2">{affordabilityToolTranslations.language.language}</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                            <div style={{padding: '12px'}} className={'English'} onClick={() => handleLanguageSelect('English') }>
                                <span>{affordabilityToolTranslations.language.english}</span>
                                {selectedLanguage === 'English' && 
                                    <img src={LanguageSelectorCheckmark} 
                                    alt="Selected" 
                                    style={{ marginLeft: '8px', width: '16px', height: '16px' }} />}
                            </div>
                            <div style={{padding: '12px'}} className={'Spanish'} onClick={() => handleLanguageSelect('Spanish')}>
                                <span>{affordabilityToolTranslations.language.spanish}</span>
                                {selectedLanguage === 'Spanish' && 
                                    <img src={LanguageSelectorCheckmark} 
                                    alt="Selected"
                                    style={{ marginLeft: '8px', width: '16px', height: '16px' }} />}
                            </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className={ChatMenuAccordionClasses['chat-menu-accordion__item']}  style={{border:"none", background:"white"}}>
                        <Card.Header className={ChatMenuAccordionClasses['chat-menu-accordion__header']} style={{borderBottom:"1px #D1D1D1", background:"white"}}>
                            <CustomToggle eventKey="3">{affordabilityToolTranslations.privacy.privacy}</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                            <div style={{ padding: "16px 12px" }}>
                                <p>
                                   {affordabilityToolTranslations.privacy.privacyPolicy.privacyPolicyFirst}
                                   {affordabilityToolTranslations.privacy.privacyPolicy.privacyPolicyLink} <a href="https://www.coveredca.com/privacy" target="_blank" rel="noopener noreferrer" style={{color: "#2D77A3"}}> {affordabilityToolTranslations.privacy.privacyPolicy.privacyPolicySecond}</a>
                                </p>
                            </div>
                        </Accordion.Collapse>
                    </Card>

                    <Card className={ChatMenuAccordionClasses['chat-menu-accordion__item']} style={{border:"none", background:"white"}}>
                        <Card.Header className={ChatMenuAccordionClasses['chat-menu-accordion__header']} style={{border:"none", background:"white"}}>
                            <CustomToggle eventKey="4">{affordabilityToolTranslations.Feedback.Feedback}</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                            <div style={{ padding: "16px 12px" }}>
                                <p>{affordabilityToolTranslations.Feedback.feedbackText.first} <a href="/privacy" target="_blank" rel="noopener noreferrer" style={{color: "#2D77A3"}}>{affordabilityToolTranslations.Feedback.feedbackText.second}.</a>
                                </p>
                            </div>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </ChatMenu.Body>

            <ChatMenu.Footer>
                <div style={{ padding: "16px", backgroundColor: "white" }}>
                    <NavigationButton type="primary" onClick={() => toggleM2CMenu()}>
                    {affordabilityToolTranslations.ToolMenuFooter.label}
                    </NavigationButton>
                </div>
            </ChatMenu.Footer>
        </ChatMenu>
    );
}

function CustomToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <button
            className={`${ChatMenuAccordionClasses['chat-menu-accordion__toggle']} ${isCurrentEventKey ? ChatMenuAccordionClasses['chat-menu-accordion__toggle--active'] : ""}`}
            type="button"
            onClick={decoratedOnClick}
            style={{border:"none", background:"white"}}
        >
            <div>
                {children}
            </div>
            {isCurrentEventKey ?
                <img style={{ marginLeft: "auto" }} src={accordionItemCloseIcon} alt="Close Icon" />
                :
                <img style={{ marginLeft: "auto" }} src={accordionItemOpenIcon} alt="Open Icon" />
            }
        </button>
    );
}

export default M2CMenu;
