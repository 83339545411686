import React from "react";
import classes from "./ChatbotLayout.module.css";

function ChatbotLayout({ children }) {
    return (
        <div className={`${classes['cca-chatbot']} ${classes['cca-chatbot--bordered']}`}>
            {children}
        </div>
    )
}

ChatbotLayout.Header = function ({ children }) {
    return (
        <div className={`${classes['cca-chatbot__header']}`}>
            {children}
        </div>
    )
};

ChatbotLayout.Wizard = function ({ children }) {
    return (
        <div className={`${classes['cca-chatbot__wizard']}`}>
            {children}
        </div>
    )
};

ChatbotLayout.Webchat = function ({ children }) {
    return (
        <div className={`${classes["cca-chatbot__webchat"]}`}>
            {children}
        </div>
    )
};

ChatbotLayout.Footer = function ({ children }) {
    return (
        <div className={`${classes["cca-chatbot__footer"]}`}>
            {children}
        </div>
    )
}

export default ChatbotLayout;

