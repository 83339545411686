import React, { useContext } from "react";
import classes from "./HeaderMenuToggle.module.css";
import menuIcon from "../../../../Images/header-menu-toggle-icon.svg"

function HeaderMenuToggle({ onClick }) {
  const altText = "Menu";
  const onMenuToggleClick = () => {
    onClick();
  }
  return (
    <button
      onClick={onMenuToggleClick}
      className={`${classes["header-menu-toggle"]}`}
    >
      <img src={menuIcon} alt={altText} />
    </button>
  );
}

export default HeaderMenuToggle;
