import React from "react";
import "./PersonSelection.css";
import Input from "../Input/Input";
import Form from "../../Form/Form";
import { useLanguage } from "../langugeContext";
import { translations } from "../../../Components/M2CWizard/translations";
import CheckmarkIcon from "../../../Images/Checkmark.svg";
import CircleIcon from "../../../Images/Circle.svg";
import ErrorIcon from "../../../Images/Error.svg";

const PersonSelection = ({ name, age, selectedOption, onChange, showValidationError }) => {
    const { currentLanguage } = useLanguage();
    const KeepTranslations = translations[currentLanguage]["KeepOrCancelPage"];
    return (
        <div className="person-selection">
            <div className="person-details-person-selection">
                <strong className="person-name">{name}</strong>
                <p className="person-age">{age} {KeepTranslations.PersonSelection.Years}</p>
            </div>
            <div className={`person-options ${showValidationError ? 'validation-error' : ''}`}>
                <Form.Control>
                    <input
                        type="radio"
                        id={`${name}-keep`}
                        value="keep"
                        checked={selectedOption === "keep"}
                        onChange={() => onChange("keep")}
                    />
                    <label htmlFor={`${name}-keep`} className="custom-radio">
                        <span className="icon-container">
                            <img
                                src={showValidationError && !selectedOption ? ErrorIcon : (selectedOption === "keep" ? CheckmarkIcon : CircleIcon)}
                                alt={selectedOption === "keep" ? "Checked" : "Unchecked"}
                                className="radio-icon"
                            />
                        </span>
                        <span className="keep-cancel-option">{KeepTranslations.PersonSelection.Keep}</span>
                    </label>
                </Form.Control>
                <Form.Control>
                    <input
                        type="radio"
                        id={`${name}-cancel`}
                        value="cancel"
                        checked={selectedOption === "cancel"}
                        onChange={() => onChange("cancel")}
                    />
                    <label htmlFor={`${name}-cancel`} className="custom-radio">
                        <span className="icon-container">
                            <img
                                src={showValidationError && !selectedOption ? ErrorIcon : (selectedOption === "cancel" ? CheckmarkIcon : CircleIcon)}
                                alt={selectedOption === "cancel" ? "Checked" : "Unchecked"}
                                className="radio-icon"
                            />
                        </span>
                        <span className="keep-cancel-option">{KeepTranslations.PersonSelection.Cancel}</span>
                    </label>
                </Form.Control>
            </div>
        </div>
    );
};

export default PersonSelection;