import React, { forwardRef } from 'react';
import classes from './InputValidationMessage.module.css';

const InputValidationMessage = forwardRef(({ children, className }, ref) => {
    return (
        <div className={`${classes['input-validation-message']} ${className}`} ref={ref}>
            {children}
        </div>
    );
});

export default InputValidationMessage;
