import React, { useContext } from 'react';
import HeaderLayout from '../../UiKit/Chatbot/Header/HeaderLayout';
import HeaderCloseButton from '../../UiKit/Chatbot/Header/HeaderCloseButton/HeaderCloseButton';
import HeaderMenuToggle from '../../UiKit/Chatbot/Header/HeaderMenuToggle/HeaderMenuToggle';
import HeaderTitle from '../../UiKit/Chatbot/Header/HeaderTitle/HeaderTitle'
import useM2C from '../../Hooks/useM2C';

function M2CHeader() {
    const { toggleM2CMenu, close} = useM2C();
    return (
        <HeaderLayout>
            <HeaderLayout.MenuToggle>
                <HeaderMenuToggle onClick={() => toggleM2CMenu()} />
            </HeaderLayout.MenuToggle>
            <HeaderLayout.Title>
                <HeaderTitle />
            </HeaderLayout.Title>
            <HeaderLayout.CloseButton>
                <HeaderCloseButton
                    onClick={() => close()}
                />
            </HeaderLayout.CloseButton>
        </HeaderLayout>
    )
}

export default M2CHeader;