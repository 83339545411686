import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useEnrollmentStatus from "../../Hooks/useEnrollmentStatus";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import Alert from "../../UiKit/Wizard/Alert/Alert";
import{ useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translation"

function EnrollmentStatusServiceError() {
    const { close } = useEnrollmentStatus();
    const { currentLanguage } = useLanguage();
    const serviceErrorTranslations = translations[currentLanguage]["EnrollmentStatusServiceError"];
    const buttonTranslations = translations[currentLanguage]["button"];

    console.log("serviceErrorTranslations", serviceErrorTranslations.bodyText)

    const handleExit = () => {
        console.log("exit")
        close(currentLanguage);
               
        }

    return (
        <PageLayout>
            <PageLayout.Content>
                    <Alert
                            iconType='red'
                            frameIcon='red'
                            heading={serviceErrorTranslations.Title}
                            message={serviceErrorTranslations.bodyText}
            
            />
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton onClick={handleExit} type="primary" style={{ marginBottom: "36px" }}>
                    {
                        buttonTranslations.Exit
                    }
                </NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}


export default EnrollmentStatusServiceError;