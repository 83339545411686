import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useEnrollmentStatus from "../../Hooks/useEnrollmentStatus";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import{ useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translation"

function EnrollmentStatusThankYou({sendMessage }) {
    const { close } = useEnrollmentStatus();
    const { currentLanguage } = useLanguage();
    const thankYourPageTranslations = translations[currentLanguage]["EnrollmentStatusThankYou"];
    const buttonTranslations = translations[currentLanguage]["button"];

    const handleExit = () => {
        console.log("exit")
        close(currentLanguage);
               
        }
  

    return (
        <PageLayout>
            <PageLayout.Content>
            <SubHeader
                subHeader={thankYourPageTranslations.Title}
            />
            <Body style={{ marginTop: '8px'}} bodyText={thankYourPageTranslations.bodyText} />
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton onClick={handleExit} type="primary" style={{ marginBottom: "36px" }}>
                    {
                        buttonTranslations.Exit
                    }
                </NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}


export default EnrollmentStatusThankYou;