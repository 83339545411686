import React, { createContext, useContext, useState, useEffect } from 'react';
import translations from './translations';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {

  const getInitialLanguage = () => {
    return document.documentElement.lang || 'en'; 
  };


  const [lang, setLang] = useState(getInitialLanguage());


  const value = {
    setLang,
    currentLanguage: lang,
    translate: translations[lang]
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
