import React from "react";
import PropTypes from "prop-types";
import InputValidationMessage from "../InputValidationMessage/InputValidationMessage";
import "./ChatbotRadio.css";
import checkmarkIcon from "../../Images/Checkmark.svg";
import circleIcon from "../../Images/Circle.svg";
import errorIcon from "../../Images/Error.svg"; // Import the error icon

const ChatbotRadio = ({
  options,
  name,
  onChange,
  value,
  shouldShowValidation,
  inputValidationMessage,
  optionStyle, 
  containerStyle, 
  validationFailed 
}) => {
  return (
    <div
      className={`chatbot-radio ${
        shouldShowValidation ? "chatbot-radio--invalid" : ""
      }`}
      style={containerStyle} 
    >
      {options.map((option) => (
        <div key={option.value} className="chatbot-radio__option" style={optionStyle}> {/* Apply style to option */}
          <input
            type="radio"
            id={option.value}
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={() => onChange(option.value)}
            className="chatbot-radio__input"
          />
          <label htmlFor={option.value} className="chatbot-radio__label">
            <span
              className={`custom-radio ${value === option.value ? 'checked' : ''}`}
              // style={{ 
              //   border: validationFailed && value !== option.value ? '2px solid red' : 'none',
              //   borderRadius: '50%' // Ensure circular shape
              // }}
            >
              <img
                src={validationFailed && value !== option.value ? errorIcon : (value === option.value ? checkmarkIcon : circleIcon)}
                alt="Icon"
                className="chatbot-radio__icon"
              />
            </span>
            {option.label}
          </label>
        </div>
      ))}
      {shouldShowValidation && (
        <InputValidationMessage>{inputValidationMessage}</InputValidationMessage>
      )}
    </div>
  );
};

ChatbotRadio.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  shouldShowValidation: PropTypes.bool,
  inputValidationMessage: PropTypes.string,
  optionStyle: PropTypes.object, 
  containerStyle: PropTypes.object, 
  validationFailed: PropTypes.bool 
};

export default ChatbotRadio;
