const translations = {
    en: {
      inputPlaceholder: "Enter income",
      buttonNext:"Next",
      buttonBack:"Back",
      editText:"Edit"
    },
    es: {
      inputPlaceholder: "Ingrese ingresos",
    }
  };
  
  export default translations;
  