import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useAffordabilityTool from "../../Hooks/useAffordabilityTool";

function RequirementsPage() {
  const { next, close } = useAffordabilityTool();
  return (
    <PageLayout>
      <PageLayout.Header>
        <SubHeader subHeader={"You will need the following:"} />
      </PageLayout.Header>
      <PageLayout.Content>
        <ul style={{ marginTop: "32px" }}>
          <li>
            Your cost for the least expensive plan the employer offers for the
            employee and family coverage.
          </li>
          <li>Your estimated total household income.</li>
        </ul>
        <p>
          Need help getting this information? Your employer can complete the
          Employer Coverage Worksheet.
        </p>
      </PageLayout.Content>
      <PageLayout.Navigation>
        <NavigationButton onClick={() => next()} type={"primary"}>
          Next
        </NavigationButton>
        <NavigationButton onClick={() => close()} type={"tertiary"}>
          Exit
        </NavigationButton>
      </PageLayout.Navigation>
    </PageLayout>
  );
}

export default RequirementsPage;
