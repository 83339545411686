import React from "react";
import Modal from "../../UiKit/Modal/Modal";
import useAffordabilityTool from "../../Hooks/useAffordabilityTool";
import NavigationButton, { NavigationButtonGroup } from "../../UiKit/Wizard/NavigationButton/NavigationButton";

function PhoneSupportModalOffHours({type}) {
    const {closePhoneSupportOffHoursModal} = useAffordabilityTool();
    console.log("phone support modal", type)
    return (
        <Modal type={type}>
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Close onClick={()=>closePhoneSupportOffHoursModal()}/>
                </Modal.Header>
                <Modal.Body>
                    <Modal.Title>
                        We’ll be back soon!
                    </Modal.Title>
                    <Modal.Content>
                        Our hours are: Monday to Friday, 8 a.m. to 6 p.m.
                        <div style={{borderBottom: "1px solid #D1D1D1", width:"232px",margin: "10px 0px"}}/>
                        <div style={{ marginTop: "13px", }}>
                            For general questions, you can visit our Support Page.
                        </div>
                    </Modal.Content>
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    )
}

export default PhoneSupportModalOffHours;