import React, { useContext } from 'react';

import ChatMenu from "../../UiKit/Chatbot/ChatMenu/ChatMenu";
import backButtonIcon from "../../Images/header-close-icon.svg";
import { ChatbotContext } from '../../Contexts/ChatbotContext';

function WebchatMenu() {
    const { isMenuOpen, setIsMenuOpen } = useContext(ChatbotContext);
    return (
        <ChatMenu isOpen={isMenuOpen}>
            <ChatMenu.Header>
                <ChatMenu.HeaderBackButtonContainer>
                    <ChatMenu.HeaderBackButton onClose={() => setIsMenuOpen(false)}>
                        <img src={backButtonIcon} alt="Back Button" />
                    </ChatMenu.HeaderBackButton>
                </ChatMenu.HeaderBackButtonContainer>
                <ChatMenu.HeaderText>
                    Chat menu
                </ChatMenu.HeaderText>
            </ChatMenu.Header>

            <ChatMenu.Body>
                <ChatMenu.Content>
                    <ChatMenu.ItemsTitle>
                        Common Topics
                    </ChatMenu.ItemsTitle>
                    <ChatMenu.Items>
                        <ChatMenu.Item onClick={() => console.log("menu item clicked")}>
                            How to get health coverage
                        </ChatMenu.Item>
                        <ChatMenu.Item onClick={() => console.log("menu item clicked")}>
                            Dental and vision coverage
                        </ChatMenu.Item>
                        <ChatMenu.Item onClick={() => console.log("menu item clicked")}>
                            My account
                        </ChatMenu.Item>
                        <ChatMenu.Item onClick={() => console.log("menu item clicked")}>
                            My Medi-Cal is ending
                        </ChatMenu.Item>
                    </ChatMenu.Items>
                </ChatMenu.Content>

                <ChatMenu.MainActions>
                    <ChatMenu.MainAction onClick={() => console.log("main action clicked")}>
                        Resume chat
                    </ChatMenu.MainAction>
                </ChatMenu.MainActions>
            </ChatMenu.Body>

            <ChatMenu.Footer>
                <ChatMenu.FooterItems>
                    <ChatMenu.FooterItem onClick={() => console.log("footer item clicked")}>
                        Switch to Live Chat
                    </ChatMenu.FooterItem>
                    <ChatMenu.FooterItem onClick={() => console.log("footer item clicked")}>
                        Privacy Policy
                    </ChatMenu.FooterItem>
                    <ChatMenu.FooterItem onClick={() => console.log("footer item clicked")}>
                        How to Ask a Question
                    </ChatMenu.FooterItem>
                </ChatMenu.FooterItems>
            </ChatMenu.Footer>
        </ChatMenu>
    )
}

export default WebchatMenu;