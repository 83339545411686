import React from 'react';
import Lottie from 'react-lottie';
import animationData from './lottie/CiCi-Avatar-Final-2024.json';
import styles from './WelcomeMessage.module.css';

const WelcomeMessage = () => {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div className={styles.welcome_lottie_avatar}>
            <Lottie
                options={defaultOptions}
                height={112}
                width={112}
            />
        </div>
    );
};

export default WelcomeMessage;

