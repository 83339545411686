import React, { useState, useEffect } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useM2C from "../../Hooks/useM2C";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import Alert from "../../UiKit/Wizard/Alert/Alert";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import styles from "../../UiKit/Wizard/WizardLayout/WizardLayout.module.css";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translations";


function KeepOrCancelConfirmationPage(props) {
  const { previous, sb260Event, closeM2C } = useM2C();
  const { onNext, onBack, memberDecisions, sendLoggingEventToBackend } = props;
  const [bottomValue, setBottomValue] = useState("-42px");

  const decisionsArray = Object.values(memberDecisions);
  const keepCount = decisionsArray.filter((decision) => decision === "keep").length;
  const cancelCount = decisionsArray.filter((decision) => decision === "cancel").length;
  const { currentLanguage } = useLanguage();
  const translationsKeepOrCancelConfirmation = translations[currentLanguage]["KeepOrCancelConfirmationPage"];
  const NavButton = translations[currentLanguage]["NavButton"];

  console.log("KeepCount", keepCount);
  console.log("CancelCount", cancelCount);

  useEffect(() => {
    const updateBottomValue = () => {
      const windowHeight = window.innerHeight;
      console.log("windowHeight", windowHeight);
      if (windowHeight > 600 && windowHeight < 900) {
        setBottomValue("-24px");
      } else if (windowHeight > 901) {
        // setBottomValue("-7px");
      } else {
        setBottomValue("-42px");
      }
    };

    updateBottomValue(); 
    window.addEventListener("resize", updateBottomValue);

    return () => {
      window.removeEventListener("resize", updateBottomValue);
    };
  }, []);

  useEffect(() => {
    const intentName = currentLanguage === "en" ? "M2CResultSuccess" : "M2CresultSuccessSpanish";
    const text = currentLanguage === "en" ? "M2C Result Success" : "M2C Result Success Spanish";
    sendLoggingEventToBackend({
      intentName: intentName,
      text: text,
    });
  }, [sendLoggingEventToBackend, currentLanguage]);

  return (
    <>
      <WizardLayout.Content>
        <PageLayout>
          <PageLayout.Header style={{ marginBottom: "-10px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <SubHeader style={{ marginBottom: "-" }} subHeader={translationsKeepOrCancelConfirmation.SubHeader} />
            </div>
          </PageLayout.Header>
          <PageLayout.Content>
            <Body
              bodyText={
                <>
                  <div style={{ marginTop: "10px", marginBottom: "32px" }}>
                    {translationsKeepOrCancelConfirmation.Body}
                  </div>

                  <div style={{ marginTop: "10px", fontWeight: "700" }}>
                    {translationsKeepOrCancelConfirmation.PeopleKeepingTheirPlan}
                  </div>
                  <hr
                    style={{
                      border: "0px",
                      borderTop: "1px solid #ccc",
                      marginTop: "8px",
                      marginBottom: "16px",
                    }}
                  />
                  <Alert
                    iconType="yellow"
                    frameIcon="yellow"
                    highlightedMessage={translationsKeepOrCancelConfirmation.PaymentReminder}
                    message={translationsKeepOrCancelConfirmation.PaymentReminderMessage}
                  />
                  <div style={{ marginTop: "16px" }}>
                    <a
                      href="https://apply.coveredca.com/static/lw-web/login"
                      style={{
                        color: "var(--CalHEERS-Main-Blue, #2D77A3)",
                        textDecoration: "underline",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {translationsKeepOrCancelConfirmation.SignIntoYourAccount}
                    </a>{" "}
                    {translationsKeepOrCancelConfirmation.SignIntoYourAccountMessage}
                  </div>
                  <div style={{ marginTop: "32px", fontWeight: "700" }}>
                    {translationsKeepOrCancelConfirmation.PeopleCancellingTheirPlan}
                  </div>
                  <hr
                    style={{
                      border: "0px",
                      borderTop: "1px solid #ccc",
                      marginTop: "8px",
                      marginBottom: "16px",
                    }}
                  />
                  <div style={{ marginTop: "16px" }}>
                    {translationsKeepOrCancelConfirmation.PeopleCancellingTheirPlanMessage1}
                    <a
                      href="https://apply.coveredca.com/static/lw-web/login"
                      style={{
                        color: "var(--CalHEERS-Main-Blue, #2D77A3)",
                        textDecoration: "underline",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {translationsKeepOrCancelConfirmation.PeopleCancellingTheirPlanMessage2}
                    </a>
                    .
                  </div>
                </>
              }
            />
          </PageLayout.Content>
        </PageLayout>
      </WizardLayout.Content>
      <div style={{ position: "relative" }}>
        <div className={`${styles.overlaybottom}`} style={{ bottom: bottomValue , height: "30px"}}></div>
      </div>
      <WizardLayout.Navigation>
        <NavigationButton onClick={() => closeM2C()} type="primary">
          {NavButton.ExitButton}
        </NavigationButton>
        <NavigationButton onClick={() => onNext()} type="secondary">
          {NavButton.ProvideFeedbackButton}
        </NavigationButton>
      </WizardLayout.Navigation>
    </>
  );
}

export default KeepOrCancelConfirmationPage;