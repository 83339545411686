// This will hold the theme info which is our default styling and can be overridden by local props
import React from 'react';
import yellowIcon from '../Alert/icons/yellowAlert.svg';
import redIcon from '../Alert/icons/redAlert.svg';
import blueIcon from '../Alert/icons/blueAlert.svg';
import redFrame from "../Alert/icons/frameRed.svg";
import yellowFrame from "../Alert/icons/frameYellow.svg";
import blueFrame from '../Alert/icons/frameBlue.svg';
import greenIcon from '../Alert/icons/greenAlert.svg';
import greenFrame from '../Alert/icons/frameGreen.svg';

const alertContext = React.createContext({
  textColor: 'black',
  icons: {
    yellow: yellowIcon,
    red: redIcon,
    blue: blueIcon,
    green: greenIcon
  },
  frameColors: {
    red: '#D71D30',
    yellow: '#F1BD19',
    blue: '#2D77A3',
    green: '#1E824C'
  }
});

export default alertContext;

