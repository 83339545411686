import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useM2C from "../../Hooks/useM2C";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import { translations } from "./translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import useServiceHours from "../../Hooks/useServiceHours";

function ContactSupportPage(props) {
  const { close } = useM2C();
  const { onNext, onBack } = props;
  const { currentLanguage } = useLanguage();
  const ContactSupportTranslations = translations[currentLanguage]["ContactSupportPage"];
  const NavButton = translations[currentLanguage]["NavButton"];

  const { isServiceHour, serviceHourData } = useServiceHours();

  const addLinkStyles = (bodyText, lang) => {
    const linkText = lang === 'en' ? 'Chat online' : 'Chatea en línea';
    const urlPattern = new RegExp(`<a href='[^']*'>${linkText}<\/a>`, 'g');
    const styledLink = `<a href='https://covered-ca.my.salesforce-sites.com/chat/?lang=${lang === 'en' ? 'en_US' : 'es_US'}' style='color: #2D77A3; text-decoration: underline;'>${linkText}</a>`;
    return bodyText.replace(urlPattern, styledLink);
  };


  const bodyTextWithStyledLink = addLinkStyles(ContactSupportTranslations.Body, currentLanguage);

  return (
    <PageLayout>
      <PageLayout.Header style={{ marginBottom: "8px" }}>
        <SubHeader subHeader={ContactSupportTranslations.Title} />
      </PageLayout.Header>
      <PageLayout.Content>
        <Body 
          bodyText={
            <>
              <div style={{ marginTop: '8px' }} dangerouslySetInnerHTML={{ __html: bodyTextWithStyledLink }} />
              <div style={{ marginTop: '32px' }}>
                <strong>{ContactSupportTranslations.Title1}</strong><br />
                <a href={`tel:${ContactSupportTranslations.PhoneNumber.replace(/[^0-9]/g, '')}`} style={{ color: "#2D77A3", textDecoration:"underline"}}>
                  {ContactSupportTranslations.PhoneNumber}
                </a><br />
                {serviceHourData && serviceHourData.weekDayHours && (
                  <div>
                    {serviceHourData.weekDayHours.map((hour, index) => (
                      hour && <div key={index}>{hour}</div>
                    ))}
                    {serviceHourData.extendedHours && serviceHourData.extendedHours.map((hour, index) => (
                      hour && <div key={index}>{hour}</div>
                    ))}
                  </div>
                )}
              </div>
            </>
          }
        />
      </PageLayout.Content>
      <PageLayout.Navigation>
        <NavigationButton onClick={() => close()} type="primary" style={{ marginBottom: "36px" }}>
          {NavButton.ExitButton}
        </NavigationButton>
      </PageLayout.Navigation>
    </PageLayout>
  );
}

export default ContactSupportPage;