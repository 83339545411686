import React, {useState} from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useAffordabilityTool from "../../Hooks/useAffordabilityTool";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Alert from "../../UiKit/Wizard/Alert/Alert";
import { translations } from "./translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";


function WelcomePage1() {
  const { next, previous } = useAffordabilityTool();
  const [showTooltip, setShowTooltip] = useState(true);
  const { currentLanguage } = useLanguage();
  const navButtons = translations[currentLanguage]['NavButton'];
  const welcomePageTranslations = translations[currentLanguage]['WelcomePage1']; 

  return (
    <PageLayout>
      <PageLayout.Content>
        <Content
          showTooltip={showTooltip}
          currentLanguage={currentLanguage}
          welcomePageTranslations={welcomePageTranslations}
        />
      </PageLayout.Content>
      <PageLayout.Navigation>
        <NavigationButton onClick={() => next()} type="primary">
          {navButtons.nextButton}
        </NavigationButton>
        <NavigationButton onClick={() => previous()} type="secondary">
          {navButtons.backButton}
        </NavigationButton>
      </PageLayout.Navigation>
    </PageLayout>
  );
}

function Content({showTooltip, currentLanguage, welcomePageTranslations}) {
  return (
    <div>
      <SubHeader style={{ display: "flex", marginBottom: "24px", marginTop: "0px" }} 
      subHeader={welcomePageTranslations.subHeader}/>
      <Alert
        iconType='yellow'
        frameIcon='yellow'
        message={welcomePageTranslations.bodyText.first}
       
    />
    </div>
  );
}

export default WelcomePage1;
