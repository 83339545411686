import React from 'react';
import Modal from '../../UiKit/Modal/Modal';
import NavigationButton, { NavigationButtonGroup } from '../../UiKit/Wizard/NavigationButton/NavigationButton';
import Body from "../../UiKit/Wizard/Body/Body";

function PrivacyStatementModal({ type, insideWizard, handleClose }) {
    return (
        <Modal type={type} insideWizard={insideWizard}>
            <Modal.Dialog>
                <Modal.Header>
                    {/* <Modal.Title style={{ fontSize: "18px", lineHeight: "24px"}}>Privacy Statement</Modal.Title> */}
                    <Modal.Close onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                <Modal.Title style={{ fontSize: "18px", lineHeight: "24px"}}>Privacy Statement</Modal.Title>
                <Modal.Content>
                    <Body
                        bodyText={
                            <>
                                <div style={{ marginTop: '0px', fontSize: "12px", lineHeight: "16px" }}>
                                    The information requested on this form is required by Covered California to assist
                                    with your request, and the information you provide will be used by Covered California
                                    solely for that purpose. Legal references authorizing the collection of the
                                    personally-identifiable information through this form include Sections 1798.22,
                                    1798.25, 1798.27 and 1798.35 of the California Civil Code and Section 155.260(a)
                                    of the Code of Federal Regulations. California Health Benefits Exchange dba Covered
                                    California, 1601 Exposition Blvd, Sacramento, CA 95815 (800) 889-3871.
                                </div>
                            </>
                        }
                    />
                    </Modal.Content>
                    <NavigationButtonGroup style={{ paddingTop: "24px", paddingBottom: "24px", paddingLeft:"16px", paddingRight: "16px"}}>
                        <NavigationButton style={{ marginBottom: "8px"}} type={"primary"} onClick={handleClose}>Close</NavigationButton>
                    </NavigationButtonGroup>
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    );
}

export default PrivacyStatementModal;
