import React , { forwardRef}from 'react';
import classes from './PageLayout.module.css';

function PageLayout({ children , style}) {
    return (
        <div className={`${classes['page']}`} style={style}>
            {children}
        </div>
    )
}

PageLayout.Header = function ({ children, style }) {
    return (
        <div className={`${classes['page__header']}`} style={style}>
            {children}
        </div>
    )
};

PageLayout.Content = forwardRef(({ children, style }, ref) => {
    return (
        <div ref={ref} className={`${classes['page__content']}`} style={style}>
            {children}
        </div>
    );
});
PageLayout.Navigation = function ({ children, style }) {
    const childrenArray = React.Children.toArray(children);
    return (
        <div className={`${classes['page__navigation']}`}>
            {childrenArray.map((child, index) => (
                <div key={index} className={`${classes['page__navigation-button-container']}`} style={style}>
                    {child}
                </div>
            ))}
        </div>
    )
}

export default PageLayout;