import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "custom-event-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./Components/Webchat/overrides.css";
import "../src/___archive/index.css";

import App from "./___archive/App";
// import Cici from "./___archive/Cici";

import Cici from './Components/Cici/Cici';

import Alert from "./UiKit/Wizard/Alert/Alert";
import * as serviceWorker from "./___archive/serviceWorker";
import SubHeader from "./UiKit/Wizard/SubHeader/SubHeader";
import DualButton from "./UiKit/Wizard/DualButton/DualButton";
import { LanguageProvider } from '../src/UiKit/Wizard/langugeContext';
import Edit from "./UiKit/Wizard/Edit/Edit";

import ChatbotSelect from "./UiKit/ChatbotSelect/ChatbotSelect";
import ExpansionToggle from "./UiKit/Wizard/Expansion/Expansion";


// ReactDOM.render(<WelcomeMessage />, document.getElementById("chatbot-root"));

// ReactDOM.render(
//   <>
//     <Alert
//       iconType='red'
//       iconFrame='red'
//       message="If your plan has a premium, you must pay your first month's bill to start your coverage."
//       heading='Payment Reminder'
//     />
//     <br />
//     <SubHeader subHeader='Health Coverage Costs' />
//     <LanguageProvider><DualButton />,</LanguageProvider>

// <LanguageProvider>
//   <Edit editIcon="edit" label="editText" /></LanguageProvider>
//   </>,
//   document.getElementById("chatbot-root")
// );

// ReactDOM.render(<LanguageProvider>
//   <Edit editIcon="edit" label="editText" />
// </LanguageProvider>, document.getElementById("chatbot-root"));

// ReactDOM.render(
//   <LanguageProvider><DualButton />, 
//   </LanguageProvider>, document.getElementById("chatbot-root"));

ReactDOM.render(<Cici />, document.getElementById("chatbot-root"));
// ReactDOM.render(
//     <ExpansionToggle>
//     <ExpansionToggle.Header>More?</ExpansionToggle.Header>
//     <ExpansionToggle.Content>{"Open the expansion"}</ExpansionToggle.Content>
//     </ExpansionToggle>, document.getElementById("chatbot-root"));
// ReactDOM.render(<ChatbotSelect options={
//     [
//         { value: 'Affordability Tool', label: 'Affordability Tool' },
//         { value: 'Health Plan', label: 'Health Plan' },
//         { value: 'Dental Plan', label: 'Dental Plan' },
//         { value: 'Vision Plan', label: 'Vision Plan' },
//         { value: 'Life Insurance', label: 'Life Insurance' }
//     ]
// } onChange={(selectedOption) => console.log(selectedOption)} />, document.getElementById("chatbot-root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
