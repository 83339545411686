import { useLanguage } from "../UiKit/Wizard/langugeContext";
import UserSession from "../Services/UserSession/UserSession";
import { jwtDecode } from "jwt-decode";
const UserSessionService = new UserSession(localStorage, jwtDecode);

const useLanguageHook = () => {

    const { currentLanguage, setLang } = useLanguage();

    const updateLanguage = (newLanguage) => {
        setLang(newLanguage);
    }

    const didLanguageSwitch = () => {
        if (currentLanguage != UserSessionService.getPreviousLanguage()) return true;
        return false;
    }

    return {
        currentLanguage,
        updateLanguage,
        didLanguageSwitch
    }

}

export default useLanguageHook;