import React, { useState, useRef, useEffect } from 'react';
import styles from './Tooltip.module.css';
import tooltipIcon from './tooltip-icon.svg';
import tooltipActiveIcon from './tooltip-active-icon.svg';
import { useLanguage } from '../langugeContext';

function Tooltip({
  message,
  width = '250px',
  height = 'auto',
  mobileWidth = '250px',
  mobileHeight = 'auto',
  smallMobileWidth = '150px',
  smallMobileHeight = 'auto',
  forcePosition,
  forcePositionSmall,
  forcePositionLeft,
  forcePositionRight,
  customClassName = '',
  customStyle = {}
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [position, setPosition] = useState('bottom');  
  const iconRef = useRef(null);
  const tooltipRef = useRef(null);
  const { currentLanguage } = useLanguage();

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  const handleClickOutside = (event) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target) &&
      iconRef.current &&
      !iconRef.current.contains(event.target)
    ) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    if (showTooltip) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showTooltip]);

  useEffect(() => {
    if (showTooltip && iconRef.current && tooltipRef.current) {
      const iconRect = iconRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      let newPosition = 'bottom'; 
      // console.log("window.innerWidth", window.innerWidth);
      // console.log("window.innerHeight", window.innerHeight);
      // console.log("iconRect", iconRect.left, tooltipRect.width);
      // console.log("window.innerWidth - iconRect.right tooltipRect.width", window.innerWidth - iconRect.right, tooltipRect.width);
     
      if (window.innerWidth >= 376 && window.innerWidth <= 480 && forcePosition) {
        newPosition = forcePosition;
      } else if (window.innerWidth >= 300 && window.innerWidth <= 375 && forcePositionSmall) {
        newPosition = forcePositionSmall;
      } else if (window.innerWidth >= 675 && window.innerWidth <= 1000 && window.innerHeight <= 1368) {
        newPosition = currentLanguage === 'es' ? forcePositionLeft : 'right';
      } else if (window.innerWidth >= 1500 && window.innerHeight <= 920) {
        newPosition = forcePositionRight;
      } else {
        if (window.innerHeight - iconRect.bottom > tooltipRect.height) {
          newPosition = 'bottom';
        } else if (iconRect.top > tooltipRect.height) {
          newPosition = 'top';
        } else if (window.innerWidth - iconRect.right > tooltipRect.width) {
          newPosition = 'right';
        } else if (iconRect.left > tooltipRect.width) {
          newPosition = 'left';
        }
      }

      setPosition(newPosition);  
    }
  }, [showTooltip, forcePosition, forcePositionSmall, forcePositionLeft, currentLanguage, forcePositionRight]);

  return (
    <div className={`${styles.tooltipContainer} ${customClassName}`} style={customStyle}>
      <img
        src={showTooltip ? tooltipActiveIcon : tooltipIcon}
        alt="tooltip icon"
        className={styles.tooltipIcon}
        onClick={toggleTooltip}
        ref={iconRef}
      />
      {showTooltip && (
        <div
          ref={tooltipRef}
          className={`${styles.tooltipMessage} ${styles[`tooltipMessage${position.charAt(0).toUpperCase() + position.slice(1)}`]} ${styles[position]} ${styles[currentLanguage]}`}
          style={{
            '--tooltip-width': width,
            '--tooltip-height': height,
            '--tooltip-width-mobile': mobileWidth,
            '--tooltip-height-mobile': mobileHeight,
            '--tooltip-width-small-mobile': smallMobileWidth,
            '--tooltip-height-small-mobile': smallMobileHeight,
          }}
        >
          {message}
        </div>
      )}
    </div>
  );
}

export default Tooltip;
