function getContributionFrequencyFromString(contributionFrequencyString, language = 'en') {
    contributionFrequencyString = contributionFrequencyString.toLowerCase();
    switch (language) {
        case 'es': // Spanish
        switch (contributionFrequencyString) {
            case "mensualmente":
        return 12;
          case "cada tres meses":
            return 4;
          case "dos veces al mes":
            return 24;
          case "cada dos semanas":
            return 26;
          case "semanalmente":
            return 52;
          case "anualmente":
            return 1;
          default:
            return 0;
            }
        case 'en':
        default:
            switch (contributionFrequencyString) {
                case "monthly":
                    return 12;
                case "quarterly":
                    return 4;
                case "twice a month":
                    return 26;
                case "every 2 weeks":
                    return 26;
                case "weekly":
                    return 52;
                case "yearly":
                    return 1;
                default:
                    return 0;
            }
    }
}

export default getContributionFrequencyFromString;
