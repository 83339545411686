import React from 'react';
import classes from './WizardLayout.module.css';

function WizardLayout({ children }) {
    return (
        <div className={`${classes['wizard']}`}>
            {children}
        </div>
    )
}

WizardLayout.Header = function ({ children }) {
    return (
        <div className={`${classes['wizard__header']}`}>
            {children}
        </div>
    )
};

WizardLayout.Content = function ({ children, style }) {
    return (
        <div className={classes['wizard__content']} style={style}>
            {children}
        </div>
    )
};

// WizardLayout.Navigation = function ({ children }) {
//     return (
//         <div className={`${classes['wizard__navigation']}`}>
//         {children.map((child, index) => (
//             <div key={index} className={`${classes['wizard__navigation-button-container']}`}>
//                 {child}
//             </div>
//         ))}
//     </div>
//     );
// };

WizardLayout.Navigation = function ({ children }) {
    return (
        <div className={`${classes['wizard__navigation']}`}>
            {React.Children.toArray(children).map((child, index) => (
                <div key={index} className={`${classes['wizard__navigation-button-container']}`}>
                    {child}
                </div>
            ))}
        </div>
    );
};

export default WizardLayout;