import React, { useState, useEffect } from "react";
import useM2C from "../../Hooks/useM2C";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Form from "../../UiKit/Form/Form";
import PersonSelection from "../../UiKit/Wizard/PersonSelection/PersonSelection";
import PlanDetails from "../../UiKit/Wizard/PlanDetails/PlanDetails";
import moment from "moment";
import useGroupCalculations from "../../Hooks/useGroupCalculations";
import InputValidationMessage from "../../UiKit/InputValidationMessage/InputValidationMessage";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translations";

function KeepOrCancelPage(props) {
    const { next, previous, sb260Event } = useM2C();
    const { setMemberDecisions, currentMemberDecisions, onNext, updatekeepEnrollmentYear } = props;
    const { groupedMembers, groupTotalPremiums, initialGroupTotalPremiums } = useGroupCalculations(sb260Event.data.getMemberDetails, currentMemberDecisions);
    const { currentLanguage } = useLanguage();
    const KeepTranslations = translations[currentLanguage]["KeepOrCancelPage"];
    const NavButton = translations[currentLanguage]["NavButton"];

    const groups = sb260Event.data.getMemberDetails || {};

    const initialSelectedOptions = {};
    Object.keys(groups).forEach(groupId => {
        groups[groupId].members.forEach((member, memberIndex) => {
            initialSelectedOptions[`${groupId}-${member.apsId}-${memberIndex}`] = currentMemberDecisions[`${groupId}-${member.apsId}-${memberIndex}`] || "";
        });
    });

    const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions);
    const [showValidationError, setShowValidationError] = useState(false);

    useEffect(() => {
        setMemberDecisions(selectedOptions);
    }, [selectedOptions, setMemberDecisions]);

    const handleSelectionChange = (person, option) => {
        setSelectedOptions(prevState => {
            const newState = {
                ...prevState,
                [person]: option
            };
            const allDecided = Object.values(newState).every(opt => opt === "keep" || opt === "cancel");
            if (allDecided) {
                setShowValidationError(false); // Hide validation error if all selections are made
            }
            return newState;
        });
    };

    const onNextClick = () => {
        const allDecided = Object.values(selectedOptions).every(option => option === "keep" || option === "cancel");
        if (!allDecided) {
            setShowValidationError(true);
        } else {
            setShowValidationError(false);
            const year = Object.values(selectedOptions).some(option => option === "keep") ? new Date().getFullYear() : null;
            updatekeepEnrollmentYear(year);  
            onNext();
        }
    };

    return (
        <PageLayout>
            <PageLayout.Header style={{ marginBottom: "-10px" }}>
                <SubHeader style={{ marginBottom: "-" }} subHeader={KeepTranslations.Title} />
            </PageLayout.Header>
            <PageLayout.Content>
                <Form>
                    {Object.keys(groupedMembers).map((groupId, index) => (
                        <Form.FormGroup key={groupId}>
                            {groupedMembers[groupId].map((member, memberIndex) => (
                                <div key={`${groupId}-${member.apsId}-${memberIndex}`}>
                                    {memberIndex === 0 && (
                                        <>
                                            <PlanDetails
                                                group={`${KeepTranslations.PlanDetails.Group} ${index + 1}`}
                                                planName={member.plan}
                                                monthlyCost={initialGroupTotalPremiums[groupId]}
                                            />
                                            <hr style={{ border: "0px", borderTop: "1px solid #ccc", margin: "10px 0" }} />
                                        </>
                                    )}
                                    <PersonSelection
                                        name={`${member.firstName} ${member.lastName.charAt(0)}.`}
                                        age={moment().diff(moment(member.age, "YYYY-MM-DD"), 'years')}
                                        selectedOption={selectedOptions[`${groupId}-${member.apsId}-${memberIndex}`]}
                                        onChange={(option) => handleSelectionChange(`${groupId}-${member.apsId}-${memberIndex}`, option)}
                                        showValidationError={showValidationError && !selectedOptions[`${groupId}-${member.apsId}-${memberIndex}`]}
                                    />
                                </div>
                            ))}
                        </Form.FormGroup>
                    ))}
                    {showValidationError && (
                        <InputValidationMessage>
                            {KeepTranslations.InputValidationMessage}
                        </InputValidationMessage>
                    )}
                </Form>
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton onClick={onNextClick} type={"primary"}>{NavButton.NextButton}</NavigationButton>
                <NavigationButton onClick={() => previous()} type={"secondary"}>{NavButton.BackButton}</NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

export default KeepOrCancelPage;