import React from "react";
import classes from './WelcomeScreenSuggestedActions.module.css';
import SuggestedAction from "../../../../UiKit/SuggestedAction/SuggestedAction";
import useWebChat from "../../../../Hooks/useWebChat";

function WelcomeScreenSuggestedActions () {
    const { store } = useWebChat();
    const onSuggestedActionClick = (message) => {
        store.dispatch({
            type: "WEB_CHAT/SEND_MESSAGE",
            payload: {
                text: message,
            },
        })
    }
    return(
        <div className={`${classes['welcome-screen-suggested-actions']}`}>
            <SuggestedAction onClick={()=>onSuggestedActionClick("I need coverage")}>I need coverage</SuggestedAction>
            <SuggestedAction onClick={()=>onSuggestedActionClick("Account help")}>Account help</SuggestedAction>
            <SuggestedAction onClick={()=>onSuggestedActionClick("My medi-cal is ending")}>My medi-cal is ending</SuggestedAction>
        </div>
    )
}

export default WelcomeScreenSuggestedActions;