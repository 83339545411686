import React, { useContext } from 'react';
import HeaderLayout from '../../UiKit/Chatbot/Header/HeaderLayout';
import HeaderCloseButton from '../../UiKit/Chatbot/Header/HeaderCloseButton/HeaderCloseButton';
import HeaderMenuToggle from '../../UiKit/Chatbot/Header/HeaderMenuToggle/HeaderMenuToggle';
import useAffordabilityTool from '../../Hooks/useAffordabilityTool';
import HeaderTitle from '../../UiKit/Chatbot/Header/HeaderTitle/HeaderTitle'
import useAffordabilityToolInApp from '../../Hooks/useAffordabilityToolInApp';

function AffordabilityToolInAppHeader() {
    const { toggleAffordabilityToolMenu, close } = useAffordabilityTool();
    return (
        <HeaderLayout>
            <HeaderLayout.MenuToggle>
                <HeaderMenuToggle onClick={() => toggleAffordabilityToolMenu()} />
            </HeaderLayout.MenuToggle>
            <HeaderLayout.Title>
                <HeaderTitle />
            </HeaderLayout.Title>
            <HeaderLayout.CloseButton>
                <HeaderCloseButton
                    onClick={() => close()}
                />
            </HeaderLayout.CloseButton>
        </HeaderLayout>
    )
}

export default AffordabilityToolInAppHeader;