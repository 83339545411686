import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useM2C from "../../Hooks/useM2C";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import { translations } from "./translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import useServiceHours from "../../Hooks/useServiceHours";

function NoAPSContactSupportPage(props) {
  const { close } = useM2C();
  const { onNext, onBack } = props;
  const { currentLanguage } = useLanguage();
  const ContactSupportTranslations = translations[currentLanguage]["NoContactSupportPage"];
  const NavButton = translations[currentLanguage]["NavButton"];

  const { isServiceHour, serviceHourData } = useServiceHours();
  

  const chatLink = (
    <a
      href={`https://covered-ca.my.salesforce-sites.com/chat/?lang=${currentLanguage === 'en' ? 'en_US' : 'es_US'}`}
      style={{ color: '#2D77A3', textDecoration: 'underline', fontFamily: 'Open Sans', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight: '20px' }}
    >
      {currentLanguage === 'en' ? 'Chat with a representative' : 'chatear con un representante'}
    </a>
  );

  const phoneLink = (
    <a
      href={`tel:${ContactSupportTranslations.PhoneNumber.replace(/[^0-9]/g, '')}`}
      style={{ color: '#2D77A3', textDecoration: 'underline', fontFamily: 'Open Sans', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight: '20px' }}
    >
      {ContactSupportTranslations.PhoneNumber}
    </a>
  );

  return (
    <PageLayout>
      <PageLayout.Header style={{ marginBottom: '8px' }}>
        <SubHeader subHeader={ContactSupportTranslations.Title} />
      </PageLayout.Header>
      <PageLayout.Content>
        <Body
          bodyText={
            <>
              <div style={{ marginTop: '8px' }}>
                {ContactSupportTranslations.Body}
              </div>
              <div style={{ marginTop: '8px' }}>
                {currentLanguage === 'en' ? (
                  <>You can {chatLink} or call at {phoneLink}.</>
                ) : (
                  <>Puede {chatLink} o llamarnos al {phoneLink}.</>
                )}
              </div>
              <div style={{ marginTop: '32px' }}>
                <strong>{ContactSupportTranslations.Title1}</strong><br />
                {serviceHourData && serviceHourData.weekDayHours && (
                  <div>
                    {serviceHourData.weekDayHours.map((hour, index) => (
                      hour && <div key={index}>{hour}</div>
                    ))}
                    {serviceHourData.extendedHours && serviceHourData.extendedHours.map((hour, index) => (
                      hour && <div key={index}>{hour}</div>
                    ))}
                  </div>
                )}
              </div>
            </>
          }
        />
      </PageLayout.Content>
      <PageLayout.Navigation>
        <NavigationButton onClick={() => close()} type="primary" style={{ marginBottom: '36px' }}>
          {NavButton.ExitButton}
        </NavigationButton>
      </PageLayout.Navigation>
    </PageLayout>
  );
}

export default NoAPSContactSupportPage;