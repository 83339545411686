const getFormattedDollarAmount = (dollarAmount) => {
    if (dollarAmount === undefined || dollarAmount === null) {
        return '';
    }

    const strAmount = dollarAmount.toString().replace(/[^0-9.]/g, '');

    if (strAmount.endsWith(".")) {
        const valueWithoutDot = strAmount.slice(0, -1);
        const parsedAmount = parseFloat(valueWithoutDot);
        return parsedAmount.toLocaleString() + ".";
    }

    if (strAmount.includes('.')) {
        const [wholeNumber, decimalPart] = strAmount.split('.');
        return `${parseInt(wholeNumber).toLocaleString()}.${decimalPart}`;
    } else {
        return parseInt(strAmount).toLocaleString();
    }
};

const useDollarAmountFormatter = () => {
    return { getFormattedDollarAmount };
};

export default useDollarAmountFormatter;
