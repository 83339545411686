import React, { useEffect, useState } from "react";
import Select from "react-select";
import InputValidationMessage from "../InputValidationMessage/InputValidationMessage";
import "./ChatbotSelect.css";

const ChatbotSelect = ({
  options,
  onChange,
  value,
  placeholder,
  shouldShowValidation,
  inputValidationMessage,
}) => {
  const [selectValue, setSelectValue] = useState({ label: "", value: "" });

  const isSet = () => {
    return (
      selectValue.value !== undefined &&
      selectValue.value !== null &&
      selectValue.value !== ""
    );
  };

  const onSelection = (selectedOption) => {
    onChange(selectedOption);
  };

  const getSelectValueFromOptions = (value) => {
    const selectValue = options.find((option) => option.value === value);
    if (selectValue) return selectValue;
    return { label: "", value: "" };
  };

  useEffect(() => {
    setSelectValue(getSelectValueFromOptions(value));
  }, [value]);

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      fontStyle: 'italic',
      color: 'var(--Text-Inactive, #949494)',
    }),
  };

  return (
    <div
      className={
        shouldShowValidation
          ? "chatbot-select chatbot-select--invalid"
          : "chatbot-select"
      }
    >
      <Select
        onChange={(selectedOption) => onSelection(selectedOption)}
        options={options}
        value={isSet() ? selectValue : null}
        placeholder={placeholder}
        classNamePrefix={`chatbot-select`}
        styles={customStyles}
      />
      {shouldShowValidation ? (
        <InputValidationMessage>
          {inputValidationMessage}
        </InputValidationMessage>
      ) : null}
    </div>
  );
};

export default ChatbotSelect;
