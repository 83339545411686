// PersonStatus.js
import React from 'react';
import Edit from '../Edit/Edit';
import './PersonStatus.css'; 
import CheckIcon  from '../../../Images/Keep.svg';
import CrossIcon from '../../../Images/Cancel.svg';
import { useLanguage } from '../langugeContext';
import { translations } from '../../../Components/M2CWizard/translations';

const PersonStatus = ({ name, age, status, onEdit }) => {
    const { currentLanguage } = useLanguage();
    const PersonStatusTranslations = translations[currentLanguage].ReviewAndConfirmPage.PersonStatus;
    const isKeepingPlan = status === "keep";
    return (
        <div className="person-status">
            <div className="person-details">
                <div className="status-icon">
                    <img
                        src={isKeepingPlan ? CheckIcon : CrossIcon}
                        alt={isKeepingPlan ? "Keeping plan" : "Canceling plan"}
                    />
                </div>
                <div className="person-info">
                    <strong className="person-name">{name}</strong>, <span className="person-age">{age} {PersonStatusTranslations.Years}</span>
                    <p className="status-text keep-cancel-status">{isKeepingPlan ?  PersonStatusTranslations.Keep : PersonStatusTranslations.Cancel}</p>
                </div>
            </div>
            <Edit onClick={onEdit}>{PersonStatusTranslations.Edit}</Edit>
        </div>
    );
};

export default PersonStatus;