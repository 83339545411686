import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useEnrollmentStatus from "../../Hooks/useEnrollmentStatus";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import Alert from "../../UiKit/Wizard/Alert/Alert";
import{ useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translation"

function WelcomePage() {
    const { next } = useEnrollmentStatus();
    const { currentLanguage } = useLanguage();
    const welcomePageTranslations = translations[currentLanguage]["WelcomePage"];
    const buttonTranslations = translations[currentLanguage]["button"];

    return (
        <PageLayout>
            <PageLayout.Content>
                <Content welcomePageTranslations={welcomePageTranslations} />
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton onClick={() => next()} type="primary" style={{ marginBottom: "36px" }}>
                    {
                        buttonTranslations.Begin
                    }
                </NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

function Content({ welcomePageTranslations }) {
    return (
        <div
            style={{
                fontSize: '21px',
                lineHeight: '29.4px',
                fontWeight: 'normal',
                margin: '0',
            }}
        >
            <SubHeader
                style={{ display: 'flex', marginBottom: '8px'}}
                subHeader={welcomePageTranslations.Title}
            />
            <Body
                bodyText={
                    <>
                        <div style={{ marginTop: '8px'}}>
                           <p>{welcomePageTranslations.Body}</p> {welcomePageTranslations.Body1}
                        </div>
                    </>
                }
            />
            <div style={{paddingTop:"20px"}}>
             <Alert
                    iconType='blue'
                    frameIcon='blue'
                    heading={welcomePageTranslations.AlertTitle}
                    message={welcomePageTranslations.AlertBody}
       
    />
        </div>
        </div>
    );
}

export default WelcomePage;