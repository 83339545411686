import React, { useContext, useEffect, useRef } from "react"

import classes from './Cici.module.css';

import ChatbotContextProvider from "../../Contexts/ChatbotContext";
import { LanguageProvider } from "../../UiKit/Wizard/langugeContext";

import Webchat from "../Webchat/Webchat";
import AffordabilityToolWizard from '../AffordabilityToolWizard/AffordabilityToolWizard';
import useWebChat from "../../Hooks/useWebChat";
import WebChatContextProvider from "../../Contexts/WebChatContext";
import useChatbot from "../../Hooks/useChatbot";
import DeveloperTools from "../DevelopmentHelpers/DeveloperTools";
import M2CWizard from "../M2CWizard/M2CWizard";
import PasswordResetWizard from "../PasswordResetWizard/PasswordResetWizard";
import EnrollmentStatusWizard from "../EnrollmentStatusWizard/EnrollmentStatusWizard";
import useLanguageHook from "../../Hooks/useLanguageHook";

/**
 * * use cases
 * open
 * minimize
 * close 
 * open wizards
 * open modals
 */
function Cici() {
    return (
        <LanguageProvider>
            <ChatbotContextProvider>
                <WebChatContextProvider>
                    <CiciHelper />
                </WebChatContextProvider>
            </ChatbotContextProvider>
        </LanguageProvider>
    )
}

function CiciHelper() {
    const { open, close, isMinimized, isClosed, isAffordabilityToolWizardOpen, isM2CWizardOpen, sb260Event, isEnrollmentStatusWizardOpen,isPasswordResetMenuOpen, enrollmentStatusEvent,passwordResetEvent } = useChatbot();
    const { sendActivity, sendLoggingEventToBackend, sendMessage, postActivity } = useWebChat();
    const { updateLanguage } = useLanguageHook();

    const shouldHideWebchat = () => {
        if (isAffordabilityToolWizardOpen) return true;
        if (isM2CWizardOpen) return true;
        if (isEnrollmentStatusWizardOpen) return true;
        if (isPasswordResetMenuOpen) return true;
        return false;
    }

    const getCiciClasses = () => {
        let ciciClasses = classes['cici'];
        if (isMinimized) ciciClasses += ` ${classes['cici--is-minimized']}`;
        return ciciClasses;
    }

    const isPageSpanish = () => {
        if (document.location.href.indexOf('espanol') !== -1) return true;
        return false;
    }

    useEffect(() => {
        if (isPageSpanish()) {
            updateLanguage('es');
            return;
        }
        updateLanguage('en');
    }, []);

    if (isClosed) return null;

    return (
        <div className={getCiciClasses()}>
            {process.env.NODE_ENV === "development" ? <DeveloperTools /> : null}
            <button
                className={`${classes['cici__close-button']}`}
                onClick={close}
            >
                close
            </button>
            <button
                className={`${classes['cici__open-button']}`}
                onClick={() => open()}
            >
                open
            </button>
            <div className={`${classes['cici__body']}`}>
                <div className={`${shouldHideWebchat() ? 'd-none' : ''}`}>
                    <Webchat />
                </div>
                {isAffordabilityToolWizardOpen && <AffordabilityToolWizard sendLoggingEventToBackend={sendLoggingEventToBackend} />}
                {isM2CWizardOpen && <M2CWizard sendActivity={sendActivity} event={sb260Event} sendLoggingEventToBackend={sendLoggingEventToBackend} />}
                {isEnrollmentStatusWizardOpen && <EnrollmentStatusWizard sendActivity={sendActivity} event={enrollmentStatusEvent} sendLoggingEventToBackend={sendLoggingEventToBackend} sendMessage={sendMessage} />}
                {isPasswordResetMenuOpen && <PasswordResetWizard postActivity={postActivity} sendActivity={sendActivity} event={passwordResetEvent} />}
            </div>
        </div>
    )
}

export default Cici;
