import React, { createContext, useContext, useState } from "react";

const PillContext = createContext(null);

const PillContextProvider = ({children}) => {
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const handleMouseDown = () => setIsMouseDown(true);
    const handleMouseUp = () => setIsMouseDown(false);
    
    const value = {
        isMouseDown,
        setIsMouseDown,
        isFocused,
        setIsFocused,
        isHovered,
        setIsHovered,
        isActive,
        setIsActive,
        handleMouseDown, 
        handleMouseUp
    };
    return(
        <PillContext.Provider value={{ value }}>
            {children}
        </PillContext.Provider>
    )
}

export const usePillContext = () => {
    const pillContext = useContext(PillContext);
    if(!pillContext) throw new Error("usePillContext must be used within PillContextProvider.");
    return pillContext
}

export default PillContextProvider;