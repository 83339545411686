import React from 'react';
import '../../Feedback/FeedbackAttachment.css';
import './liveChatCard.css'

//Drop Downs for Live chat
var customerTypeDropdown = ['New Consumer', 'Existing Consumer', 'Medi-Cal Consumer']
var liveChatTopicDropdown={
                            'New Consumer' :['Check Application Status', 'General Information', 'Reset Password', 'Determine your potential program eligibility'],
                            'Existing Consumer':['Reset Password', 'Report a Change', 'Check Application Status', '1095/3895 Tax Document Help', 'Renew Coverage', 'Other Questions'],
                            'Medi-Cal Consumer':['Check Status', 'Other Questions']
                          }


class LiveChatCard extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          firstName:"",
          lastName:"",
          customerTypeDropdown: customerTypeDropdown,
          liveChatTopicDropdown: liveChatTopicDropdown,
          customerType: customerTypeDropdown[0],
          liveChatTopic: liveChatTopicDropdown[customerTypeDropdown[0]][0]
        }
      }

      handleTypingFirstName = (event)=>{
        this.setState({ firstName: event.target.value });
      }

      handleTypingLastName = (event)=>{
        this.setState({ lastName: event.target.value });
      }

      handleliveChatTopic = (event) =>{
        this.setState({ liveChatTopic: event.target.value });
      }

      handleCustomerType = (event) =>{
        
        this.setState({ customerType: event.target.value, liveChatTopic: this.state.liveChatTopicDropdown[event.target.value][0] });
      } 

      handleSubmit = () =>{
        // console.log({value: {
        //   intentName: 'liveChatRequest',
        //   parentIntent: 'liveChatRequest',
        //   title: 'liveChatRequest',
        //   firstName: this.state.firstName,
        //   lastName: this.state.lastName,
        //   reasonForLiveChat: this.state.customerType+" - "+this.state.liveChatTopic,
        //   customerType : this.state.customerType
        // }})
        this.props.postActivity({
          type: 'message',
            value: {
              intentName: 'liveChatRequest',
              parentIntent: 'liveChatRequest',
              title: 'liveChatRequest',
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              reasonForLiveChat: this.state.customerType+" | "+this.state.liveChatTopic
            }
        });
      }

 
    render() {
        const displayBlock = {
            display: 'block'
          };
      
          const displayNone = {
            display: 'none'
          };
      
        let cardLanguage = {}
        if(this.props.context.locale === "es") {
            cardLanguage = {}
            
          } else {
            cardLanguage = {
              firstName : 'First Name',
              lastName :'Last Name',
              button: 'Submit',
              heading: 'Live Chat Request Questionnaire',
              liveChatTopicHeading: 'Are you a new or existing consumer?',
              liveChatSubTopicHeading: 'Please select one topic for your Live Chat session:',
              disclaimerHeading: 'Privacy Statement',
              disclaimerBody: 'The information requested on this form is required by Covered California to assist with your request, and the information you provide will be used by Covered California solely for that purpose. Legal references authorizing the collection of the personally-identifiable information through this form include Sections 1798.22, 1798.25, 1798.27 and 1798.35 of the California Civil Code and Section 155.260(a) of the Code of Federal Regulations. California Health Benefits Exchange dba Covered California, 1601 Exposition Blvd, Sacramento, CA 95815 (800) 889-3871.'
            }
          }
  
   
  
      return (
        <div>
        <div className={ 'liveChatCard-container' }>
          <div className={ 'feedbackAttachment' }><strong>{cardLanguage.heading}</strong></div>
          <div className={ 'liveChatCard-fields__outer' }>
            <input type="text" id="firstName" placeholder={cardLanguage.firstName} className={'width-100 liveChatCard-fields'} value={ this.state.firstName } onChange={ this.handleTypingFirstName }></input>
          </div>
          <div className={ 'liveChatCard-fields__outer' }>
            <input type="text" id="lastName" placeholder={cardLanguage.lastName} className={'width-100 liveChatCard-fields' } value={ this.state.lastName } onChange={ this.handleTypingLastName }></input>
          </div>
        
          <div className={ 'liveChatCard-fields__outer' }>
            <label className={ 'text-body liveChatCard-label liveChatCard-fields__label' }>{cardLanguage.liveChatTopicHeading}</label>
            <select type="select" id="customrType" className={'width-100 liveChatCard-fields-dropdown'} value={ this.state.customerType } onChange={ this.handleCustomerType }>
            {this.state.customerTypeDropdown.map((value, index) => {
                return <option key={index} value={value}>{value}</option>
              })}
            </select>
          </div>

          <div className={ 'liveChatCard-fields__outer' }>
            <label className={ 'text-body liveChatCard-label liveChatCard-fields__label' }>{cardLanguage.liveChatSubTopicHeading}</label>
            <select type="select" id="liveChatTopic" className={'width-100 liveChatCard-fields-dropdown'} value={ this.state.liveChatTopic } onChange={ this.handleliveChatTopic }>
            {this.state.liveChatTopicDropdown[this.state.customerType].map((value, index) => {
                return <option key={index} value={value}>{value}</option>
              })}
            </select>
          </div>
          <div className={ 'text-body liveChatCard-label' }><strong>{cardLanguage.disclaimerHeading}</strong></div>
          <div className={ 'text-body liveChatCard-label-small' }>{cardLanguage.disclaimerBody}</div>
          

  
        <div className={ 'feedbackAttachment__button-div ac-actionSet' }>
          <button type="button" aria-label="Submit" className={ 'feedbackAttachment__button ac-pushButton width-100'} onClick={ this.handleSubmit }>
            <div style={this.state.submitted ? displayBlock : displayNone}>
              <span className={'cici-loading'} role="status" aria-hidden="true"></span>
            </div>
            <div style={!this.state.submitted ? displayBlock : displayNone}>
              <div className={!this.state.formValidation ? '' : 'shakeButton'}>
                {cardLanguage.button}
              </div>
            </div>
          </button>
        </div>
      </div>  
      </div>
      );
  
    }
  }
  
  export default LiveChatCard;
  