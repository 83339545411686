export function getGaId(){
    const cookieString = document.cookie;
    const cookieArrayDirty = cookieString.split(";");
    const cookieArrayClean = getCleanCookieArray(cookieArrayDirty);
    return findGaCookie(cookieArrayClean);
}

function findGaCookie(cookieArrayClean){
    const gaCookie = cookieArrayClean.find(cookieItem => cookieItem.key === "_ga");
    if(gaCookie){
        return gaCookie.value;
    }
    return "";
}

function getCleanCookieArray(cookieArrayDirty){
    const cookieArrayClean = [];
    for(let i=0; i<cookieArrayDirty.length; i++){
        const trimmed = cookieArrayDirty[i].trim();
        const keyPair = trimmed.split("=");
        const keyPairObject = {
            key: keyPair[0],
            value: keyPair[1]
        }
        cookieArrayClean.push(keyPairObject);
    }
    return cookieArrayClean;
}

const addGaIdToActivity = (action) => {
    action = { ...action, payload: { ...action.payload, activity: { ...action.payload.activity, gaId: getGaId() } } }
    return action;
}

const addPageUrlToActivity = (action) => {
    action = { ...action, payload: { ...action.payload, activity: { ...action.payload.activity, pageUrl: window.location.pathname } } }
    return action;
};

const postActivityHandler = (action) => {
    let modifiedAction = null;
    modifiedAction = addGaIdToActivity(action);
    modifiedAction = addPageUrlToActivity(modifiedAction);
    return modifiedAction;
}

export default postActivityHandler;