import React from "react";
import classes from "./Footer.module.css";


function FooterLayout({ children }) {
    return (
        <div className={`${classes['footer']}`}>
            {children}
        </div>
    )
}

FooterLayout.Footer = function ({ children }) {
    return (
        <div className={`${classes['footer__language-options']}`}>
            {children}
        </div>
    )
}

export default FooterLayout;