import React, { createContext, useState } from "react";

export const AffordabilityToolContext = createContext();

const AffordabilityToolContextProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isAffordabilityToolMenuOpen, setIsAffordabilityToolMenuOpen] = useState(false);
  const [name, setName] = useState("Ramon");
  const [coverageYear, setCoverageYear] = useState("");
  const [annualHouseholdIncome, setAnnualHouseholdIncome] = useState("");
  const [isEmployee, setIsEmployee] = useState(null);
  const [contributionAmount, setContributionAmount] = useState("");
  const [contributionFrequency, setContributionFrequency] = useState("");
  const [esiOffers, setEsiOffers] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [householdMemberType, setHouseholdMemberType] = useState("");
  const [closeModal, setCloseModal] = useState({isOpen: false, type: null});
  const [helpModal, setHelpModal] = useState({isOpen: false, type: null})
  const [offBusinessHoursHelpModal, setOffBusinessHoursHelpModal] = useState({isOpen: false, type: null})

  const value = {
    currentPage,
    setCurrentPage,
    name,
    setName,
    coverageYear,
    setCoverageYear,
    annualHouseholdIncome,
    setAnnualHouseholdIncome,
    isEmployee,
    setIsEmployee,
    householdMemberType,
    setHouseholdMemberType,
    contributionAmount,
    setContributionAmount,
    contributionFrequency,
    setContributionFrequency,
    isReady,
    setIsReady,
    esiOffers,
    setEsiOffers,
    isAffordabilityToolMenuOpen,
    setIsAffordabilityToolMenuOpen,
    closeModal,
    setCloseModal,
    helpModal,
    setHelpModal,
    offBusinessHoursHelpModal,
    setOffBusinessHoursHelpModal
  };

  return (
    <AffordabilityToolContext.Provider value={value}>
      {children}
    </AffordabilityToolContext.Provider>
  );
};

export default AffordabilityToolContextProvider;
