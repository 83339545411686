import React from "react";
import styles from "./SubHeader.module.css";

const SubHeader = ({ subHeader, style }) => {
  return (
    <div className={styles.cici_subHeader_container} style={style}>
      <div className={styles.cici_subHeader_typography}>{subHeader}</div>
    </div>
  );
};

export default SubHeader;
