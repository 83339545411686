import React from "react";
import FooterLayout from "./FooterLayout";
import FooterLanguageOptions from "./FooterLanguageOptions/FooterLanguageOptions";

function Footer() {
    return (
        <FooterLayout>
            <FooterLayout.Footer>
                <FooterLanguageOptions />
            </FooterLayout.Footer>
        </FooterLayout>
    )
}

export default Footer;