import React, { useState } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useAffordabilityTool from "../../Hooks/useAffordabilityTool";
import ChatbotSelect from "../../UiKit/ChatbotSelect/ChatbotSelect";
import Input from "../../UiKit/Wizard/Input/Input";
import InputValidationMessage from "../../UiKit/InputValidationMessage/InputValidationMessage";
import Form from "../../UiKit/Form/Form";
import useDollarAmountFormatter from "../../Utilities/getFormattedDollarAmount";
import Tooltip from "../../UiKit/Wizard/Tooltip/Tooltip";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translations";

const isEmployeeOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
];
const contributionFrequencyOptions = [
    { value: "weekly", label: "Weekly" },
    { value: "twice a month", label: "Twice a month" },
    { value: "every 2 weeks", label: "Every 2 weeks" },
    { value: "monthly", label: "Monthly" },
    { value: "quarterly", label: "Quarterly" },
    { value: "yearly", label: "Yearly" }
];

function CoverageCostPage() {
    const { next, previous, isEmployee, isEmployeeValid, updateIsEmployee, contributionAmount, isContributionAmountValid, updateContributionCost, contributionFrequency, isContributionFrequencyValid, updateContributionFrequency, householdMemberType, householdMember } = useAffordabilityTool();
    const { getFormattedDollarAmount } = useDollarAmountFormatter();
    const { currentLanguage } = useLanguage();
    const coverageCostTranslations = translations[currentLanguage]["CoverageCostPage"];
    const NavButton = translations[currentLanguage]["NavButton"];

    const [rawContributionAmount, setRawContributionAmount] = useState(contributionAmount || '');
    const [shouldShowIsEmployeeValidationError, setShouldShowIsEmployeeValidationError] = useState(false);
    const [shouldShowContributionAmountValidationError, setShouldShowContributionAmountValidationError] = useState(false);
    const [shouldShowContributionFrequencyValidationError, setShouldShowContributionFrequencyValidationError] = useState(false);

    const [shouldShowValidationError, setShouldShowValidationError] = useState(false);
    const [showTooltip, setShowTooltip] = useState(true);
    const validateIsEmployee = (isEmployee) => {
        if (!isEmployeeValid(isEmployee)) {
            setShouldShowIsEmployeeValidationError(true);
            return;
        }
        setShouldShowIsEmployeeValidationError(false);
    }

    const validateContributionAmount = (contributionAmount) => {
        if (!isContributionAmountValid(contributionAmount)) {
            setShouldShowContributionAmountValidationError(true);
            return;
        }
        setShouldShowContributionAmountValidationError(false);
    }

    const validateContributionFrequency = (contributionFrequency) => {
        if (!isContributionFrequencyValid(contributionFrequency)) {
            setShouldShowContributionFrequencyValidationError(true);
            return;
        }
        setShouldShowContributionFrequencyValidationError(false);
    }

    const onContributionAmountChange = (e) => {
        const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Remove non-numeric characters
        setRawContributionAmount(numericValue);

        if (!isContributionAmountValid(numericValue)) {
            setShouldShowContributionAmountValidationError(true);
            return;
        }

        setShouldShowContributionAmountValidationError(false);
        updateContributionCost(numericValue);
    };

    const onContributionFrequencyChange = (selectedOption) => {
        validateContributionFrequency(selectedOption.value);
        updateContributionFrequency(selectedOption.value);
    };

    const onNextClick = () => {
        validateContributionAmount(rawContributionAmount);
        if (!isContributionAmountValid(rawContributionAmount)) return;

        validateContributionFrequency(contributionFrequency);
        if (!isContributionFrequencyValid(contributionFrequency)) return;

        next();
    };

    const formattedContributionAmount = rawContributionAmount ? `$${getFormattedDollarAmount(rawContributionAmount)}` : '';
    const employeeType = currentLanguage === 'es' ? 'Empleado' : 'employee';
    const familyType = currentLanguage === 'es' ? 'familia' : 'family';
    return (
        <PageLayout>
            <PageLayout.Header style={{ marginBottom: "-17px"}}>
                <SubHeader style={{ marginBottom: "-" }} subHeader={coverageCostTranslations.subHeader} />
            </PageLayout.Header>
            <PageLayout.Content>
                <Form>
                    <Form.FormGroup>
                        {householdMemberType === employeeType ? (
                            <Form.Label style={{ display: "block" }}>
                                {coverageCostTranslations.bodyText.first}
                                <Tooltip
                                    width="200px"
                                    height="auto"
                                    message={coverageCostTranslations.bodyText.second}
                                    forcePosition="right"
                                    forcePositionSmall="right"
                                    forcePositionRight={currentLanguage === "es" ? "right" : "right"}
                                    customClassName="custom-tooltip-left"
                                />
                            </Form.Label>
                        ) : (
                            <Form.Label style={{ display: "block" }}>
                                {coverageCostTranslations.bodyText.third}
                                <Tooltip
                                    width="180px"
                                    height="auto"
                                    message={coverageCostTranslations.bodyText.fourth}
                                    forcePosition="right"
                                    forcePositionSmall="right"
                                    forcePositionRight={currentLanguage === "es" ? "left" : "down"}
                                    customClassName="custom-tooltip-left"
                                />
                            </Form.Label>
                        )}

                        <Form.Control>
                            <Input
                                placeholder={coverageCostTranslations.Placeholder}
                                type="text"
                                value={formattedContributionAmount}
                                onChange={onContributionAmountChange}
                                isValid={!shouldShowContributionAmountValidationError}
                            />
                        </Form.Control>
                        {shouldShowContributionAmountValidationError &&
                            <InputValidationMessage>
                                {coverageCostTranslations.validationMessage}
                            </InputValidationMessage>
                        }
                    </Form.FormGroup>
                    <Form.FormGroup>
                        <Form.Label>{coverageCostTranslations.frequency.frequencyLabel}</Form.Label>
                        <Form.Control>
                            <ChatbotSelect
                                options={coverageCostTranslations.frequency.frequencyOptions}
                                onChange={onContributionFrequencyChange}
                                value={contributionFrequency}
                                placeholder={coverageCostTranslations.frequency.frequencyPlaceholder}
                                isRequired={true}
                                shouldShowValidation={shouldShowContributionFrequencyValidationError}
                                inputValidationMessage={coverageCostTranslations.frequency.frequencyValidationMessage}
                            />
                        </Form.Control>
                    </Form.FormGroup>
                </Form>
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton onClick={onNextClick} type={"primary"}>{NavButton.nextButton}</NavigationButton>
                <NavigationButton onClick={() => previous()} type={"secondary"}>{NavButton.backButton}</NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

export default CoverageCostPage;
