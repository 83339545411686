import React from "react";
import Modal from "../../UiKit/Modal/Modal";
import useEnrollmentStatus from "../../Hooks/useEnrollmentStatus";



function PhoneSupportModal({ type, serviceHourData, localeValue }) {
  const { closePhoneSupportModal } = useEnrollmentStatus();
  console.log(serviceHourData.en)
  console.log(localeValue)

  let weekDayHours = [];
  let extendedHours = [];   
  let holidayHours = []; 
   
    if (serviceHourData && serviceHourData.en && serviceHourData.es) {
        weekDayHours = localeValue === "English" ? serviceHourData.en.weekDayHours : serviceHourData.es.weekDayHours;
        extendedHours = localeValue === "English" ? serviceHourData.en.extendedHours : serviceHourData.es.extendedHours;
        holidayHours = localeValue === "English" ? serviceHourData.en.holidayHours : serviceHourData.es.holidayHours;
    } else {
        console.error("serviceHourData is not defined or missing properties");
    }

    const validExtendedHours = extendedHours.filter(hour => hour.trim() !== "");

  return (
    <Modal type={type}>
      <Modal.Dialog>
        <Modal.Header>
          <Modal.Close onClick={() => closePhoneSupportModal()} />
        </Modal.Header>
        <Modal.Body>
          <Modal.Title>We're happy to help.</Modal.Title>
          <Modal.Content>
                <p> {weekDayHours.map((hour, index) => (
                                                <span key={index}>
                                                    {hour}
                                                    {index < weekDayHours.length - 1 && <br />}
                                                    </span>
                                                ))}
                </p>
                {holidayHours.length > 0 && (
                                            <>
                                                <p>{localeValue === "English" ? "Holiday Hours: " : "Horas extendidas:"}<br/>
                                                {holidayHours.map((hour, index) => <span key={index}>{hour}</span>)}
                                                </p>
                                            </>
                                        )}
                {validExtendedHours.length > 0 && (
                                            <>
                                                <br /><br />
                                                <p>{localeValue === "English" ? "Extended Hours:" : "Horas extendidas:"} <br />
                                                {validExtendedHours.map((hour, index) => <span key={index}>{hour}<br /></span>)}
                                                </p>
                                            </>
                                        )}
                
                <div style={{ marginTop: "16px" }}>
                  <a
                    href="#"
                    style={{
                      color: "#2D77A3",
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "24px",
                    }}
                  >
                    (855)708-4590
                  </a>
                </div>
          </Modal.Content>
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  );
}

export default PhoneSupportModal;
