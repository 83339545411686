import { useMemo } from 'react';

const useGroupCalculations = (data, memberDecisions) => {
    const groupByEnrollmentId = (data) => {
        return Object.values(data).reduce((acc, group) => {
            group.members.forEach(member => {
                const key = `${member.enrollmentId}`;
                (acc[key] = acc[key] || []).push(member);
            });
            return acc;
        }, {});
    };

    const calculateTotalPremium = (group, groupId) => {
        return group.reduce((total, member, memberIndex) => {
            const decisionKey = `${groupId}-${member.apsId}-${memberIndex}`;
            if (memberDecisions[decisionKey] === "keep") {
                return total + parseFloat(member.monthlyPremium);
            }
            return total;
        }, 0);
    };

    const calculateInitialTotalPremium = (group) => {
        return group.reduce((total, member) => total + parseFloat(member.monthlyPremium), 0);
    };

    const groupedMembers = useMemo(() => {
        return data
            ? groupByEnrollmentId(data)
            : {};
    }, [data]);

    const groupTotalPremiums = useMemo(() => {
        const premiums = Object.entries(groupedMembers).reduce((acc, [groupId, group]) => {
            acc[groupId] = calculateTotalPremium(group, groupId);
            return acc;
        }, {});
        return premiums;
    }, [groupedMembers, memberDecisions]);

    const initialGroupTotalPremiums = useMemo(() => {
        const premiums = Object.entries(groupedMembers).reduce((acc, [groupId, group]) => {
            acc[groupId] = calculateInitialTotalPremium(group);
            return acc;
        }, {});
        return premiums;
    }, [groupedMembers]);

    return { groupedMembers, groupTotalPremiums, initialGroupTotalPremiums };
};

export default useGroupCalculations;
