import React ,{ useState, useEffect} from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useM2C from "../../Hooks/useM2C";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import Alert from "../../UiKit/Wizard/Alert/Alert";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translations";

function ServiceErrorPage(props) {
    const { close } = useM2C();
    const { onNext, onBack } = props;
    const { currentLanguage } = useLanguage();
    const ServiceErrorTranslations = translations[currentLanguage]["ServiceErrorPage"];
    const NavButton = translations[currentLanguage]["NavButton"];
    return (
        <PageLayout>
            <PageLayout.Header>
            </PageLayout.Header>
            <PageLayout.Content>
            <Alert
                iconType='red'
                frameIcon='red'
                highlightedMessage={ServiceErrorTranslations.HighlightMessage}
                message={ServiceErrorTranslations.Message}                        
            />
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton onClick={() => close()} type="primary" style={{ marginBottom: "36px" }}>
                    { translations[currentLanguage]["RetunToChatButton"]}
                </NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}
export default ServiceErrorPage;
