// use enrollment status context
import { useContext } from "react";
import { EnrollmentStatusContext } from "../Contexts/EnrollmentStatusContext";

function useEnrollmentStatusContext(){
    const context = useContext(EnrollmentStatusContext);
    if(!context) throw new Error("useM2cToolContext must be used within the M2c tool context");
    return context;
}

export default useEnrollmentStatusContext;