import React ,{ useState, useEffect} from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useM2C from "../../Hooks/useM2C";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import {Pages} from "../../Components/M2CWizard/M2CWizard";
import Form from "../../UiKit/Form/Form";
import Input from "../../UiKit/Wizard/Input/Input";
import { FaStar } from "react-icons/fa";
import StarIcon from "../../Images/Star.svg";
import KeepIcon from "../../Images/Keep.svg";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
//import './Feedback.css';
import { translations } from "./translations";
import StarFilledIcon from "../../Images/Starfilled.svg";


function FeedbackPage(props) {
    const { close, onNext ,sendLoggingEventToBackend} = props;
    const stars = Array(5).fill(0);
    const [rating, setRating] = useState(null);     
    const [commentText, setCommentText] = useState('');
    const { currentLanguage } = useLanguage();
    const translationsFeedback = translations[currentLanguage]["FeedbackPage"];
    const NavButton = translations[currentLanguage]["NavButton"];

    const handleSubmit = (event) => {
        event.preventDefault(); 
        props.sendLoggingEventToBackend({
            intentName: "Providefeedback-MCT",
            text: "provideFeedback-MCT",
            rating: rating,
            comment: commentText
        });
        onNext(); 
    };



    return (
        <PageLayout>
            <PageLayout.Header style={{ marginBottom: "-10px" }}>
                <SubHeader subHeader={translationsFeedback.Title} />
            </PageLayout.Header>
            <PageLayout.Content>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px', marginTop: "32px", gap: "4px" }}>
                    {stars.map((_, index) => {
                        const ratingValue = index + 1;
                        return (
                            <label key={index}>
                                <input
                                    style={{ display: 'none' }}
                                    type="radio"
                                    name="rating"
                                    value={ratingValue}
                                    onClick={() => setRating(ratingValue)} 
                                />
                                <img
                                    src={ratingValue <= rating ? StarFilledIcon : StarIcon} 
                                    alt="star"
                                    style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                />
                            </label>
                        );
                    })}
                </div>
                <textarea
                    style={{
                        display: "flex",
                        height: "60px",
                        width: "100%",
                        padding: "10px 12px",
                        alignItems: "flex-start",
                        gap: "10px",
                        flexShrink: "0",
                        alignSelf: "stretch",
                        borderRadius: "3px",
                        border: "1px solid var(--Grayscale-Gray---200, #BABABC)",
                        background: "var(--Background-Default, #FFF)"
                    }}
                    placeholder={translationsFeedback.OptionalMessage}
                    onChange={e => setCommentText(e.target.value)}
                    value={commentText}
                />
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton type="primary" disabled={!rating} onClick={handleSubmit}>{NavButton.SubmitButton}</NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

export default FeedbackPage;