import React, { useState, useEffect } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useAffordabilityTool from "../../Hooks/useAffordabilityTool";
import ChatbotSelect from "../../UiKit/ChatbotSelect/ChatbotSelect";
import Form from "../../UiKit/Form/Form";
import { translations } from "./translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";

function HouseholdMemberPage() {
  const { next, previous, updateHouseholdMember, householdMemberType } = useAffordabilityTool();
  const { currentLanguage } = useLanguage();
  const householdMemberTranslations = translations[currentLanguage]["HouseholdMemberPage"];

  const householdMemberOptions = householdMemberTranslations.householdMemberOptions;

  // const householdMemberOptions = [
  //   { value: "Employee", label: "Employee" },
  //   { value: "Family Member", label: "Family Member" },
  // ];

  const NavButton = translations[currentLanguage]["NavButton"];

  const [shouldShowValidationError, setShouldShowValidationError] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const selected = householdMemberOptions.find(option => option.value === householdMemberType);
    setSelectedOption(selected);
  }, [householdMemberType, householdMemberOptions]);

  const isValid = (householdMember) => {
    if (householdMemberOptions === "") return false;
    return true;
  };

  const onNextClick = () => {
    if (!selectedOption || !isValid(selectedOption.value)) {
      setShouldShowValidationError(true);
      return;
    }
    const intentName = selectedOption === "Employee" ? (currentLanguage === "English" ? "affordabilityToolEmployeeCalculation" : "affordabilityToolEmployeeSpanish") : (currentLanguage === "English" ? "affordabilityToolFamily" : "affordabilityToolFamilySpanish");
    setShouldShowValidationError(false);
    next();
  };

  const onSelection = (selectedOption) => {
    if (!isValid(selectedOption.value)) {
      setShouldShowValidationError(true);
      return;
    }
    setShouldShowValidationError(false);
    setSelectedOption(selectedOption);
    updateHouseholdMember(selectedOption.value);
  };

  return (
    <PageLayout>
      <PageLayout.Header >
        <SubHeader
          style={{ display: "flex", marginBottom: "8px" }}
          subHeader={householdMemberTranslations.subHeader}
        />
      </PageLayout.Header>
      <PageLayout.Content>
        <div id="Form-body" style={{ display: "flex", paddingBottom: "24px" }}>
          <Form.Label>{householdMemberTranslations.bodyText.first}</Form.Label>
        </div>
        <div id="Form-body-1" style={{ display: "flex", gap: "8px" }} >
          <Form.Label>{householdMemberTranslations.bodyText.second}</Form.Label>
        </div>
        <ChatbotSelect
          options={householdMemberOptions}
          onChange={onSelection}
          value={selectedOption ? selectedOption.value : ""}
          placeholder={householdMemberTranslations.Placeholder}
          isRequired={true}
          shouldShowValidation={shouldShowValidationError}
          inputValidationMessage={householdMemberTranslations.validationMessage}
        />
      </PageLayout.Content>
      <PageLayout.Navigation>
        <NavigationButton onClick={onNextClick} type="primary">
          {NavButton.nextButton}
        </NavigationButton>
        <NavigationButton onClick={() => previous()} type="secondary">
          {NavButton.backButton}
        </NavigationButton>
      </PageLayout.Navigation>
    </PageLayout>
  );
}

export default HouseholdMemberPage;