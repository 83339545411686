import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useM2C from "../../Hooks/useM2C";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import welcomeImage from "../../Images/Welcome.svg";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translations";

function WelcomePage(props) {
    const { next } = useM2C();
    const { currentLanguage } = useLanguage();
    const { sendLoggingEventToBackend, onNext } = props;
    const welcomePageTranslations = translations[currentLanguage]["WelcomePage"];
    const NavButton = translations[currentLanguage]["NavButton"];
    const lang = currentLanguage;

    const handleNextClick = () => {
        onNext();
    
        props.sendLoggingEventToBackend({
          intentName: lang === "en" ? "M2CGettingStarted" : "M2CGettingStartedSpanish",
          text: lang === "en" ? "M2CGettingStarted" : "M2CGettingStartedSpanish"
        });
      };
      
    return (
        <PageLayout>
            <PageLayout.Content>
                <Content welcomePageTranslations={welcomePageTranslations} NavButton={NavButton}/>
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton onClick={handleNextClick} type="primary" style={{ marginBottom: "36px" }}>
                    {NavButton.BeginButton}
                </NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

function Content({ welcomePageTranslations }) {
    return (
        <div
            style={{
                fontSize: '21px',
                lineHeight: '29.4px',
                fontWeight: 'normal',
                margin: '0',
                paddingTop:'32px'
            }}
        >
            <img src={welcomeImage} style={{ paddingBottom: "24px"}} alt={welcomePageTranslations.alt} />
            <SubHeader
                style={{ display: 'flex', marginBottom: '8px', marginTop: '0px' }}
                subHeader={welcomePageTranslations.Title}
            />
            <Body
                bodyText={
                    <>
                        <div style={{ marginTop: '10px' }}>
                           <strong>{welcomePageTranslations.Body}</strong> {welcomePageTranslations.Body1}
                        </div>
                    </>
                }
            />
        </div>
    );
}

export default WelcomePage;