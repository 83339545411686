import React ,{ useState, useEffect} from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useM2C from "../../Hooks/useM2C";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import {Pages} from "../../Components/M2CWizard/M2CWizard";
import Form from "../../UiKit/Form/Form";
import Input from "../../UiKit/Wizard/Input/Input";
import { translations } from "./translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";

function ThankYouPage(props) {
    const { closeM2C } = useM2C();
    const { onNext, onBack } = props;
    const { currentLanguage } = useLanguage();
    const ThankyouTranslations = translations[currentLanguage]["ThankYouPage"];
    const NavButton = translations[currentLanguage]["NavButton"];
    return (
        <PageLayout>
            <PageLayout.Header>
                <SubHeader subHeader={ThankyouTranslations.Title} />
            </PageLayout.Header>
            <PageLayout.Content>
                <Body style={{ }}
                    bodyText={
                        <>
                            <div style={{ marginTop: '10px' }}>
                                {ThankyouTranslations.Body}
                            </div>
                        </>
                    }
                />
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton onClick={() => closeM2C()} type="primary" style={{ marginBottom: "36px" }}>
                    {NavButton.ExitButton}
                </NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}
export default ThankYouPage;
