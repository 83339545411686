import React, { useState } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useAffordabilityTool from "../../Hooks/useAffordabilityTool";
import Input from "../../UiKit/Wizard/Input/Input";
import InputValidationMessage from "../../UiKit/InputValidationMessage/InputValidationMessage";
import Form from "../../UiKit/Form/Form";
import useDollarAmountFormatter from "../../Utilities/getFormattedDollarAmount";
import { translations } from "./translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";

function IncomePage() {
    const { next, previous, annualHouseholdIncome, updateHouseholdIncome, coverageYear } = useAffordabilityTool();
    const { getFormattedDollarAmount } = useDollarAmountFormatter();

    const [rawIncome, setRawIncome] = useState(annualHouseholdIncome || '');
    const [shouldShowValidationError, setShouldShowValidationError] = useState(false);

    const  { currentLanguage } = useLanguage();
    const incomeTranslations = translations[currentLanguage]["IncomePage"];
    const NavButton = translations[currentLanguage]["NavButton"];

    const isValid = (income) => {
        return income !== "" && !isNaN(income.replace(/[^0-9.]/g, ''));
    };

    const onNextClick = () => {
        if (!isValid(rawIncome)) {
            setShouldShowValidationError(true);
            return;
        }
        setShouldShowValidationError(false);
        next();
    };

    const onIncomeChange = (income) => {
        const numericValue = income.replace(/[^0-9.]/g, ''); 
        setRawIncome(numericValue);

        if (!isValid(numericValue)) {
            setShouldShowValidationError(true);
            return;
        }

        setShouldShowValidationError(false);
        updateHouseholdIncome(numericValue);
    };

    const formattedIncome = rawIncome ? `$${getFormattedDollarAmount(rawIncome)}` : '';

    return (
        <PageLayout>
            <PageLayout.Header style={{ marginBottom: "-17px"}}>
                <SubHeader  style={{marginBottom: ""}} subHeader={incomeTranslations.subHeader} />
            </PageLayout.Header>
            <PageLayout.Content>
                <Form>
                    <Form.FormGroup>
                        <Form.Label>{incomeTranslations.bodyText.first} {coverageYear}?</Form.Label>
                        <Form.Control>
                            <Input
                                placeholder={incomeTranslations.Placeholder}
                                type="text"
                                value={formattedIncome}
                                onChange={(e) => onIncomeChange(e.target.value)}
                                isValid={!shouldShowValidationError}
                            />
                        </Form.Control>
                        {shouldShowValidationError &&
                            <InputValidationMessage>
                               {incomeTranslations.validationMessage}
                            </InputValidationMessage>
                        }
                    </Form.FormGroup>
                </Form>
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton onClick={onNextClick} type={`primary`}>{NavButton.nextButton}</NavigationButton>
                <NavigationButton onClick={() => previous()} type={"secondary"}> {NavButton.backButton}</NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

export default IncomePage;
