import React, { useState } from 'react';
import ArrowIcon from './arrow.svg';
import UpArrowIcon from './upArrow.svg';
import styles from './Expansion.module.css';
import { useLanguage } from '../langugeContext';
import { translations } from '../../../Components/AffordabilityToolWizard/translations';

function ExpansionToggle({ children }) {
  const [showMessage, setShowMessage] = useState(false);
  const { currentLanguage } = useLanguage();
  const expansionTranslations = translations[currentLanguage]["ExpansionToggle"];

  const toggleMessage = () => {
    setShowMessage(!showMessage);
  };

  return (
    <div className={styles.expansionToggle}>
      {React.Children.map(children, child => {
        if (child.type === ExpansionToggle.Header) {
          return React.cloneElement(child, { toggleMessage, showMessage, expansionTranslations });
        }
        return null; 
      })}
      {showMessage && (
        <div className={styles.messageDetails}>
          {React.Children.map(children, child => {
            if (child.type === ExpansionToggle.Content) {
              return child;
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
}

ExpansionToggle.Header = function ExpansionToggleHeader({ children, toggleMessage, showMessage, expansionTranslations }) {
  return (
    <div className={styles.toggleHeader} onClick={toggleMessage}>
      <h3 className={styles.toggleHeading}>{showMessage ? expansionTranslations.Less: expansionTranslations.More}</h3>
      <img
        className={styles.toggleIcon}
        src={showMessage ? UpArrowIcon : ArrowIcon}
        alt="Toggle Icon"
        width="12"
        height="12"
      />
    </div>
  );
}

ExpansionToggle.Content = function ExpansionToggleContent({ children }) {
  return (
    <div>
      {children}
    </div>
  );
}

export default ExpansionToggle;
