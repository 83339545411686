const pushToDatalayer = (event, eventName, eventParams) => {
    try {
        window.dataLayer.push({
            event: "chatbot",
            eventDetails: {
                name: ""
            }
        });
    } catch (error) {
        console.log("chatbot action could not be pushed to the datalayer")
    }
}

const pushLogEventToDataLayer = (action) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "chatbot",
        eventDetails: {
            name: action.payload.activity.value.event
        }
    });
}

export default {
    pushToDatalayer,
    pushLogEventToDataLayer
}