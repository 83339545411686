import React, { useContext } from "react";
import classes from "./ChatMenu.module.css";
function ChatMenu({ isOpen, children }) {
  const menuActiveClass = `${classes["chat-menu"]} ${isOpen ? "" : classes["chat-menu--inactive"]}`;
  return (
    <div
      className={menuActiveClass}
    >
      {children}
    </div>
  );
}


ChatMenu.Header = ({ children }) => {
  return (
    <div className={`${classes["chat-menu__header"]}`}>
      {children}
    </div>
  )
}

ChatMenu.HeaderBackButtonContainer = ({ children }) => {
  return (
    <div
      className={`${classes["chat-menu__header-back-button-container"]}`}
    >
      {children}
    </div>
  )
};

ChatMenu.HeaderBackButton = ({ children, onClose }) => {
  return (
    <button
      onClick={() => onClose()}
      className={`${classes["chat-menu__header-back-button"]}`}
    >
      {children}
    </button>
  )
}

ChatMenu.HeaderText = ({ children }) => {
  return (
    <div className={`${classes["chat-menu__header-text"]}`}>
      {children}
    </div>
  )
}

ChatMenu.Body = ({ children }) => {
  return (
    <div className={`${classes["chat-menu__body"]}`}>
      {children}
    </div>
  )
};

ChatMenu.Content = ({ children }) => {
  return (
    <div className={`${classes["chat-menu__content"]}`}>
      {children}
    </div>
  )
}

ChatMenu.Items = ({ children }) => {
  return (
    <div className={`${classes["chat-menu__items"]}`}>
      {children}
    </div>
  )
}

ChatMenu.Item = ({ children }) => {
  return (
    <button className={`${classes["chat-menu__item"]}`}>
      {children}
    </button>
  )
}

ChatMenu.ItemsTitle = ({ children }) => {
  return (
    <div className={`${classes["chat-menu__items-title"]}`}>
      {children}
    </div>
  )
}


ChatMenu.MainActions = ({ children }) => {
  return (
    <div className={`${classes["chat-menu__main-actions"]}`}>
      {children}
    </div>
  )
}

ChatMenu.MainAction = ({ children, onClick }) => {
  return (
    <button
      onClick={() => onClick()}
      className={`${classes["chat-menu__main-action"]}`}
    >
      {children}
    </button>
  )
}

ChatMenu.Footer = ({ children }) => {
  return (
    <div className={`${classes["chat-menu__footer"]}`}>
      {children}
    </div>
  )
}

ChatMenu.FooterItems = ({ children }) => {
  return (
    <div className={`${classes["chat-menu__footer-items"]}`}>
      {children}
    </div>
  )
}

ChatMenu.FooterItem = ({ children }) => {
  return (
    <button className={`${classes["chat-menu__footer-item"]}`}>
      {children}
    </button>
  )
}

/**
 * 
 
<div className={`${classes["chat-menu__header"]}`}>
        <div
          className={`${classes["chat-menu__header-back-button-container"]}`}
        >
          <button
            onClick={() => onClose()}
            className={`${classes["chat-menu__header-back-button"]}`}
          >
            <img src={backButtonIcon} alt="Back Button" />
          </button>
        </div>
        <div className={`${classes["chat-menu__header-text"]}`}>Chat Menu</div>
      </div>
      <div className={`${classes["chat-menu__content"]}`}>
        <div className={`${classes["chat-menu__title"]}`}>Common Topics</div>
        <div className={`${classes["chat-menu__items"]}`}>
          <button className={`${classes["chat-menu__item"]}`}>
            How to get health coverage
          </button>
          <button className={`${classes["chat-menu__item"]}`}>
            Dental and vision coverage
          </button>
          <button className={`${classes["chat-menu__item"]}`}>
            My account
          </button>
          <button className={`${classes["chat-menu__item"]}`}>
            My Medi-Cal is ending
          </button>
        </div>
      </div>
      <div className={`${classes["chat-menu__main-actions"]}`}>
        <button
          onClick={() => onClose()}
          className={`${classes["chat-menu__main-action"]}`}
        >
          Resume chat
        </button>
      </div>
      <div className={`${classes["chat-menu__footer"]}`}>
        <div className={`${classes["chat-menu__footer-items"]}`}>
          <button className={`${classes["chat-menu__footer-item"]}`}>
            Switch to Live Chat
          </button>
          <button className={`${classes["chat-menu__footer-item"]}`}>
            Privacy Policy
          </button>
          <button className={`${classes["chat-menu__footer-item"]}`}>
            How to Ask a Question
          </button>
        </div>
      </div>



 */

export default ChatMenu;
