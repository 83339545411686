import Feedback from "react-bootstrap/esm/Feedback";

const Es = "es";
const En = "en";

export const translations = {
  en: {
    IntroPage: {
      subHeader: "Employer-Sponsored Health Insurance Affordability Tool",
      bodyText: {
        first:
          "Every household is different. Some family members may qualify for health insurance through another family member's job, while others do not. Different jobs have different rules about who is eligible for insurance.",
        second:
          "Use this tool to check <strong>each offer</strong> of insurance available to <strong>each household member</strong>.",
      },
      BeginButton: "Begin",
    },
    RetunToChatButton: "Return to Chat",
    WelcomePage: {
      subHeader: "To start, you need to know:",
      bodyText: {
        first: "The estimated household income for the coverage year.",
        second:
          "If the health insurance offered meets the minimum value standard.",
        third:
          "How much the lowest-cost health plan costs for employee-only and family coverage",
        fourth: "If you aren't sure, you can ask your employer to fill out our",
        fifth: "Employer Coverage Worksheet.",
      },
      tooltip: {
        message1:
          "Include the income for you, your spouse, and anyone you claim as a dependent when you file taxes. Income includes wages and tips, salary, self-employment income, interest and dividends received, alimony received, Social Security payments received and other income.",
        message2:
          "Plan pays at least 60% of the total cost of medical services and substantially covers hospital and doctor services.",
      },
      link:"https://www.coveredca.com/pdfs/Employer-Sponsored-Coverage-Worksheet.pdf"
    },
    WelcomePage1: {
      subHeader: "Please Answer Carefully",
      bodyText: {
        first:
          "If you report the wrong amounts, you might get financial help you don't qualify for and may have to repay some or all of it.",
      },
    },
    HouseholdMemberPage: {
      subHeader: "Household Member",
      bodyText: {
        first: "For this offer of coverage:",
        second: "Is the household member the employee or family member?",
      },
      householdMemberOptions: [
        { value: "Employee", label: "Employee" },
        { value: "familyMember", label: "Family Member" },
      ],
      validationMessage: "Please make a selection",
      Placeholder: "Please select",
    },
    CoverageYearPage: {
      subHeader: "Coverage Year",
      bodyText: {
        first: "Choose the year you want your coverage to begin.",
      },
      Placeholder: "Select year",
      validationMessage: "Enter a year",
    },
    IncomePage: {
      "subHeader": "Income",
      "bodyText": {
        "first": "What's your total estimated household income for",
      },
      "Placeholder": "Enter income",
      "validationMessage": "Enter your household income",
    },
    CoverageCostPage: { 
      "subHeader": "Coverage Costs",
      "bodyText": {
        "first": "Using the lowest-cost plan offered, what does employee-only coverage cost?",
        "second": "Employee-only may also be referred to as 'Single'.",
        "third": "Using the lowest-cost plan offered, what does family coverage cost?",
        "fourth": "Amount may vary by number of family members. Enter the amount for your family size.",
      },
      "frequency":{
        "frequencyLabel": "How often would this amount be paid?",
        "frequencyOptions": [
          { value: "weekly", label: "Weekly" },
          { value: "twice a month", label: "Twice a month" },
          { value: "every 2 weeks", label: "Every 2 weeks" },
          { value: "monthly", label: "Monthly" },
          { value: "quarterly", label: "Quarterly" },
          { value: "yearly", label: "Yearly" }
        ],
        "frequencyValidationMessage": "Please make a selection",
        "frequencyPlaceholder": "Select how often",
      },
      "validationMessage": "Enter the cost of the lowest-cost plan offered",
      "Placeholder": "Enter amount",
    },
    ReviewAndConfirmPage: {
      "subHeader": "Review and Confirm",
      "householdMember": "Household Member",
      "coverageYear": "Year Of Coverage",
      "annualHouseholdIncome": "Estimated Total Household Income for",
      "contributionAmount": "Health Coverage Costs",
      "contributionFrequency": "Paid",
      "employee": "for employee-only coverage",
      "family": "for family coverage",
      "edit": "Edit",
      "paid": "Paid",
    },
    ResultsPage: {
      "subHeader": "Affordability Results",
      "bodyText": {
        "first": "Based on the information you provided, here are your results:",
        "second": "You may qualify for help paying for health insurance.",
      },
      affordableResults: {
        employeeHeading:"Employee-only Coverage is",
        affordable:"Affordable",    
        notAffordable: "Not Affordable", 
        familyHeading:"Family Coverage is",
        detailMessage: {
          affordable: "Based on the information provided, your offer of employer-sponsored coverage is considered 'affordable'. This means that you do not qualify for financial help through Covered California, but may still ",
          notAffordable: "Based on the information provided, your offer of employer-sponsored coverage is considered 'unaffordable'. This means that you may qualify for financial help through Covered California when you "
        }
      },
      coverageCost:"Coverage cost: ",
      mayQualify: "May qualify for financial help",
      mayNotQualify: "Does not qualify for financial help",
      ofIncome: "of income",
      checkAnotherOfferButton: "Check another offer of coverage",
      closeButton:"Close Tool",
      enroll:"enroll"
  },
  ExpansionToggle:{
    More: "More",
    Less: "Less",
  },
    NavButton: {
      nextButton: "Next",
      backButton: "Back",
      SubmitButton: "Submit",
      GetMyResultsButton: "Get my results",
    },
    CloseModal : {
      ModalTitle: "Before You Exit",
      ModalContent: "If you leave now, you will lose any information you entered.",
      returnButton: "Return to tool",
      exitButton: "Exit without saving",
    },
    ToolMenu: {
      ToolMenuTitle: "Menu",
      liveHelp: {
        liveHelp: "Live Help",
        liveChat: "Live Chat",
        inPersonHelp: "In-Person Help",
        phoneSupport: "Phone Support"
      },
      commonChatTopics: {
        commonChatTopics: "Common Chat Topics",
        iNeedCoverage: "I need coverage",
        accountHelp: "Account help",
        myMedicalisEnding: "My Medi-Cal is ending",
      },
      language: {
        language: "Language",
        english: "English",
        spanish: "Spanish"
      },
      privacy: {
        privacy: "Privacy",
        privacyPolicy: {
          privacyPolicyFirst: "Do not share Personally Identifiable Information, like your Social Security Number, date of birth, case number, or last name.",
          privacyPolicyLink: "View our",
          privacyPolicySecond: "Privacy Policy",
        },
      },
      Feedback: {
        Feedback: "Feedback",
        feedbackText:{
          first:"We'd love to know how we are doing. Let us know by",
          second: "providing feedback",
        }
      },
      ToolMenuFooter: {
        label: "Resume Chat"
      }
      
    },
    VirtualAssistant: {
        VirtualAssistant: "Virtual Assistant",
      }
  },
  es: {
    IntroPage: {
      subHeader:
        "Herramienta de asequibilidad para el seguro de salud patrocinado por el empleador",
      bodyText: {
        first:
          "Cada hogar es diferente. Algunos miembros de la familia podrían calificar para un seguro de salud a través del trabajo de otro miembro de la familia, mientras que otros no. Diferentes trabajos tienen diferentes reglas sobre quién es elegible para recibir seguro.",
        second:
          "Utiliza esta herramienta para revisar <strong>cada oferta</strong> de seguro disponible para <strong>cada miembro del hogar</strong>.",
      },
      BeginButton: "Comenzar",
    },

    RetunToChatButton: "Volver al chat",
    WelcomePage: {
      subHeader: "Antes de iniciar, necesitarás:",
      bodyText: {
        first: "El ingreso total estimado del hogar para el año de cobertura.",
        second:
          "Si el seguro de salud que ofrece tu trabajo cumple con el estándar de valor mínimo.",
        third:
          "La cantidad que cuesta el plan de salud de menor costo para la cobertura solo para el empleado y familiar.",
        fourth:
          "Si necesitas ayuda, puedes pedirle a tu empleador que complete nuestra",
        fifth: "Hoja de trabajo de cobertura del empleador.",
      },
      tooltip: {
        message1:
          "Incluye tus ingresos, los de tu cónyuge y los de todas las personas que reclames como dependientes cuando declares impuestos. La cantidad debe incluir todos los ingresos que recibes, como sueldos, ingresos de trabajo por cuenta propia, intereses, dividendos, pensión alimenticia, y Seguro Social.",
        message2:
          "El plan paga al menos el 60% del costo total de los servicios médicos y cubre en gran medida los servicios hospitalarios y médicos.",
      },
      link:"https://www.coveredca.com/pdfs/SPANISH-VERSION-Pdf-Employer-Sponsored-Coverage-Worksheet-(PDF)_12-07-2022.pdf"
    },
    WelcomePage1: {
      subHeader: "Por favor responde cuidadosamente",
      bodyText: {
        first:
          "Si declaras las cantidades incorrectas, podrías recibir ayuda financiera para la que no calificas y podrías tener que devolver parte o toda la cantidad.",
      },
    },
    HouseholdMemberPage: {
      subHeader: "Miembro del hogar",
      bodyText: {
        first: "Para esta oferta de cobertura:",
        second:
          "¿Es el miembro del hogar el empleado o un miembro de la familia?",
      },
      householdMemberOptions: [
        { value: "Empleado", label: "Empleado" },
        { value: "Miembro de la familia", label: "Miembro de la familia" },
      ],
      validationMessage: "Por favor haz una selección",
      Placeholder: "Selecciona una respuesta",
    },
    CoverageYearPage: {
      subHeader: "Año de cobertura",
      bodyText: {
        first: "Elige el año en que deseas que comience tu cobertura.",
      },
      Placeholder: "Selecciona el año",
      validationMessage: "Ingresa un",
    },
    IncomePage: {
      subHeader: "Ingresos",
      bodyText: {
        first: "¿Cuál es el ingreso total estimado de tu hogar para",
      },
      Placeholder: "Ingresa la cantidad",
      validationMessage: "Ingresa la cantidad de los ingresos de tu hogar.",
    
    },
    CoverageCostPage: { 
      subHeader: "Costos de cobertura de salud",
      bodyText: {
        first: "Utilizando el plan de menor costo que ofrece tu trabajo, ¿cuánto cuesta la cobertura solo para el empleado?",
        second: "“Solo para el empleado” también se podría referir como cobertura “individual”.",
        third: "Utilizando el plan de menor costo que ofrece tu trabajo, ¿cuánto cuesta la cobertura familiar?",
        fourth: "El costo podría variar según el número de miembros de la familia. Ingresa el costo para el tamaño de tu familia.",
      },
      frequency:{
        frequencyLabel: "¿Con qué frecuencia se pagaría esta cantidad?",
        frequencyOptions: [
          { value: "semanalmente", label: "Semanalmente" },
          { value: "dos veces al mes", label: "Dos veces al mes" },
          { value: "cada dos semanas", label: "Cada dos semanas" },
          { value: "mensualmente", label: "Mensualmente" },
          { value: "cada tres meses", label: "Cada tres meses" },
          { value: "anualmente", label: "Anualmente" }
        ],
        frequencyValidationMessage: "Selecciona con  qué frecuencia se pagaría esta cantidad.",
        frequencyPlaceholder: "Selecciona la frecuencia",
      },
      validationMessage: "Ingresa el costo de la cobertura familiar",
      Placeholder: "Ingresa la cantidad",
    
    },
    ReviewAndConfirmPage:{
      subHeader: "Revisar y confirmar",
      householdMember: "Miembro del hogar",
      coverageYear: "Año de cobertura",
      annualHouseholdIncome: "Total estimado de los ingresos del hogar para",
      contributionAmount: "Costos de cobertura de salud",
      contributionFrequency: "Pagado",
      employee: "para la cobertura solo para el empleado",
      family: "para la cobertura familiar",
      edit: "Cambiar",
      paid: "Pagado",
    },
    ResultsPage: {
      subHeader: "Resultados de asequibilidad",
      bodyText: {
        first: "Basándonos en la información que proporcionaste, estos son tus resultados:",
        second: "Podrías calificar para recibir ayuda para pagar el seguro de salud.",
      },
      affordableResults: {
        employeeHeading:"La cobertura solo para el empleado es",
        affordable:"asequible",
        notAffordable: "no es asequible",
        familyHeading:"La cobertura familiar",
        detailMessage: {
          affordable: "Basado en la información que nos diste, la cobertura que te ofrece tu trabajo se considera “no asequible”. Esto significa que tu familia podría calificar para recibir ayuda financiera a través de Covered California cuando te",
          notAffordable: "Basado en la información que nos diste, la cobertura que te ofrece tu trabajo se considera “asequible”. Esto significa que tu familia no califica para recibir ayuda financiera a través de Covered California, cuando te"
        }
      },
      coverageCost:"Costo de la cobertura: ",
      mayQualify: "Podría calificar para la ayuda financiera",
      mayNotQualify: "No califica para la ayuda financiera",
      ofIncome: "de los ingresos",
      checkAnotherOfferButton:"Revisar otra oferta de cobertura",
      closeButton:"Cerrar herramienta",
      enroll:"inscribes"
    },
   ExpansionToggle: {
      More: "Más",
      Less: "Menos",
    },
    NavButton: {
      nextButton: "Siguiente",
      backButton: "Volver",
      SubmitButton: "Enviar",
      GetMyResultsButton: "Obtener mis resultados",
    },
    CloseModal : {
      ModalTitle: "Antes de que salgas.",
      ModalContent: "Si sales ahora, perderás toda la información que ingresaste.",
      returnButton: "Volver a la herramienta",
      exitButton: "Salir sin guardar",
    },
    ToolMenu: {
      ToolMenuTitle: "Menú",
      liveHelp: {
        liveHelp: "Ayuda por teléfono",
        liveChat: "Chat en vivo",
        inPersonHelp: "Ayuda en persona",
        phoneSupport: "Apoyo por teléfono"
      },
      commonChatTopics: {
        commonChatTopics: "Temas de chat comunes",
        iNeedCoverage: "Necesito cobertura",
        accountHelp: "Ayuda con la cuenta",
        myMedicalisEnding: "Mi Medi-cal está por terminar",
      },
      language: {
        language: "Idioma",
        english: "Inglés",
        spanish: "Español"
      },
      privacy: {
        privacy: "Privacidad",
        privacyPolicy: {
          privacyPolicyFirst: "No compartas información de identificación personal, como tu número de Seguro Social, fecha de nacimiento, número de caso o apellido.",
          privacyPolicyLink: "Leé nuestra",
          privacyPolicySecond: "Política de privacidad",
        },
      },
      Feedback: {
        Feedback: "Comentarios",
        feedbackText:{
          first:"Nos encantaría saber cómo fue tu experiencia. Déjanos tus",
          second: "comentarios",
        }
      },
      ToolMenuFooter: {
        label: "Continuar con chat"
      },
      
    },
    VirtualAssistant: {
        VirtualAssistant: "Asistente Virtual",
      }
  },
};
