import React from "react";
import classes from "./HeaderTitle.module.css";
import ciciIcon from "../../../../Images/header-cici-icon.svg";
import { translations } from "../../../../Components/AffordabilityToolWizard/translations";
import { useLanguage } from "../../../../UiKit/Wizard/langugeContext";


function HeaderTitle() {
    const { currentLanguage } = useLanguage();
    const welcomePageTranslations = translations[currentLanguage]['VirtualAssistant'];
    const icon = <img src={ciciIcon} alt="Cici" />;
    return (
        <div className={`${classes['header-title']}`}>
            {icon}
            <div className={`${classes['header-title__text']}`}>
                {welcomePageTranslations.VirtualAssistant}
            </div>
        </div>
    )
}

export default HeaderTitle;