import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import usePasswordReset from "../../Hooks/usePasswordReset";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import useChatbot from "../../Hooks/useChatbot";
import useServiceHours from "../../Hooks/useServiceHours";

function ServiceCenterHoursPage(props) {
  const { closePasswordReset, setCurrentPage, resetPasswordResetState } =
    usePasswordReset();
  const { closePasswordResetWizard, openWelcomePage } = useChatbot();
  const { isServiceHour, serviceHourData } = useServiceHours();

  return (
    <PageLayout>
      <PageLayout.Header>
        <SubHeader subHeader="Call us to reset your password." />
      </PageLayout.Header>
      <PageLayout.Content>
        <Body
          style={{ fontSize: "16px" }}
          bodyText={
            <>
              <div style={{ marginTop: "10px" }}>
                You do not have a verification method on file.
              </div>
              <div style={{ marginTop: "32px" }}>
                <strong>Covered California Service Center</strong>
                <br />
                <a
                  href={`tel:8557084590`}
                  style={{ color: "#2D77A3", textDecoration: "underline" }}
                >
                  (855) 708-4590
                </a>
                <br />
                {serviceHourData && serviceHourData.weekDayHours && (
                  <div>
                    {serviceHourData.weekDayHours.map(
                      (hour, index) => hour && <div key={index}>{hour}</div>
                    )}
                    {serviceHourData.extendedHours &&
                      serviceHourData.extendedHours.map(
                        (hour, index) => hour && <div key={index}>{hour}</div>
                      )}
                  </div>
                )}
              </div>
            </>
          }
        />
      </PageLayout.Content>
      <PageLayout.Navigation>
        <NavigationButton
          // onClick={() => closePasswordReset()}
          onClick={() => closePasswordResetWizard()}
          // onClick={handleStartOver}  // Navigate to WelcomePage
          type="primary"
          style={{ marginBottom: "36px" }}
        >
          Exit
        </NavigationButton>
      </PageLayout.Navigation>
    </PageLayout>
  );
}

export default ServiceCenterHoursPage;