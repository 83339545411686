import axios from 'axios';

const CHATBOT_TOKEN_BASE_URL = 'https://supreme-guacamole-pjp7q49qjq9cx4-3005.app.github.dev';

const getToken = async () => {
    const {data} = await axios.get(`/getToken`);
    return data;
}
const refreshToken =  async (conversationId) => {
    const {data} = await axios.get(`/refreshToken/${conversationId}`);
    return data;
}

export default {
    getToken,
    refreshToken
}