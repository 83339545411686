import React from "react";
import styles from "./Body.module.css";

function Body({ bodyText, style }) {
    return (
        <>
            <p className={styles.cici_bodyText} style={style}>{bodyText}</p>
        </>
      );
    };
    
export default Body;
