import React, { useState, useEffect, useRef, useContext } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import usePasswordReset from "../../Hooks/usePasswordReset";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Form from "../../UiKit/Form/Form";
import Input from "../../UiKit/Wizard/Input/Input";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import Body from "../../UiKit/Wizard/Body/Body";
import sendEventToDataLayer from "../../___archive/datalayer-event-logger-for-transactions/sendEventToDataLayer";
import InputMask from 'react-input-mask';
import ReCAPTCHA from "react-google-recaptcha";
import InputValidationMessage from "../../UiKit/InputValidationMessage/InputValidationMessage";
import { PasswordResetContext } from "../../Contexts/PasswordResetContext";
import  Alert  from "../../UiKit/Wizard/Alert/Alert";
import useChatbot from "../../Hooks/useChatbot";
import PrivacyStatementModal from "./PrivacyStatementModal";
import './PasswordReset.css';

const CAPTCHA_SITE_KEY = process.env.NODE_ENV === 'development' ? "6Lea14UkAAAAADFKcSV8372FNO5CoTEy4RLktKjW" : "6Ld32mQkAAAAAJDLX9kgVM56Zl8-IgOj3Xm9GawQ";

function ResetPasswordPage(props) {
  const { next, closeModal, openRelativeCloseModal } = usePasswordReset();
  const { username, setUsername, dob, setDob,exceededAttemptMessage,userNotFound, serviceNotAvailable } = useContext(PasswordResetContext);
  // const [username, setUsername] = useState("");
  // const [dob, setDob] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isCaptchaHidden, setIsCaptchaHidden] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUsernameValidationVisible, setIsUsernameValidationVisible] = useState(false);
  const [isDobValidationVisible, setIsDobValidationVisible] = useState(false);
  const [isPrivacyStatementExpanded, setIsPrivacyStatementExpanded] = useState(false);
  const captchaRef = useRef();
  const alertRef = useRef(); 
  const {closePasswordResetWizard} = useChatbot();
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const { onNext, postActivity, sendActivity, onPrevious} = props;
  const [alertReady, setAlertReady] = useState(false);



  const onSubmit = async () => {
    let hasError = false;

    if (!isUsernameValid(username)) {
      setIsUsernameValidationVisible(true);
      hasError = true;
    } else {
      setIsUsernameValidationVisible(false);
    }

    if (!isDobValid(dob)) {
      setIsDobValidationVisible(true);
      hasError = true;
    } else {
      setIsDobValidationVisible(false);
    }

    if (hasError) {
      return;
    }

    // const token = await captchaRef.current.executeAsync();
    const token = '';
    sendActivity({
      type: 'message',
      value: {
        username: username,
        dob: dob,
        captchaToken: token,
        type: 'forgotPasswordCardClick',
        intentName: 'forgotPassword'
      }
    });
    setIsCaptchaHidden(false);
    setIsSubmitted(true);
  };

  const onForgotUsername = () => {
    sendEventToDataLayer({
      event: "chatbot",
      eventDetails: {
        category: "CiCi",
        action: "Password Reset Flow",
        label: "'Forgot Username' Link Click"
      }
    });
    postActivity({
      type: 'message',
      value: {
        intentName: 'forgotUsername'
      }
    });
    setIsSubmitted(true);
  };

  const onCaptchaChange = (newCaptcha) => {
    setCaptchaToken(newCaptcha);
    updateSubmitButtonState(username, dob, newCaptcha);
  };

  const isUsernameValid = (username) => {
    if (!username || username === "") {
      return false;
    }
    return true;
  };

  const isDobValid = (dob) => {
    if (dob === "") {
      return false;
    }
    if (dob === "__/__") {
      return false;
    }
    if (isNaN(new Date(dob + "/2022"))) {
      return false;
    }
    return true;
  };

  const areInputsValid = (username, dob) => {
    if (!isUsernameValid(username)) {
      return false;
    }
    if (!isDobValid(dob)) {
      return false;
    }
    return true;
  };

  const updateSubmitButtonState = (username, dob, captcha) => {
    if (areInputsValid(username, dob)) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const onUsernameUpdate = (event) => {
    const newUsername = event.target.value;
    setUsername(newUsername);
    if (!isUsernameValid(newUsername)) {
      setIsUsernameValidationVisible(true);
    } else {
      setIsUsernameValidationVisible(false);
    }
    updateSubmitButtonState(newUsername, dob, captchaToken);
  };

  const onDobUpdate = (event) => {
    const newDob = event.target.value;
    setDob(newDob);
    if (!isDobValid(newDob)) {
      setIsDobValidationVisible(true);
    } else {
      setIsDobValidationVisible(false);
    }
    updateSubmitButtonState(username, newDob, captchaToken);
  };

  useEffect(() => {
    localStorage.setItem("conversationMayContainPii", true);
  }, []);

  console.log("props.serviceNotAvailable", props.serviceNotAvailable);
  const linkStyle = {
    color: 'var(--CalHEERS-Main-Blue, #2D77A3)',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    textDecorationLine: 'underline'
};

console.log("alertRef", alertRef)

useEffect(() => {
  const focusAlert = () => {
    if (alertRef.current) {
      console.log("Focusing and scrolling alert after delay:", alertRef.current);
      alertRef.current.focus();
      alertRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      console.log('Alert ref is still null, retrying...');
      setTimeout(focusAlert, 300); // Retry after a longer delay
    }
  };

  focusAlert();
}, [props.exceededAttemptMessage, props.userNotFound, props.serviceNotAvailable]);


console.log("exceededAttemptMessage",    props)





return (
  <>
    <WizardLayout.Content>
      <PageLayout>
        <PageLayout.Header style={{ marginBottom: "8px" }}>
          <SubHeader subHeader="Reset your Password" />
        </PageLayout.Header>
        <PageLayout.Content>
          <Body
            bodyText={
              <>
                <div style={{ marginTop: '0px' }}>
                  Enter your information below.
                </div>
              </>
            }
          />
          <Form>
            <Form.FormGroup>
              <Form.Label>Username</Form.Label>
              <Form.Control>
                {/* <Input
                  onChange={onUsernameUpdate}
                  value={username}
                  placeholder="Enter username"
                /> */}
                 <Input
                    style={isUsernameValidationVisible ? { border: '1px solid red' } : {}}
                    onChange={onUsernameUpdate}
                    value={username}
                    placeholder="Enter username"
                  />
                {isUsernameValidationVisible && <InputValidationMessage>Please enter your username.</InputValidationMessage>}
              </Form.Control>
              <a href="#!" style={linkStyle} onClick={onForgotUsername} disabled={isSubmitted}>Forgot your username?</a>
            </Form.FormGroup>
            <Form.FormGroup>
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control>
                <InputMask
                 style={isDobValidationVisible ? { border: '1px solid red' } : {}}
                  mask="99/99"
                  maskChar="_"
                  className="form-control"
                  placeholder="mm/dd"
                  onChange={onDobUpdate}
                  value={dob}
                />
              </Form.Control>
              {isDobValidationVisible && <InputValidationMessage>Please enter a valid date of birth in the following format: mm/dd.</InputValidationMessage>}
            </Form.FormGroup>
          </Form>

          {/* <div className="d-none">
            <ReCAPTCHA
              sitekey={CAPTCHA_SITE_KEY}
              ref={captchaRef}
              size="invisible"
            />
          </div> */}
          <div className="my-3">
            {/* <a href="#!" style={linkStyle} onClick={() => setIsPrivacyStatementExpanded(!isPrivacyStatementExpanded)}>Privacy Statement</a>
            <div style={{ color: "#212529" }} className={isPrivacyStatementExpanded ? "d-block" : "d-none"}>
              The information requested on this form is required by Covered California to assist with your request,
              and the information you provide will be used by Covered California solely for that purpose. Legal
              references authorizing the collection of the personally-identifiable information through this form
              include Sections 1798.22, 1798.25, 1798.27 and 1798.35 of the California Civil Code and Section 155.260(a)
              of the Code of Federal Regulations. California Health Benefits Exchange dba Covered California,
              1601 Exposition Blvd, Sacramento, CA 95815 (800) 889-3871. */}

          <a href="#!" style={linkStyle} onClick={() => setShowPrivacyModal(true)}>Privacy Statement</a>
            {/* </div> */}
          </div>
          {props.exceededAttemptMessage && (
             <div
             ref={(ref) => {
               if (ref) {
                 alertRef.current = ref;
                 console.log('Alert ref set:', ref);
                 
                 // Focus and scroll into view once the ref is correctly set
                 ref.focus();
                 ref.scrollIntoView({ behavior: "smooth", block: "center" });
               }
             }}
             tabIndex={-1}
           >
             <Alert
               iconType="red"
               frameIcon="red"
               message={props.exceededAttemptMessage || props.userNotFound || props.serviceNotAvailable}
             />
           </div>
           
              
            )}
            {props.userNotFound && (
              <div ref={alertRef} tabIndex={-1}>
                <Alert
                  iconType='red'
                  frameIcon='red'
                  message={props.userNotFound}
                />
              </div>
            )}
            {props.serviceNotAvailable && (
              <div ref={alertRef} tabIndex={-1}>
                <Alert
                  iconType='red'
                  frameIcon='red'
                  message={props.serviceNotAvailable}
                />
              </div>
            )}
        </PageLayout.Content>
      </PageLayout>
    </WizardLayout.Content>
    <WizardLayout.Navigation>
      <NavigationButton onClick={onSubmit} type={"primary"}  disabled={isSubmitDisabled || isSubmitted}
      >
        Submit
      </NavigationButton>
       {/* <NavigationButton onClick={() => closePasswordResetWizard()}  type={"secondary"} >
                Exit
        </NavigationButton> */}
    </WizardLayout.Navigation>
    {showPrivacyModal && (
                <PrivacyStatementModal type="fixed" insideWizard={true} handleClose={() => setShowPrivacyModal(false)} />
            )}
  </>
);
}

export default ResetPasswordPage;