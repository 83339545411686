import React, { useState, useEffect, useRef } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useM2C from "../../Hooks/useM2C";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import { Pages } from "./M2CWizard";
import Form from "../../UiKit/Form/Form";
import Input from "../../UiKit/Wizard/Input/Input";
import InputValidationMessage from "../../UiKit/InputValidationMessage/InputValidationMessage";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translations";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import styles from "../../UiKit/Wizard/WizardLayout/WizardLayout.module.css";


function SourceOfHealthCoveragePage(props) {
  const { previous, sb260Event } = useM2C();
  const {
    handleSetCurrentPage,
    selectedOption,
    handleSelectedOptionChange,
    initialOtherText,
    handleOtherTextChange,
    memberDecisions,
    onConfirm,
    eventName,
    onNext,
    onBack,
  } = props;

  const { currentLanguage } = useLanguage();
  const [errors, setErrors] = useState([]);
  const [localSelectedOption, setLocalSelectedOption] = useState(selectedOption);
  const [otherText, setOtherText] = useState(initialOtherText || "");
  const [showError, setShowError] = useState(false);
  const [showOtherTextError, setShowOtherTextError] = useState(false);
  const SourceOfHealthtranslations = translations[currentLanguage]["SourceOfHealthCoveragePage"];
  const NavButton = translations[currentLanguage]["NavButton"];

  const errorRef = useRef(null);
  const otherTextErrorRef = useRef(null);
  console.log("*****",sb260Event.data.getMemberDetails)
  const enrollments = Object.entries(sb260Event.data.getMemberDetails || {}).reduce(
    (acc, [enrollmentId, group]) => {
      const membersWithEnrollmentId = group.members.map((member) => ({
        ...member,
        enrollmentId,
      }));
      return [...acc, ...membersWithEnrollmentId];
    },
    []
  );


  const updatedEnrollments = enrollments.map((member, index) => ({
    ...member,
    type: memberDecisions[`${member.enrollmentId}-${member.apsId}-${index}`],
  }));

  const memberDecisionsArray = Object.values(memberDecisions);
  const keepCount = memberDecisionsArray.filter(
    (decision) => decision === "keep"
  ).length;
  const cancelCount = memberDecisionsArray.filter(
    (decision) => decision === "cancel"
  ).length;

  useEffect(() => {
    const isFormValid = () => {
      if (localSelectedOption === "") {
        return false;
      }
      if (localSelectedOption === "05" && otherText.trim() === "") {
        return false;
      }
      return true;
    };
    setShowError(!isFormValid());
  }, [localSelectedOption, otherText]);

  const handleOptOut = () => {
    if (eventName === "SHOW_SERVICE_TIMEOUT_ERROR") {
      setShowError(true);
      setErrors([SourceOfHealthtranslations.Error]);
      return;
    }

    if (localSelectedOption === "") {
      setShowError(true);
      setErrors([SourceOfHealthtranslations.Error]);
    } else {
      setErrors([]);
    }
  };

  const handleNext = () => {
    let formIsValid = true;
    let newErrors = [];

    if (localSelectedOption === "") {
      formIsValid = false;
      newErrors.push(SourceOfHealthtranslations.Error);
    } else if (localSelectedOption === "05" && otherText.trim() === "") {
      formIsValid = false;
      newErrors.push(SourceOfHealthtranslations.OtherValidation);
      setShowOtherTextError(true);
    } else {
      setShowOtherTextError(false);
    }

    setShowError(!formIsValid);
    setErrors(newErrors);

    console.log("formIsValid:", formIsValid); // Debugging log
    console.log("showOtherTextError:", showOtherTextError); // Debugging log

    if (!formIsValid) {
      return;
    }

    if (onConfirm && typeof onConfirm === "function") {
      onConfirm(updatedEnrollments);
    }
    if (eventName === "SHOW_SERVICE_TIMEOUT_ERROR") {
      handleOptOut();
    } else {
      handleSetCurrentPage(Pages.REVIEWANDCONFIRM);
      if (keepCount === 0 && cancelCount > 0) {
        const intentName =
          currentLanguage === "en"
            ? "M2CsourceOfCoverage"
            : "M2CSourceOfCoverageSpanish";
        const text =
          currentLanguage === "en"
            ? "M2C Source of Coverage"
            : "M2C Source Of Coverage Spanish";
        props.sendLoggingEventToBackend({
          intentName: intentName,
          text: text,
        });
      }
    }
  };

  const handleOptionChange = (newOption) => {
    setLocalSelectedOption(newOption);
    handleSelectedOptionChange(newOption);
    setErrors([]);
    setShowError(false);

    if (newOption !== "05") {
      setOtherText("");
      handleOtherTextChange("");
      setShowOtherTextError(false);
    }
  };

  useEffect(() => {
    if (showError && errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      errorRef.current.focus();
    }
  }, [showError]);

  useEffect(() => {
    if (showOtherTextError && otherTextErrorRef.current) {
      otherTextErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      otherTextErrorRef.current.focus();
    }
  }, [showOtherTextError]);


  const options =
    currentLanguage === "en"
      ? [
          {
            label:
              "A plan through a current employer or union of yours or a family member’s",
            value: "01",
          },
          {
            label: "Returning to Medi-Cal or Medicaid",
            value: "02",
          },
          {
            label: "Medicare",
            value: "03",
          },
          {
            label: "I will not have any health coverage",
            value: "04",
          },
          {
            label: "Prefer not to answer",
            value: "06",
          },
          { label: "Other", value: "05" },
        ]
      : [
          {
            label:
              "Un plan a través de un empleador actual o unión tuya o de un miembro de tu familia",
            value: "01",
          },
          {
            label: "Regresando a Medi-Cal o Medicaid",
            value: "02",
          },
          {
            label: "Medicare",
            value: "03",
          },
          {
            label: "No tendré cobertura de salud",
            value: "04",
          },
          {
            label: "Prefiero no responder",
            value: "06",
          },
          { label: "Otro", value: "05" },
        ];

  return (
    <>
      <WizardLayout.Content>
        <PageLayout>
          <PageLayout.Header style={{ marginBottom: "-10px" }}>
            <SubHeader subHeader={SourceOfHealthtranslations.SubHeader} />
          </PageLayout.Header>
          <PageLayout.Content>
            <Form>
              <Form.FormGroup>
                <Form.RadioGroup
                  name="sourceOfHealthCoverage"
                  selectedOption={localSelectedOption}
                  onChange={handleOptionChange}
                  options={options}
                />
                {localSelectedOption === "05" && (
                  <Form.Control>
                    <Form.Label htmlFor="otherText">
                      {SourceOfHealthtranslations.Other}
                    </Form.Label>
                    <Input
                      type="text"
                      placeholder={SourceOfHealthtranslations.Other}
                      value={otherText}
                      onChange={(e) => {
                        setOtherText(e.target.value);
                        handleOtherTextChange(e.target.value);
                        setShowOtherTextError(false); // Remove error state when user types
                      }}
                      isValid={!showOtherTextError}
                    />
                    {showOtherTextError && (
                      <InputValidationMessage ref={otherTextErrorRef}>
                        {SourceOfHealthtranslations.OtherValidation}
                      </InputValidationMessage>
                    )}
                  </Form.Control>
                )}
              </Form.FormGroup>
            </Form>
            {showError && (
              <InputValidationMessage ref={errorRef}>
                {errors[0]}
              </InputValidationMessage>
            )}
          </PageLayout.Content>
        </PageLayout>
      </WizardLayout.Content>
      <div style={{ position: "relative" }}>
        <div
          className={`${styles.overlaybottom}`}
          style={{ height: "49px" }}
        ></div>
      </div>
      <WizardLayout.Navigation>
        <NavigationButton onClick={handleNext} type={"primary"}>
          {NavButton.NextButton}
        </NavigationButton>
        <NavigationButton onClick={onBack} type={"secondary"}>
          {NavButton.BackButton}
        </NavigationButton>
      </WizardLayout.Navigation>
    </>
  );
}

export default SourceOfHealthCoveragePage;