import { useState } from "react";
import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import useEnrollmentStatus from "../../Hooks/useEnrollmentStatus";
import filledStar from './images/Filled.svg';
import emptyStar from './images/Empty.svg';
import translations from "./translation";

function EnrollmentStatusFeedbackPage({sendMessage}) {

    const { next } = useEnrollmentStatus();
    const { currentLanguage } = useLanguage();
    const ProvideFeedbackTranslations = translations[currentLanguage]["EnrollmentStatusProvideFeedback"];
    const buttonTranslations = translations[currentLanguage]["button"];
   

    const handleSubmit = () => {
      console.log("submit")
        next();
        // alert(`Rating: ${rating}, Feedback: ${feedback}`);
      };

    return (
       <PageLayout>
        <PageLayout.Header style={{ marginBottom: "-17px" }}>
            <SubHeader subHeader={ProvideFeedbackTranslations.Title} />
        </PageLayout.Header>
        <PageLayout.Content>    
            <RatingComponent ProvideFeedbackTranslations={ProvideFeedbackTranslations}/>
        </PageLayout.Content>
        <PageLayout.Navigation>
                <NavigationButton onClick={() => handleSubmit()} type="primary">
                    {buttonTranslations.Submit}
                </NavigationButton>
            </PageLayout.Navigation>

       </PageLayout>
             
             
      
            
    )
}
function RatingComponent({ProvideFeedbackTranslations}){
const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');

  const handleRatingClick = (index) => {
    setRating(index);
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  
  const styles = {
    container: {
      margin: '0 auto',
      textAlign: 'center',
    },
    stars: {
      display: 'flex',
      marginBottom: '16px',
      marginTop: '32px',
      
    },
    star: {
      fontSize: '2rem',
      cursor: 'pointer',
      width: '30px',
      height: '30px',
      transition: 'opacity 0.2s',
    },
    textarea: {
      width: '100%',
      marginTop: '10px',
      padding: '10px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      resize: 'none',
      display: 'flex',
      height: '61px',
      padding: '10px 12px',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.stars}>
        {[...Array(5)].map((_, index) => (
          <img
            key={index}
            src={index < rating ? filledStar : emptyStar}
            alt={index < rating ? 'Filled Star' : 'Unfilled Star'}
            style={styles.star}
            onClick={() => handleRatingClick(index + 1)}
          />
        ))}
      </div>
      <textarea
        style={styles.textarea}
        placeholder={ProvideFeedbackTranslations.Placeholder}
        value={feedback}
        onChange={handleFeedbackChange}
      ></textarea>
    </div>
  );
};




export default EnrollmentStatusFeedbackPage;