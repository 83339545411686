import React from 'react';
import classes from './WelcomeScreen.module.css';
import WelcomeMessage from './WelcomeMessage/WelcomeMessage';
import WelcomeScreenSuggestedActions from './WelcomeScreenSuggestedActions/WelcomeScreenSuggestedActions';

function WelcomeScreen () {
    return (
        <div className={`${classes['welcome-screen']}`}>
            <div className={`${classes['welcome-screen__image-container']}`}>
                <WelcomeMessage />
            </div>
            <h2 className={`${classes['welcome-screen__heading']}`}>
                I’m <strong>CiCi</strong>, your virtual assistant.
            </h2>
            <p className={`${classes['welcome-screen__sub-heading']}`}>
                Here are some common chat topics. You can also ask me about something else below.
            </p>
            <div className={`${classes['welcome-screen__actions-container']}`}>
                <WelcomeScreenSuggestedActions />
            </div>
        </div>
    )
}

export default WelcomeScreen;