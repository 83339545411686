import useChatbot from "../useChatbot";
import { useWebChatContext } from "../../Contexts/WebChatContext";
import useLanguageHook from '../useLanguageHook';

import postActivityHandler from "../../Services/PostActivityHandler";
import { getGaId } from "../../Services/PostActivityHandler";

function useWebChat(userSessionService, chatbotTokenService, dataLayerService, createDirectLine, createStore) {
  const { directline, setDirectline, store, setStore, error, setError } = useWebChatContext();
  const { openAffordabilityTool, openEnrollmentStatusWizard, openM2CWizard, setShowWelcomScreen, openPasswordResetWizard,
    passwordResetEvent } = useChatbot();
  const { currentLanguage } = useLanguageHook();

  const _createDirectlineObject = (token) => {
    const directlineObject = createDirectLine(token);
    return directlineObject;
  }

  const _getToken = async () => {
    if (userSessionService.isNewUser()) {
      return await chatbotTokenService.getToken()
    }
    try {
      return await chatbotTokenService.refreshToken(userSessionService.getConversationId());
    } catch (error) {
      return await chatbotTokenService.getToken();
    }
  }

  const _handleAction = (actionFromReduxStore, next) => {

    if (_isIncomingActivityAction(actionFromReduxStore)) {
      setShowWelcomScreen(false);
      return _handleIncomingActivityAction(actionFromReduxStore, next);
    }

    if (_isPostActivityAction(actionFromReduxStore)) {
      setShowWelcomScreen(false);
      return _handlePostActivityAction(actionFromReduxStore, next);
    }

    return next(actionFromReduxStore);
  }

  const _handleAffordabilityToolTriggerAction = (action) => {
    openAffordabilityTool();
  }

  const _handleEnrollmentStatusWizardTriggerAction = (action) => {
    openEnrollmentStatusWizard(action.payload.activity.value);
  }

  const _handlePasswordResetWizardTriggerAction = (action) => {
    // setActivityData(action.payload.activity)
    // console.log("***")
    // console.log("*** _handlePasswordResetWizardTriggerAction")
    // console.log("***")
    console.log("action.payload.activty: ", action.payload.activity);
    // console.log("\n\n\n")
    if (!action.payload.activity.attachments) {
      if (_isExceededAttemptMessage(action)) {
        //exceeded
        console.log("***");
        console.log("*** exceeded attempts block");
        console.log("*** \n\n");
        // openPasswordResetWizard({ name: "contactusExceeded", data: {} })
        //openPasswordResetWizard({ name: "openAuthenticationPage", data: {} })
        openPasswordResetWizard({
          name: "openAuthenticationPage",
          data: {
            exceededAttemptMessage:
              "Passwords can only be changed once per day. If you need to change your password, wait until tomorrow or call (800) 300-1506 for more help.",
          },
        });
        return;
      }
      if (_isUserNotFound(action)) {
        //notfound
        console.log("***");
        console.log("*** user not  found block");
        console.log("*** \n\n");
        openPasswordResetWizard({
          name: "openAuthenticationPage",
          data: { userNotFound: action.payload.activity.text },
          flag: "userNotFound",
        });
        //openPasswordResetWizard({ name: "openServiceCenterPage", data: {} })
        return;
      }
      if (_isPasswordResetServerIssue(action)) {
        // notavailable
        console.log("***");
        console.log("*** server issue block");
        console.log("*** \n\n");
        openPasswordResetWizard({
          name: "openServiceCenterHoursPage",
          data: {
            serviceNotAvailable:
              "This service is temporarily unavailable. Please try again later",
          },
        });
        return;
      }
      if (_isOTPInputExceededMEssage(action)) {
        //0
        console.log("***");
        console.log("*** otp input exceeded block");
        console.log("*** \n\n");
        openPasswordResetWizard({
          name: "PasscodePage",
          data: {
            exceededOTPMessage:
              "Your account is not available at this time. Please contact Covered California for help.",
          },
        });
        return;
      }
      if (_isOTPRetry(action)) {
        //1
        console.log("***");
        console.log("*** otp retry block");
        console.log("*** \n\n");
        openPasswordResetWizard({
          name: "PasscodePage",
          data: { OTPRetry: "Please check your OTP and try again." },
        });
        return;
      }
      // if(_isOTPSessionExpired(action)){//2
      //     console.log("***")
      //     console.log("*** otp session expired block")
      //     console.log("*** \n\n")
      //     openPasswordResetWizard({ name: "openServiceCenterPage", data: { OTPSessionExpired: "You session has expired. Are you ready to continue resetting your password?" } });
      //     return;
      // }
      if (_isOTPServiceUnavailable(action)) {
        //3
        console.log("***");
        console.log("*** otp service unavailable block");
        console.log("*** \n\n");
        openPasswordResetWizard({
          name: "PasscodePage",
          data: {
            OTPServiceUnavailable:
              "This service is temporarily unavailable. Please try again later",
          },
          // flag: "otpServiceUnavailable"
        });
        return;
      }
      if (_isPasswordReset(action)) {
        console.log("***");
        console.log("*** password reset block");
        console.log("*** \n\n");
        openPasswordResetWizard({ name: "openServiceCenterPage", data: {} });
        return;
      }
      if (_isPasswordInvalid(action)) {
        console.log("***");
        console.log("*** password invalid block");
        console.log("*** \n\n");
        openPasswordResetWizard({
          name: "openPasswordPage",
          data: {
            passwordInvalid:
              "We could not update your password. You may have entered a password that you've already used in the past, a password that contains a dictionary word, or a password that contains a keyboard pattern. Please enter a new password.",
          },
        });
        return;
      }

      if (_isSuccessfullyChangedPasswordMessage(action)) {
        console.log("***");
        console.log("*** successfull password change");
        console.log("*** \n\n");
        openPasswordResetWizard({ name: "openSuccessPage", data: {} });
      }
      return;
    }

    // console.log("action.payload.activity", action.payload.activity)
    // console.log("setActivityData(action.payload.activity);", setActivityData(action.payload.activity))
    // console.log("***")
    // console.log("*** card received")
    // console.log("***")
    // console.log('contentType: ', action.payload.activity.attachments[0].contentType);
    // console.log("\n\n\n")
    const attachment = action.payload.activity.attachments[0];
    console.log(
      "***attachment.contentType***" + attachment.contentType + " card received"
    );
    if (attachment.contentType !== "application/vnd.microsoft.card.adaptive") {
      console.log("***" + attachment.contentType + " card received");
      switch (attachment.contentType) {
        // switch (action.payload.activity.attachments[0].contentType) {
        case "application/otpTypeCard":
          console.log("***");
          console.log("*** otpType card received");
          console.log("***");
          console.log(
            "contentType: ",
            action.payload.activity.attachments[0].contentType
          );
          console.log("\n\n\n");
          openPasswordResetWizard({
            name: "openOtpSelectionPage",
            data: action.payload.activity,
          });
          break;
        case "application/usernameDobCard":
          console.log("***");
          console.log("*** usernameDobCard received");
          console.log("***");
          console.log("contentType: ", action.payload.activity);
          console.log(passwordResetEvent);
          console.log("\n\n\n");
          openPasswordResetWizard({
            name: "openWelcomePage",
            data: action.payload.activity,
          });
          break;
        case "application/contactUsCard":
          // openPasswordResetWizard({name: "openContactUsPage", data: ""})
          break;
        case "application/passcodeCard":
          console.log("***");
          console.log("*** passcodeCard received");
          console.log("***");
          console.log(
            "contentType: ",
            action.payload.activity.attachments[0].contentType
          );
          console.log("\n\n\n");
          openPasswordResetWizard({
            name: "openPasscodePage",
            data: action.payload.activity,
          });
          break;
        case "application/passwordCard":
          console.log("***");
          console.log("*** password card received");
          console.log("***");
          console.log(
            "contentType: ",
            action.payload.activity.attachments[0].contentType
          );
          console.log("\n\n\n");
          openPasswordResetWizard({ name: "openPasswordPage", data: "" });
          break;
        default:
          openPasswordResetWizard({
            name: "startWizard",
            data: action.payload.activity,
          });
      }
    } else {
      const adaptiveCardContent = attachment.content;
      const textBlock = adaptiveCardContent.body.find(
        (bodyElement) => bodyElement.type === "TextBlock"
      );
      console.log("textBlock", textBlock);
      if (textBlock) {
        const bodyText = textBlock.text;
        console.log("bodyText", bodyText);
        switch (bodyText) {
          case "You session has expired. Are you ready to continue resetting your password?":
            console.log("*** otpType card received");
            openPasswordResetWizard({
              name: "openServiceCenterPage",
              data: action.payload.activity,
            });
            break;

          case "Are you sure you want to cancel resetting your password?":
              console.log("*** Im in reset password");
              openPasswordResetWizard({ name: "openServiceCenterPage", data: action.payload.activity });
              break;
          default:
            openPasswordResetWizard({
              name: "startWizard",
              data: action.payload.activity,
            });
        }
      }
    }
  };

  const _handleIncomingActivityAction = (action, next) => {
    if (!_isNewIncomingActivityAction(action)) return next(action);

    userSessionService.setLastActivity(action);

    if (_shouldBeLoggedIntoDataLayer(action)) {
      dataLayerService.pushLogEventToDataLayer(action);
    }

    if (_isEnrollmentStatusWizardTriggerAction(action)) {
      console.log("in handle action enrollment status")
      _handleEnrollmentStatusWizardTriggerAction(action);
    }

    if (_isAffordabilityToolTriggerAction(action)) {
      _handleAffordabilityToolTriggerAction(action);
    }

    if (_isM2CWizardTriggerAction(action)) {
      _handleM2CWizardTriggerAction(action);
    }
    if (_isPasswordResetWizardTriggerAction(action)) {
      console.log("***");
      console.log("*** Password Reset Action");
      console.log("***");
      console.log("action: ", action);
      console.log("\n\n\n");
      _handlePasswordResetWizardTriggerAction(action);
      /**
       * returning from this point means the middlewares will no longer process the action
       */
      return;
    }

    return next(action);
  }

  const _handleM2CWizardTriggerAction = (action) => {
    openM2CWizard(action.payload.activity.value);

  }

  const _handlePostActivityAction = (action, next) => {
    userSessionService.setLastActivity(action);
    return next(postActivityHandler(action));
  };

  const _isAffordabilityToolTriggerAction = (action) => {
    if (!action) return false;
    if (!action.payload) return false;
    if (!action.payload.activity) return false;
    if (!action.payload.activity.type) return false;
    if (action.payload.activity.type !== "event") return false;
    if (action.payload.activity.name !== "affordabilitytool") return false;
    return true;
  }

  const _isEnrollmentStatusWizardTriggerAction = (action) => {
    if (!action) return false;
    if (!action.payload) return false;
    if (!action.payload.activity) return false;
    if (!action.payload.activity.type) return false;
    if (action.payload.activity.type !== "event") return false;
    if (action.payload.activity.name !== "ENROLLMENT_STATUS") return false;
    return true;
  }

  const _isPasswordResetWizardTriggerAction = (action) => {
    if (!action) return false;
    if (!action.payload) return false;
    if (!action.payload.activity) return false;
    if (!action.payload.activity.type) return false;
    if (action.payload.activity.type !== "message") return false;
    if (_isExceededAttemptMessage(action)) return true;
    if (_isUserNotFound(action)) return true;
    if (_isSuccessfullyChangedPasswordMessage(action)) return true;
    if (_isPasswordResetServerIssue(action)) return true;
    if (_isOTPInputExceededMEssage(action)) return true;
    if (_isOTPRetry(action)) return true;
    if (_isOTPSessionExpired(action)) return true;
    if (_isOTPServiceUnavailable(action)) return true;
    if (_isPasswordReset(action)) return true;
    if (_isPasswordInvalid(action)) return true;
    if (!action.payload.activity.attachments) return false;
    if (action.payload.activity.attachments.length === 0) return false;
    if (
      action.payload.activity.attachments[0].contentType !==
        "application/usernameDobCard" &&
      action.payload.activity.attachments[0].contentType !==
        "application/contactUsCard" &&
      action.payload.activity.attachments[0].contentType !==
        "application/otpTypeCard" &&
      action.payload.activity.attachments[0].contentType !==
        "application/passcodeCard" &&
      action.payload.activity.attachments[0].contentType !==
        "application/passwordCard"
    )
      return false;

    return true;
  };

  const _isExceededAttemptMessage = (action) => {
    if (
      action.payload.activity.text ===
      "Passwords can only be changed once per day. If you need to change your password, wait until tomorrow or call (800) 300-1506 for more help."
    ) {
      return true;
    }
  };

  const _isUserNotFound = (action) => {
    if (
      action.payload.activity.text ===
      "We couldn't find an account with this information on file. Please try again."
    ) {
      return true;
    }
  };

  const _isSuccessfullyChangedPasswordMessage = (action) => {
    if (
      action.payload.activity.text ===
      "You have successfully changed you password. Please try to [login](https://apply.coveredca.com/static/lw-web/login) or continue to ask me questions"
    ) {
      return true;
    }
    return false;
  };

  const _isPasswordResetServerIssue = (action) => {
    if (
      action.payload.activity.text ===
      "This service is temporarily unavailable. Please try again later"
    ) {
      return true;
    }
    return false;
  };

  const _isOTPInputExceededMEssage = (action) => {
    if (
      action.payload.activity.text ===
      "Your account is not available at this time. Please contact Covered California for help."
    ) {
      return true;
    }
    return false;
  };

  const _isPasswordInvalid = (action) => {
    if (
      action.payload.activity.text ===
      "We could not update your password. You may have entered a password that you've already used in the past, a password that contains a dictionary word, or a password that contains a keyboard pattern. Please enter a new password."
    ) {
      return true;
    }
    return false;
  };

  // const _isOTPSessionExpired = (action) => {
  //     if (action.payload.activity.text === "You session has expired. Are you ready to continue resetting your password?") {
  //         return true;
  //     }
  //     return false;
  // }

  const _isOTPSessionExpired = (action) => {
    const activity = action.payload.activity;

    console.log("activity in session", activity);

    if (activity.attachments && activity.attachments.length > 0) {
      return activity.attachments.some((attachment) => {
        console.log("attachment.content", attachment.content);
        return (
          attachment.content &&
          attachment.content.type === "AdaptiveCard" &&
          attachment.content.body.some((bodyElement) => {
            console.log("bodyElement", bodyElement);
            return (
              bodyElement.type === "TextBlock" &&
              bodyElement.text ===
                "You session has expired. Are you ready to continue resetting your password?"
            );
          })
        );
      });
    }

    return false;
  };

  const _isPasswordReset = (action) => {
    const activity = action.payload.activity;

    console.log("activity in session", activity);

    if (activity.attachments && activity.attachments.length > 0) {
      return activity.attachments.some((attachment) => {
        console.log("attachment.content", attachment.content);
        return (
          attachment.content &&
          attachment.content.type === "AdaptiveCard" &&
          attachment.content.body.some((bodyElement) => {
            console.log("bodyElement", bodyElement);
            return (
              bodyElement.type === "TextBlock" &&
              bodyElement.text ===
                "Are you sure you want to cancel resetting your password?\n\n\n\n "
            );
          })
        );
      });
    }

    return false;
  };

  const _isOTPRetry = (action) => {
    if (
      action.payload.activity.text === "Please check your OTP and try again."
    ) {
      return true;
    }
    return false;
  };

  const _isOTPServiceUnavailable = (action) => {
    if (
      action.payload.activity.text ===
      "This service is temporarily unavailable. Please try again later"
    ) {
      return true;
    }
    return false;
  };


  const _isIncomingActivityAction = (action) => {
    if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') return true;
    return false;
  }

  const _isM2CWizardTriggerAction = (action) => {
    if (!action) return false;
    if (!action.payload) return false;
    if (!action.payload.activity) return false;
    if (!action.payload.activity.type) return false;
    if (action.payload.activity.type !== "event") return false;
    if (action.payload.activity.name !== "SB260_TRANSACTION") return false;
    return true;
  }

  const _isNewIncomingActivityAction = (action) => {
    const incomingActivityIdParts = action.payload.activity.id.split("|");

    /**
     * for offline-directline messages
     * - activities coming from offline-directline do not have watermarks, so 
     *   we'll treat them as new activities
     */
    if (incomingActivityIdParts.length < 2) return true;

    const incomingActivityWatermark = incomingActivityIdParts[1];
    if (incomingActivityWatermark <= userSessionService.getWatermark()) return false;
    return true;
  }

  const _isPostActivityAction = (action) => {
    const actionType = action.type;
    if (actionType === 'DIRECT_LINE/POST_ACTIVITY' || actionType === 'POST_ACTIVITY') {
      return true;
    }
    return false;
  }

  const _shouldBeLoggedIntoDataLayer = (action) => {
    console.log("action ES", action);
    if (!action) return false;
    if (!action.payload) return false;
    if (!action.payload.activity) return false;
    if (!action.payload.activity.value) return false;
    if (!action.payload.activity.value.shouldActivitySendDataLayerEvent) return false;
    return true;
  }

  const initializeWebchat = async () => {
    try {
      var token = await _getToken();
      var directlineObject = _createDirectlineObject(token);
      var webchatStore = _createStore();
      _updateSession(token);
      setStore(webchatStore);
      setDirectline(directlineObject);
    } catch (error) {
      setError({
        message: error.message
      });
      return;
    }
  }

  const resetWebchat = async (newLanguage) => {
    setDirectline(null); // Reset the current DirectLine session
    setStore(null); // Reset the store
    await initializeWebchat(newLanguage); // Reinitialize the chat with the new language
  }

  const _updateSession = (token) => {
    userSessionService.update(token);
  };

  const _createStore = () => {
    return createStore({}, ({ dispatch }) => next => action => {
      return _handleAction(action, next);
    })
  };

  const isInitialized = () => {
    if (directline && store) {
      return true;
    }
    return false;
  }

  const sendActivity = async (activity) => {
    store.dispatch({
      meta: {
        method: "keyboard"
      },
      type: 'DIRECT_LINE/POST_ACTIVITY',
      payload: {
        activity: {
          locale: document.documentElement.lang,
          pageUrl: window.location.pathname,
          gaId: getGaId(),
          ...activity
        }
      }
    });
  };

  const sendActivityUsingDirectlineObject = (directlineObject, activity) => {
    directlineObject
      .postActivity(activity)
      .subscribe(
        (id) => {
          /*console.log(id)*/
        },
        (error) => {
          /*console.log('Error posting activity', error)*/
        }
      );
  }

  const sendLoggingEventToBackend = (payload) => {
    if (payload.intentName === "Providefeedback-MCT" || payload.intentName === "EnrollmentStatusFeedback") {
      store.dispatch({
        meta: {
          method: "keyboard"
        },
        type: 'DIRECT_LINE/POST_ACTIVITY',
        payload: {
          activity: {
            type: "event",
            name: "logEvent",
            value: {
              intent: {
                name: payload.intentName || "undefined",
                text: payload.text || "undefined"
              },
              data: {
                rating: payload.rating,
                comment: payload.comment
              }
            }
          }
        }
      });
    }
    else {
      store.dispatch({
        meta: {
          method: "keyboard"
        },
        type: 'DIRECT_LINE/POST_ACTIVITY',
        payload: {
          activity: {
            type: "event",
            name: "logEvent",
            value: {
              intent: {
                name: payload.intentName || "undefined",
                text: payload.text || "undefined"
              },
            }
          }
        }
      });
    }
  }

  const sendMessage = (message) => {
    store.dispatch({
      meta: {
        method: "keyboard"
      },
      type: 'DIRECT_LINE/POST_ACTIVITY',
      payload: {
        activity: {
          type: "message",
          text: message,
          locale: document.documentElement.lang,
          pageUrl: window.location.pathname,
          gaId: getGaId()
        }
      }
    });
  }
  const postActivity = (activity) => {
    directline
      .postActivity({
        type: "event",
        value: document.documentElement.lang,
        locale: "en",
        from: { id: "me" },
        name: "updateLanguage",
        pageUrl: window.location.pathname,
      })
      .subscribe(
        (id) => {
          /*console.log(id)*/
        },
        (error) => {
          /*console.log('Error posting activity', error)*/
        }
      );
  };

  const styleOptions = {
    hideUploadButton: true
  }

  return {
    directline,
    store,
    styleOptions,
    locale: currentLanguage,
    error,
    initializeWebchat,
    isInitialized,
    sendActivity,
    sendActivityUsingDirectlineObject,
    sendLoggingEventToBackend,
    sendMessage,
    _getToken,
    postActivity,
    
  };
}

export default useWebChat;