import { useContext } from "react";
import { ChatbotContext } from "../Contexts/ChatbotContext";
import useChatbot from "./useChatbot";
import useM2CContext from "./useM2CContext";
import { M2CContext } from "../Contexts/M2CContext";
import useWebChat from "./useWebChat";
import { useLanguage } from "../UiKit/Wizard/langugeContext";

function useM2C() {
  const {
    isM2CWizardOpen, 
    isM2CMenuOpen,
    setIsM2CMenuOpen,
    currentPage,
    setCurrentPage,
    dob,
    setDob,
    ssnPart1,
    setSsnPart1,
    ssnPart2,
    setSsnPart2,
    ssnPart3,
    setSsnPart3,
    zip,
    setZip,
    M2CWizardEventData,
    closeModal, 
    setCloseModal,
    helpModal,
    setHelpModal,
    offBusinessHoursHelpModal,
    setOffBusinessHoursHelpModal
  // } = useM2CContext();
} = useContext(M2CContext);
  
const { sb260Event, setIsM2CWizardOpen } = useContext(ChatbotContext);
const chatbotHook = useChatbot();
const { sendActivity } = useWebChat();
const { currentLanguage } = useLanguage();

  const next = () => {
    setCurrentPage(currentPage + 1);
  };

  const previous = () => {
    if (currentPage === 0) return;
    setCurrentPage(currentPage - 1);
  };

  const closeM2C = () => {
    setIsM2CWizardOpen(false);
    sendActivity( currentLanguage=== "en" ? {text: "My Medi-Cal is ending", type: "message"} : {text: "Mi Medi-Cal está terminando", type: "message"});
  };

  const openFixedCloseModal = () => {
    setCloseModal({isOpen: true, type: "fixed"});
};
const openRelativeCloseModal = () => {
    setCloseModal({isOpen: true, type: "relative"});
};
const closeCloseModal = () => {
    setCloseModal({isOpen: false, type: null});
}

const openFixedHelpModal = () => {
  setHelpModal({isOpen: true, type: "fixed"});
};
const openRelativeHelpModal = () => {
  setHelpModal({isOpen: true, type: "relative"});
};

const closePhoneSupportModal = () => {
  setHelpModal({isOpen: false, type: null});
}

const openFixedOffHoursHelpModal = () => {
  setOffBusinessHoursHelpModal({isOpen: true, type: "fixed"});
};
const openRelativeOffHoursHelpModal = () => {
  setOffBusinessHoursHelpModal({isOpen: true, type: "relative"});
};

const closePhoneSupportOffHoursModal = () => {
  setOffBusinessHoursHelpModal({isOpen: false, type: null});
}

const toggleM2CMenu = () => {
  setIsM2CMenuOpen(!isM2CMenuOpen);
} 

return {
  closeM2C,
  // isM2CWizardOpen,
  currentPage,
  setCurrentPage,
  next,
  previous,
  dob,
  setDob,
  ssnPart1,
  setSsnPart1,
  ssnPart2,
  setSsnPart2,
  ssnPart3,
  setSsnPart3,
  zip,
  setZip,
  sb260Event,
  M2CWizardEventData,
  closeModal,
  closeCloseModal,
  openFixedCloseModal,
  openRelativeCloseModal,
  helpModal,
  openFixedHelpModal,
  openRelativeHelpModal,
  closePhoneSupportModal,
  openFixedOffHoursHelpModal,
  openRelativeOffHoursHelpModal,
  closePhoneSupportOffHoursModal,
  offBusinessHoursHelpModal,
  toggleM2CMenu,
  isM2CMenuOpen,
   ...chatbotHook
};
}

export default useM2C;
