import React, { useContext } from 'react';
import HeaderLayout from './HeaderLayout';
import HeaderCloseButton from './HeaderCloseButton/HeaderCloseButton';
import HeaderMenuToggle from './HeaderMenuToggle/HeaderMenuToggle';
import HeaderTitle from './HeaderTitle/HeaderTitle';
import { ChatbotContext } from '../../../Contexts/ChatbotContext';

function Header() {
    const { setIsMinimized, isMenuOpen, setIsMenuOpen } = useContext(ChatbotContext)
    return (
        <HeaderLayout>
            <HeaderLayout.MenuToggle>
                <HeaderMenuToggle onClick={() => setIsMenuOpen(!isMenuOpen)} />
            </HeaderLayout.MenuToggle>
            <HeaderLayout.Title>
                <HeaderTitle />
            </HeaderLayout.Title>
            <HeaderLayout.CloseButton>
                <HeaderCloseButton
                    onClick={() => setIsMinimized(true)}
                />
            </HeaderLayout.CloseButton>
        </HeaderLayout>
    );
}

export default Header;