import React, { useState } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useAffordabilityTool from "../../Hooks/useAffordabilityTool";
import ChatbotSelect from "../../UiKit/ChatbotSelect/ChatbotSelect";
import Form from "../../UiKit/Form/Form";
import { translations } from "./translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";

function CoverageYearPage() {
  const { next, previous, updateCoverageYear, coverageYear } =
    useAffordabilityTool();
  const { currentLanguage } = useLanguage();
  const coverageYearTranslations = translations[currentLanguage]["CoverageYearPage"];
  const NavButton = translations[currentLanguage]["NavButton"];

  const coverageYearOptions = [
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
  ];

  const [shouldShowValidationError, setShouldShowValidationError] =
    useState(false);

  const isValid = (coverageYear) => {
    if (coverageYear === "") return false;
    return true;
  };

  const onNextClick = () => {
    if (!isValid(coverageYear)) {
      setShouldShowValidationError(true);
      return;
    }
    setShouldShowValidationError(false);
    next();
  };

  const onSelection = (selectedOption) => {
    if (!isValid(selectedOption.value)) setShouldShowValidationError(true);
    setShouldShowValidationError(false);
    updateCoverageYear(selectedOption.value);
  };

  return (
    <PageLayout>
      <PageLayout.Header style={{ marginBottom: "-17px"}}>
        <SubHeader style={{marginBottom: ""}} subHeader={coverageYearTranslations.subHeader} />
      </PageLayout.Header>
      <PageLayout.Content>
        <Form>
          <Form.FormGroup>
            <Form.Label>{coverageYearTranslations.bodyText.first}</Form.Label>
            <Form.Control>
              <ChatbotSelect
                options={coverageYearOptions}
                onChange={(selectedOption) => onSelection(selectedOption)}
                value={coverageYear}
                placeholder={coverageYearTranslations.Placeholder}
                isRequired={true}
                shouldShowValidation={shouldShowValidationError}
                inputValidationMessage={coverageYearTranslations.validationMessage}
              />
            </Form.Control>
          </Form.FormGroup>
        </Form>
      </PageLayout.Content>
      <PageLayout.Navigation>
        <NavigationButton onClick={onNextClick} type={`primary`}>
        {NavButton.nextButton}
        </NavigationButton>
        <NavigationButton onClick={() => previous()} type={"secondary"}>
        {NavButton.backButton}
        </NavigationButton>
      </PageLayout.Navigation>
    </PageLayout>
  );
}

export default CoverageYearPage;
