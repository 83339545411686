import React from 'react';
import classes from './Pill.module.css';
import PillContextProvider, { usePillContext } from './PillContext';

const Pill = ({ children, variant, ...buttonProps }) => {
    return (
        <PillContextProvider>
            <PillHelper variant={variant} {...buttonProps}>
                {children}
            </PillHelper>
        </PillContextProvider>
    );
};

const PillHelper = ({ children, variant, ...buttonProps }) => {
    const { isMouseDown, handleMouseDown, handleMouseUp } = usePillContext();

    const getPillClassName = () => {
        let className = classes.pill;
        if (variant) {
            className += ` ${classes[`pill--${variant}`]}`;
        }
        if (isMouseDown) {
            className += ` ${classes['pill--active']}`;
        }
        return className;
    };

    return (
        <button
            className={getPillClassName()}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            {...buttonProps}
        >
            {children}
        </button>
    );
};

const PillIcon = ({ initialIcon, activeIcon }) => {
    const { isMouseDown } = usePillContext();
    return (
        <div className={classes['pill__icon']}>
            <img src={isMouseDown ? activeIcon : initialIcon} alt="Pill Icon" />
        </div>
    );
};

const PillIconInitial = ({ children }) => {
    const { isMouseDown } = usePillContext();
    if (isMouseDown) return null;
    return <div className={classes['pill__icon--initial']}>{children}</div>;
};

const PillIconActive = ({ children }) => {
    const { isMouseDown } = usePillContext();
    if (!isMouseDown) return null;
    return <div className={classes['pill__icon--active']}>{children}</div>;
};

const PillText = ({ children }) => {
    return <div className={classes['pill__text']}>{children}</div>;
};

Pill.Icon = PillIcon;
Pill.IconInitial = PillIconInitial;
Pill.IconActive = PillIconActive;
Pill.Text = PillText;

export default Pill;
