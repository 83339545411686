import React from 'react';
import styles from './Edit.module.css';
import EditIcon from './icons/edit.svg';

function Edit({children, onClick}) {
    return (
        <button className={styles.cici_edit} onClick={onClick}>
            <img
                src={EditIcon}
                alt="Edit Household Income"
                className={styles.cici_editImage}
            />
            <span className={styles.cici_editText}>{children}</span>
        </button>
    );
}

export default Edit;