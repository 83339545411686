const AFFORDABILITY_THRESHOLD = 9.12;

function getContributionPercentageOfIncome(householdAnnualIncome, contributionAmount, contributionFrequency) {
    return parseFloat(((contributionAmount * contributionFrequency / householdAnnualIncome) * 100).toFixed(2));
};

function isOfferAffordable(contributionPercentageOfIncome) {
    return contributionPercentageOfIncome <= AFFORDABILITY_THRESHOLD;
};

function isAnyOfferAffordable(offers) {
    const affordableOffers = offers.filter(offer => isOfferAffordable(offer));
    if (affordableOffers.length > 0) {
        return true;
    }
    return false;
};

export default {
    getContributionPercentageOfIncome,
    isOfferAffordable,
    isAnyOfferAffordable
}