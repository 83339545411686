import React from 'react';
import classes from './HeaderButton.module.css';

function HeaderButton({ children, onClick }) {
    return (
        <button onClick={onClick} className={`${classes['wizard-header-button']}`}>
            {children}
        </button>
    )
}

export default HeaderButton;