import React from 'react';
import classes from './NavigationButton.module.css'

function NavigationButton({ type, children, onClick, style }) {
    return (
        <button type='button' onClick={onClick} className={`${classes['navigation-button']} ${classes[`navigation-button--${type}`]}`} style={style}> 
            {children}
        </button>
    )
}

export function NavigationButtonSpacer(){
    return (
        <div className={`${classes['navigation-button']}`}> 
        </div>
    )
}

export function NavigationButtonGroup({children, style}){
    return(
        <div className={`${classes['navigation-button-group']} ${classes['navigation-button-group--stacked']}`} style={style}>
            {children}
        </div>
    )
}

export default NavigationButton;