import React, {useState, useEffect, useRef} from "react";
import  useM2C from "../../Hooks/useM2C";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import Form from "../../UiKit/Form/Form";
import PersonSelection from "../../UiKit/Wizard/PersonSelection/PersonSelection";
import PlanDetails from "../../UiKit/Wizard/PlanDetails/PlanDetails";
import moment from "moment";
import useGroupCalculations from "../../Hooks/useGroupCalculations";
import InputValidationMessage from "../../UiKit/InputValidationMessage/InputValidationMessage";
import AdjustedPlanDetails from "../../UiKit/Wizard/AdjustPlanDetails/AdjustPlanDetails";
import PersonStatus from "../../UiKit/Wizard/PersonStatus/PersonStatus";
import { Pages } from "./M2CWizard";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translations";

function groupByEnrollmentId(enrollments) {
    return enrollments.reduce((acc, enrollment) => {
      (acc[enrollment.enrollmentId] = acc[enrollment.enrollmentId] || []).push(
        enrollment
      );
      return acc;
    }, {});
  }

function ReviewAndConfirmPage(props){
    const [isLoading, setIsLoading] = useState(false);
    const [accepted, setAccepted] = useState(false);
    const [showError, setShowError] = useState(false);
    const [confirmClicked, setConfirmClicked] = useState(false);
    const { next, previous, sb260Event, setCurrentPage } = useM2C();
    const { memberDecisions, 
            onNext, 
            onBack,
            onSubmit,
            eventName, 
            eventCode,
            onOptOut, 
            selectedOption,
            onClose,
            enrollmentYear,
            updateEnrollmentYear1,
            sendLoggingEventToBackend  } = props;
    const {currentLanguage} = useLanguage();
    const ReviewAndConfirmPageTranslations = translations[currentLanguage]["ReviewAndConfirmPage"];
    const NavButton = translations[currentLanguage]["NavButton"];
    const { groupedMembers, groupTotalPremiums } = useGroupCalculations(sb260Event.data.getMemberDetails, memberDecisions);

    const isErrorCodeIncluded = (code) => {
        const errorCodes = ['IVRINT118', 'IVRINT119', 'IVRINT120', 'IVRINT123', 'IVRINT126'];
        return errorCodes.includes(code);
        };

    const enrollments = Object.entries(sb260Event.data.getMemberDetails || {}).reduce((acc, [enrollmentId, group]) => {
        const membersWithEnrollmentId = group.members.map(member => ({
            ...member,
            enrollmentId
        }));
        return [...acc, ...membersWithEnrollmentId];
        }, []);
    
    const groupedByEnrollmentId = groupByEnrollmentId(enrollments);
    useEffect(() => {
    }, [memberDecisions]);

    useEffect(() => {
    }, enrollmentYear);

    useEffect(() =>{
        props.sendLoggingEventToBackend({
        intentName:
            currentLanguage === "en"
            ? "M2cConfirmedPlanKeepCancel"
            : "M2cConfirmedKeepCancelSpanish",
        text:
            currentLanguage === "en"
            ? "M2c Confirmed Keep Cancel "
            : "M2c Confirmed Keep Cancel Spanish",
        });
    },[sendLoggingEventToBackend])

    const keepCount = Object.values(memberDecisions).filter((decision) => decision === "keep").length;
    const cancelCount = Object.values(memberDecisions).filter((decision) => decision === "cancel").length;

    console.log("keepCount in Review", keepCount);
    console.log("cancelCount in Review", cancelCount);
    
        const handleOptOut = async () => {
        if (eventName === "SHOW_SERVICE_TIMEOUT_ERROR") {
            setShowError(true);
            setIsLoading(false);
            return;
        }
        if (selectedOption === "") {
            setShowError(true);
            setIsLoading(false);
        } else {
            await onOptOut(selectedOption);
            setIsLoading(false);
        }
    };

    const handleOptInOptOut = async () => {
        setIsLoading(true);
        if (keepCount >= 1 && cancelCount >= 1) {
            await handleOptOut();
        } else if (keepCount >= 1) {
            await onSubmit();
        } else if (cancelCount >= 1) {
            await handleOptOut();
        }
        setIsLoading(false);
    };

    const handleNextClick = async () => {
        await handleOptInOptOut();
        if (!isErrorCodeIncluded(eventCode)) {
            onNext();
        } else {
            setConfirmClicked(true);
        }
    };

    const handleEditClick = () => {
        setCurrentPage(Pages.KEEPORCANCEL);
    };
return (
    <PageLayout>
        <PageLayout.Header style={{ marginBottom: "-10px" }}>
            <SubHeader subHeader={ReviewAndConfirmPageTranslations.SubHeader}/>
        </PageLayout.Header>
        <PageLayout.Content>
                      <Form>
                          {Object.keys(groupedMembers).map((groupId, index) => (
                              <Form.FormGroup key={groupId}>
                                  {groupedMembers[groupId].map((member, memberIndex) => (
                                      <div key={`${groupId}-${member.apsId}-${memberIndex}`}>
                                          {memberIndex === 0 && (
                                              <AdjustedPlanDetails
                                                  group={`${ReviewAndConfirmPageTranslations.Group} ${index + 1}`}
                                                  planName={member.plan}
                                                  monthlyCost={groupTotalPremiums[groupId]}
                                                  keepCount={groupedMembers[groupId].filter(m => memberDecisions[`${groupId}-${m.apsId}-${memberIndex}`] === "keep").length}
                                                  totalCount={groupedMembers[groupId].length}
                                              />
                                          )}
                                          <PersonStatus
                                              name={`${member.firstName} ${member.lastName.charAt(0)}.`}
                                              age={moment().diff(moment(member.age, "YYYY-MM-DD"), 'years')}
                                              status={memberDecisions[`${groupId}-${member.apsId}-${memberIndex}`]}
                                              onEdit={handleEditClick}
                                          />
                                      </div>
                                  ))}
                              </Form.FormGroup>
                          ))}
                      </Form>
                  </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton onClick={handleNextClick} type={"primary"}>{NavButton.SubmitButton}</NavigationButton>
                <NavigationButton onClick={onBack} type={"secondary"}>{NavButton.BackButton}</NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

export default ReviewAndConfirmPage;