import React from "react";
import classes from "./Panel.module.css";

function Panel({ children }) {
    return (
        <div className={`${classes['panel']}`}>
            {children}
        </div>
    );
}

Panel.Header = function PanelHeader({ children }) {
    return (
        <div className={`${classes['panel__header']}`}>
            {children}
        </div>
    );
}

Panel.Content = function PanelContent({ children }) {
    return (
        <div className={`${classes['panel__content']}`}>
            {children}
        </div>
    );
}

export default Panel;