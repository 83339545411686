import React from 'react';
import WizardLayout from '../../UiKit/Wizard/WizardLayout/WizardLayout';
import IntroPage from './IntroPage';
import RequirementsPage from './RequirementsPage';

import Header from '../../UiKit/Wizard/Header/Header';
import HeaderButton from '../../UiKit/Wizard/HeaderButton/HeaderButton';


import CoverageYearPage from './CoverageYearPage';
import IncomePage from './IncomePage';
import CoverageCostPage from './CoverageCostPage';
import ReviewAndConfirmPage from './ReviewAndConfirmPage';
import ResultsPage from './ResultsPage';
import ChatbotLayout from '../../UiKit/Chatbot/ChatbotLayout/ChatbotLayout';
import AffordabilityToolInAppHeader from './AffordabilityToolHeader';
import useAffordabilityTool from '../../Hooks/useAffordabilityTool';
import HouseholdMemberPage from './HouseholdMemeberPage';
import WelcomePage from './WelcomePage';
import WelcomePage1 from './WelcomePage1';
import styles from'../../UiKit/Wizard/Header/Header.module.css';
import CloseModal from './CloseModal';
import AffordabilityToolMenu from './AffordabilityToolMenu';
import { useLanguage } from '../../UiKit/Wizard/langugeContext';
import { translations } from './translations';
import AffordabilityToolContextProvider from '../../Contexts/AffordabilityToolContext';
import sendEventToDataLayer from '../../___archive/datalayer-event-logger-for-transactions/sendEventToDataLayer';

function AffordabilityToolWizard({sendLoggingEventToBackend}) {
    return(
        <AffordabilityToolContextProvider>
            <AffordabilityToolWizardHelper sendLoggingEventToBackend ={sendLoggingEventToBackend} />
        </AffordabilityToolContextProvider>
    )    
}

function AffordabilityToolWizardHelper({sendLoggingEventToBackend}){
    const { status, currentPage, closeModal, openRelativeCloseModal } = useAffordabilityTool();
    const { currentLanguage } = useLanguage();
    const introPageTranslations = translations[currentLanguage]['RetunToChatButton']; 

    const getContent = () => {
        if (status === null) {
            return <div>Loading</div>
        }
        if (status === "error") {
            return <div>Error</div>
        }
        return (
            <>
                {currentPage === 0 && <IntroPage />}
                {currentPage === 1 && <WelcomePage sendLoggingEventToBackend = {sendLoggingEventToBackend} />}
                {currentPage === 2 && <WelcomePage1 />}
                {currentPage === 3 && <HouseholdMemberPage />}
                {currentPage === 4 && <CoverageYearPage />}
                {currentPage === 5 && <IncomePage />}
                {currentPage === 6 && <CoverageCostPage />}
                {currentPage === 7 && <ReviewAndConfirmPage sendLoggingEventToBackend = {sendLoggingEventToBackend}/>}
                {currentPage === 8 && <ResultsPage sendLoggingEventToBackend={sendLoggingEventToBackend}/>}
            </>
        )
    }

    const handleClick = () => {
        openRelativeCloseModal(); 
      };

    return (
        <ChatbotLayout>
            <AffordabilityToolMenu />
            {closeModal.isOpen && <CloseModal type={closeModal.type}/>}
            <ChatbotLayout.Header>
                <AffordabilityToolInAppHeader />
            </ChatbotLayout.Header>
            <ChatbotLayout.Wizard>
                <WizardLayout>                    
                    <WizardLayout.Header>
                                <Header>
                                <div className={styles.title} onClick={handleClick}>
                                {introPageTranslations}
                            </div>
                        </Header>
                    </WizardLayout.Header>
                        {getContent()}


                </WizardLayout>
            </ChatbotLayout.Wizard>
        </ChatbotLayout>
    );
}

export default AffordabilityToolWizard;