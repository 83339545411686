import React from 'react';
import classes from './Header.module.css';

function HeaderLayout({ children }) {
    return (
        <div className={`${classes['header']}`}>
            {children}
        </div>
    );
}

HeaderLayout.MenuToggle = function ({ children }) {
    return (
        <div className={`${classes['header__menu-toggle']}`}>
            {children}
        </div>
    );
};

HeaderLayout.Title = function ({ children }) {
    return (
        <div className={`${classes['header__title']}`}>
            {children}
        </div>
    );
}

HeaderLayout.CloseButton = function ({ children }) {
    return (
        <div className={`${classes['header__close']}`}>
            {children}
        </div>
    );
}

export default HeaderLayout;