import React from "react";
import classes from "./FooterLanguageOptions.module.css";
import useLanguageHook from "../../../../Hooks/useLanguageHook";

function FooterLanguageOptions() {
  const { currentLanguage, updateLanguage } = useLanguageHook();
  return (
    <select
      aria-label="Language"
      id="footer-language-options"
      className={`${classes["footer-language-options"]}`}
      value={currentLanguage}
      onChange={(e) => updateLanguage(e.target.value)}
    >
      <option value="en" >English</option>
      <option value="es" >Spanish</option>
    </select>
  );
}

export default FooterLanguageOptions;
