import React, { useState, useEffect, useContext, useRef } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import usePasswordReset from "../../Hooks/usePasswordReset";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Form from "../../UiKit/Form/Form";
import Input from "../../UiKit/Wizard/Input/Input";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import Body from "../../UiKit/Wizard/Body/Body";
import sendEventToDataLayer from "../../___archive/datalayer-event-logger-for-transactions/sendEventToDataLayer";
import ShowPassword from "../../Images/ShowPassword.svg";
import Alert from "../../UiKit/Wizard/Alert/Alert";
import { PasswordResetContext } from "../../Contexts/PasswordResetContext";
import Tooltip from "../../UiKit/Wizard/Tooltip/Tooltip";
import useChatbot from "../../Hooks/useChatbot";

function PasswordPage({
  postActivity,
  sendActivity,
  onNext,
  passwordResetEvent,
  onPrevious,
  props,
}) {
  const { next, previous } = usePasswordReset();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [useExpiredToken, setUseExpiredToken] = useState(false);
  const [isUsedExpiredTokenVisible, setIsUsedExpiredTokenVisible] =
    useState(false);
  const [isPasswordValidationVisible, setIsPasswordValidationVisible] =
    useState(false);
  const [
    isConfirmPasswordValidationVisible,
    setIsConfirmPasswordValidationVisible,
  ] = useState(false);
  const { passwordInvalid, setPasswordInvalid } =
    useContext(PasswordResetContext);
  const { closePasswordResetWizard } = useChatbot();

  // References to the validation messages
  const passwordValidationRef = useRef(null);
  const confirmPasswordValidationRef = useRef(null);

  useEffect(() => {
    if (passwordResetEvent && passwordResetEvent.data) {
      setPasswordInvalid(passwordResetEvent.data.passwordInvalid || "");
    }
  }, [passwordResetEvent, setPasswordInvalid]);

  const hasUpperCase = (password) => {
    return password && /[A-Z]/.test(password);
  };

  const hasLowerCase = (password) => {
    return password && password.toUpperCase() !== password;
  };

  const hasNumber = (password) => {
    return password && /\d/.test(password);
  };

  const hasSpecialChar = (password) => {
    return password && /[~!@#$%^&*()_+\-=\[\]\\{}|;':",.\/<>?]/g.test(password);
  };

  const isPasswordMatch = (password, confirmPassword) => {
    return password !== "" && password === confirmPassword;
  };

  const isFiveToFiftyChars = (password) => {
    return password.length >= 8 && password.length <= 50;
  };

  const getCriteriaClass = (criteriaCheckFunction, password) => {
    return criteriaCheckFunction(password) ? "text-success pb-0" : "pb-0";
  };

  const onPasswordUpdate = (newPassword) => {
    setPassword(newPassword);
    if (isFiveToFiftyChars(newPassword)) {
      setIsPasswordValidationVisible(false);
    } else {
      setIsPasswordValidationVisible(true);
    }
    if (isPasswordMatch(newPassword, confirmPassword)) {
      setIsConfirmPasswordValidationVisible(false);
    } else {
      setIsConfirmPasswordValidationVisible(true);
    }
    if (
      isFiveToFiftyChars(newPassword) &&
      hasUpperCase(newPassword) &&
      hasLowerCase(newPassword) &&
      hasNumber(newPassword) &&
      hasSpecialChar(newPassword) &&
      isPasswordMatch(newPassword, confirmPassword)
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const onConfirmPasswordUpdate = (newConfirmPassword) => {
    setConfirmPassword(newConfirmPassword);
    if (isPasswordMatch(password, newConfirmPassword)) {
      setIsConfirmPasswordValidationVisible(false);
    } else {
      setIsConfirmPasswordValidationVisible(true);
    }
    if (
      isFiveToFiftyChars(password) &&
      hasUpperCase(password) &&
      hasLowerCase(password) &&
      hasNumber(password) &&
      hasSpecialChar(password) &&
      isPasswordMatch(password, newConfirmPassword)
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const validateForm = () => {
    const isValid =
      isFiveToFiftyChars(password) &&
      hasUpperCase(password) &&
      hasLowerCase(password) &&
      hasNumber(password) &&
      hasSpecialChar(password) &&
      isPasswordMatch(password, confirmPassword);

    // If password length or format is invalid
    if (!isFiveToFiftyChars(password)) {
      setIsPasswordValidationVisible(true);
      if (passwordValidationRef.current) {
        passwordValidationRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        passwordValidationRef.current.focus();
      }
      return false;
    } else {
      setIsPasswordValidationVisible(false);
    }

    // If confirm password does not match
    if (!isPasswordMatch(password, confirmPassword)) {
      setIsConfirmPasswordValidationVisible(true);
      if (confirmPasswordValidationRef.current) {
        confirmPasswordValidationRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        confirmPasswordValidationRef.current.focus();
      }
      return false;
    } else {
      setIsConfirmPasswordValidationVisible(false);
    }

    return isValid;
  };

  const onSubmit = () => {
    if (validateForm()) {
      sendActivity({
        type: "message",
        value: {
          type: "forgotPasswordCardClick",
          password: password,
          intentName: "forgotPassword",
          useExpiredToken: useExpiredToken,
        },
      });
      setIsDisabled(true);
      setIsSubmitDisabled(true);
      next();
    }
  };

  const liStyle = {
    color: "var(--CalHEERS-Primary-Text, #334048)",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
    paddingBottom: "4px",
  };

  return 
  // (
    // <>
    //   <WizardLayout.Content>
    //     <PageLayout>
    //       <PageLayout.Header style={{ marginBottom: "8px" }}>
    //         <SubHeader subHeader="Create New Password" />
    //       </PageLayout.Header>
    //       <PageLayout.Content>
    //         <Body
    //           bodyText={
    //             <>
    //               <div
    //                 className="mb-0"
    //                 style={{
    //                   color: "var(--CalHEERS-Primary-Text, #334048)",
    //                   fontFamily: "Open Sans",
    //                   fontSize: "12px",
    //                   fontStyle: "normal",
    //                   fontWeight: "700",
    //                   lineHeight: "140%",
    //                 }}
    //               >
    //                 Your new password cannot be:
    //               </div>
    //               <ul
    //                 style={{
    //                   paddingLeft: "20px",
    //                   paddingBottom: "24px",
    //                   marginTop: "6px",
    //                 }}
    //               >
    //                 <li style={liStyle}>a password you used before</li>
    //                 <li style={liStyle}>changed more than once a day</li>
    //                 <li style={{ ...liStyle, marginBottom: "0" }}>
    //                   a common word or phrase
    //                 </li>
    //               </ul>
    //             </>
    //           }
    //         />
    //         <Form style={{ marginTop: "-32px" }}>
    //           <Form.FormGroup>
    //             <Form.Label>New Password</Form.Label>
    //             <Form.Control>
    //               <Input
    //                 disabled={isDisabled}
    //                 type="password"
    //                 onBlur={(e) => onPasswordUpdate(e.target.value)}
    //                 onChange={(e) => onPasswordUpdate(e.target.value)}
    //                 placeholder="Enter new password"
    //               />
    //             </Form.Control>
    //             <ul
    //               style={{
    //                 paddingLeft: "20px",
    //                 marginBottom: "24px",
    //                 marginTop: "6px",
    //               }}
    //             >
    //               <li
    //                 style={liStyle}
    //                 className={
    //                   isFiveToFiftyChars(password) ? "text-success pb-0" : "pb-0"
    //                 }
    //               >
    //                 Is between 8 and 50 characters
    //               </li>
    //               <li
    //                 style={liStyle}
    //                 className={getCriteriaClass(hasUpperCase, password)}
    //               >
    //                 At least 1 uppercase letter
    //               </li>
    //               <li
    //                 style={liStyle}
    //                 className={getCriteriaClass(hasLowerCase, password)}
    //               >
    //                 At least 1 lowercase letter
    //               </li>
    //               <li
    //                 style={liStyle}
    //                 className={getCriteriaClass(hasNumber, password)}
    //               >
    //                 At least 1 number
    //               </li>
    //               <li
    //                 style={{
    //                   ...liStyle,
    //                   marginBottom: "0",
    //                   alignItems: "center",
    //                 }}
    //                 className={getCriteriaClass(hasSpecialChar, password)}
    //               >
    //                 At least 1 special character
    //                 <Tooltip
    //                   width="189px"
    //                   height="auto"
    //                   message="Special character must be at least 1 of the following:  ` ~ ! @ # $ % ^ & * ( ) _ + - = [ ] \ { } | ; ‘ : “ , . / < > ?"
    //                   forcePosition="top"
    //                   forcePositionSmall="top"
    //                   forcePositionLeft="top"
    //                   forcePositionRight="top"
    //                   style={{ marginLeft: "8px" }}
    //                 />
    //               </li>
    //             </ul>
    //             {isPasswordValidationVisible && (
    //               <div
    //                 className="text-danger"
    //                 ref={passwordValidationRef} // Reference for password validation
    //               >
    //                 Password must be between 8 and 50 characters.
    //               </div>
    //             )}
    //           </Form.FormGroup>
    //           <Form.FormGroup>
    //             <Form.Label>Confirm New Password</Form.Label>
    //             <Form.Control>
    //               <Input
    //                 disabled={isDisabled}
    //                 type="password"
    //                 onBlur={(e) => onConfirmPasswordUpdate(e.target.value)}
    //                 onChange={(e) => onConfirmPasswordUpdate(e.target.value)}
    //                 placeholder="Confirm new password"
    //               />
    //             </Form.Control>
    //             {isConfirmPasswordValidationVisible && (
    //               <div
    //                 className="text-danger"
    //                 ref={confirmPasswordValidationRef} // Reference for confirm password validation
    //               >
    //                 Passwords do not match.
    //               </div>
    //             )}
    //           </Form.FormGroup>
    //           {isUsedExpiredTokenVisible && (
    //             <Form.FormGroup>
    //               <Form.Control>
    //                 <Input
    //                   id="use-expired-token"
    //                   type="checkbox"
    //                   checked={useExpiredToken}
    //                   onChange={() => setUseExpiredToken(!useExpiredToken)}
    //                 />
    //                 <Form.Label htmlFor="use-expired-token">
    //                   Use expired token
    //                 </Form.Label>
    //               </Form.Control>
    //             </Form.FormGroup>
    //           )}
    //         </Form>
    //         {passwordInvalid && (
    //           <Alert iconType="red" frameIcon="red" message={passwordInvalid} />
    //         )}
    //       </PageLayout.Content>
    //     </PageLayout>
    //   </WizardLayout.Content>
    //   <WizardLayout.Navigation>
    //     <NavigationButton
    //       onClick={onSubmit}
    //       type={"primary"}
    //       disabled={isSubmitDisabled}
    //     >
    //       Change Password
    //     </NavigationButton>
    //     <NavigationButton
    //       onClick={() => closePasswordResetWizard()}
    //       type={"secondary"}
    //     >
    //       Exit
    //     </NavigationButton>
    //   </WizardLayout.Navigation>
    // </>
  // );
}

export default PasswordPage;
